import React, { useState, useEffect } from "react";
import {
  Table,
  Button,
  Modal,
  Input,
  Select,
} from "antd";
import {
  getDemoUsers,
  createDemoUser
} from "../../config/networkConfig";
import { useSelector } from "react-redux";
import { isMobile } from "react-device-detect";
import { settings } from "../../config/appConfig";
import { demoUsers } from "../../library/permission";
import { alertData, sharedSuccessAlert, sharedErrorAlert, sharedFailAlert } from "../../common/constants/sharedMsgBox";

export default function CSDemoUser(){
  const { userId, username, role} = useSelector((state) => state.login);
  const [tableLoading, setTableLoading] = useState(false);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [demoUserList, setDemoUserList] = useState([]);

  const rtpOptions = [
    {value: 'BigWin', label: 'Big Win'},
    {value: 'MegaWin', label: 'Mega Win'},
    {value: 'SuperMegaWin', label: 'Super Mega Win'}
  ];

  const getDemoUserList = async() =>{
    setTableLoading(true);
    const demoUserList = await getDemoUsers();
    const userList = await demoUserList.json();
    setDemoUserList(userList.data.length?userList.data:[]);
    setTableLoading(false);
  }

  useEffect(()=>{
    getDemoUserList();
  },[])

  
  
  const addModalControl = () =>{
    if(isAddModalOpen){
      setIsAddModalOpen(false);
    }else{
      setIsAddModalOpen(true);
    }
  }


  const AddForm = () => {
    const [mobileNumber, setMobileNumber] = useState('');
    const [selectedRtp, setSelectedRtp] = useState();

    const createDemoUsers = async(event) => {
      event.preventDefault();
      if(mobileNumber && selectedRtp){
        try{
          const result = await createDemoUser({mobile:mobileNumber,winType:selectedRtp, createBy: username});
          const createDemoUserResp = await result.json();
          if(createDemoUserResp.status === 'ok'){
            alertData.title = "Success";
            alertData.msg = createDemoUserResp.msg || 'Successfully create demo user';
            sharedSuccessAlert(alertData);
            getDemoUserList();
          }else{
            alertData.title = "Fail";
            alertData.msg = createDemoUserResp.msg || 'Failed to create demo user';
            sharedFailAlert(alertData);
          }
        }catch(err){
          alertData.title = "Error";
          alertData.msg = 'Failed to create demo user';
          sharedErrorAlert(alertData);
        }
      }else{
        alertData.title = "Error";
        alertData.msg = 'Please fill in all the field';
        sharedErrorAlert(alertData);
      }
      setMobileNumber('');
      setSelectedRtp();
      addModalControl();

    }

    // const searchUser = async(e) => {
    //   console.log(e.target.value);
    //   const value = e.target.value;
    //   if(value.length >= 10){
    //     const result = await getDemoUsersByMobile({mobile:value});
    //     const mobile = result.json();
    //     if(mobile.status === 'err'){
    //       // Modal.error({
    //       //   title: 'Error',
    //       //   content: mobile.msg || 'Mobile number not found',
    //       // });
    //       alertData.title = "Fail";
    //       alertData.msg = mobile.msg || 'Mobile number not found';
    //       sharedErrorAlert(alertData);
    //       setMobileNumber('');
    //     }
    //   }else{
    //     // Modal.error({
    //     //   title: 'Error',
    //     //   content: 'Please input more than 10 digit',
    //     // });
    //     alertData.title = "Error";
    //     alertData.msg = "Please input more than 10 digit!";
    //     sharedErrorAlert(alertData);
    //     setMobileNumber('');
    //   }
    // }
    
    const onChange = async(e) => {
      setMobileNumber(e.target.value);
    }

    const handleChange = (value) => {
      setSelectedRtp(value);
    };
    return(
      <div style={{margin:20}}>
        <form>
          <div style={{display:'flex', flexDirection:'column', alignItems:'center'}}>
            <div  style={{width:'80%', marginTop:20}}>
              <p style={{margin:0}}><span style={{color:'red'}}>*</span>Mobile Number</p>
              <Input style={{width:'100%'}} placeholder="Please fill in mobile number"  value={mobileNumber} onChange={onChange} enterButton />
              {/* <Input style={{width:'100%'}} placeholder="Please fill in mobile number"  value={mobileNumber} onChange={onChange} onBlur={searchUser} enterButton /> */}

            </div>
            <div style={{width:'80%', marginTop:20}}>
            <p style={{margin:0}}><span style={{color:'red'}}>*</span>RTP</p>
            <Select style={{width:'100%'}} placeholder="Please select RTP" value={selectedRtp} options={rtpOptions} onChange={handleChange}/>
            </div>
          <div style={{marginTop: 50}}>
            <Button onClick={addModalControl}>Cancel</Button>
            <Button style={styles.button} type="primary" onClick={createDemoUsers}>Create Demo User</Button>
          </div>
          </div>
        </form>
      </div>
    )
  }

  const columns =[
    {
      title: 'Mobile Number',
      dataIndex: 'mobile',
      key: 'mobile'
    },
    {
      title: 'Game Id',
      dataIndex: 'username',
      key: 'username'
    },
    {
      title: 'RTP',
      dataIndex: 'winType',
      key: 'winType'
    },
    {
      title: 'Create By',
      dataIndex: 'sysCreateBy',
      key: 'sysCreateBy'
    },
    {
      title: 'Create Time',
      dataIndex: 'sysCreateTime',
      key: 'sysCreateTime'
    }
  ];
  const styles = {
    paginationStyle: {
      marginTop: 50,
    },
    tabelContainer: {
      backgroundColor: "#FFFFFF",
      padding: 24,
    },
    button:{
      marginLeft:20,
    },
    addModal:{
      minHeight:'300px'
    }
  };
  return(
    demoUsers(role) ?
  <div style={styles.tabelContainer}>
    <div style={{ display:'flex', justifyContent:'space-around', width:"300px", marginBottom:20 }}>
      <Button type="primary" onClick={getDemoUserList}>Refresh Page</Button>
      <Button style={styles.button}  type="primary" onClick={addModalControl}>Create Demo User</Button>
    </div>
    <Modal title="Create Demo User Form" width={800} bodyStyle={styles.addModal} visible={isAddModalOpen}  onCancel={addModalControl}
    footer={null}>
    <AddForm />
    </Modal>
    <Table
        loading={tableLoading}
        columns={columns}
        dataSource={demoUserList}
        scroll={{ x: isMobile ? "110%" : "100%", y: null }}
        pagination={{
          defaultPageSize: 10,
          showSizeChanger: true,
          pageSizeOptions: settings.pageSizeOptions,
          style: styles.paginationStyle,
          alignmentBottom: 'right',
        }}
      >
      </Table>
  </div>:<div style={{textAlign:"center",color:"red",fontSize:"150%"}}><h1 style={{color:"red"}}>Not Allowed to Access This Page</h1></div>)
}
