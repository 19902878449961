const pageAccessInitialState = [];

const pageAccessLoadingInitialState = {
  loading: false,
};

const currentPageAccessInitialState = {
  pageId: null,
  read: null,
  create: null,
  write: null,
  delete: null
};

const sideMenuInitialState = [];

export const pageAccess = ( state = pageAccessInitialState, {type, payload}) => {
  switch (type) {

    case "sign_in_get_access":
      return payload;

    case "sign_out_remove_access":
      return pageAccessInitialState;

    default:
      return state;
  }
}

export const pageAccessLoading = ( state = pageAccessLoadingInitialState, { type }) => {
  switch (type) {
    case "sign_in_start_loading":
      return { loading: true };

    case "sign_in_stop_loading":
      return { loading: false };

    default:
      return state;
  }
}

export const currentPageAccess = (state = currentPageAccessInitialState, {type, payload}) => {

  switch (type) {
    case "set_current_page_access":
      return {
        ...state,
        pageId: payload.pageId,
        read: payload.read,
        create: payload.create,
        write: payload.write,
        delete: payload.delete
      };

      case "remove_current_page_access":
        return currentPageAccessInitialState;

    default:
      return state;
  }
};

export const sideMenu = (state = sideMenuInitialState, {type, payload}) => {
  
  switch (type) {
    case "set_side_menu":
      return payload;

    case "remove_side_menu":
      return sideMenuInitialState;

    default: 
    return state;
  }
};


