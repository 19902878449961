import React, { useState, useEffect , useRef} from "react";
import {
    PageHeader,
    Button,
    Form,
    DatePicker,
    Select,
    Tabs,
    Space,
  } from "antd";
import { useSelector } from "react-redux";
import { Table } from "ant-table-extensions";
import { isMobile } from "react-device-detect";
import {
    SearchOutlined,
    FileExcelOutlined
  } from "@ant-design/icons";
import {
  newsModalNew,
    reportAgentList,
    reportAgentData,
    reportMarketingData,
  } from "../../../config/networkConfig"
import moment from "moment";
import { settings } from "../../../config/appConfig";
import { FormatDataMobile } from "../../../library/numbers";
import { ReportAgentVw } from "../../../library/permission";
import style from "../../../resource/style/style";
import { alertData, sharedSuccessAlert, sharedErrorAlert, sharedFailAlert, } from "../../../common/constants/sharedMsgBox";

export default function ReportAgent() {
    const {TabPane} = Tabs;
    const {role, username, compCode} = useSelector((state) => state.login);
    const [form] = Form.useForm();
    const { Option } = Select;
    const [trxLoading, setTrxLoading] = useState(false);
    const [agentReport, setAgentReport]     = useState([]);
    const [marketingReport, setMarketingReport]     = useState([]);
    const [todayReport, setTodayReport]     = useState([]);
    const [todayLastUpdate, setTodayLastUpdate]     = useState([]);
    const [agentOptions, setAgentOptions] = useState([]);
    const [searchTab1, setSearchTab1]  = useState(true);
    const [searchTab2, setSearchTab2]  = useState(false);
    const [searchTab3, setSearchTab3]  = useState(false);

    //Date Picker
    const [todayDate, setTodayDate] = useState(new Date().toLocaleDateString('en-GB').split('/').reverse().join('-'));
    const [trxDateFrom1, setTrxDateFrom1] = useState("");
    const [trxDateFrom2, setTrxDateFrom2] = useState("");
    const [trxDateTo1, setTrxDateTo1] = useState("");
    const [trxDateTo2, setTrxDateTo2] = useState("");
    const [type1, setType1] = useState("custom");
    const [type2, setType2] = useState("custom");
    const [agentSelection, setAgentSelection] = useState("");
    const reportAgentRef = useRef(null);
    const reportDateFrom1 = useRef(null);
    const reportDateTo1 = useRef(null);
    const reportDateFrom2 = useRef(null);
    const reportDateTo2 = useRef(null);

    const [modal1Loading ,          setModal1Loading] = useState(false);
    const [modal2Loading ,          setModal2Loading] = useState(false);
    const [modal3Loading ,          setModal3Loading] = useState(false);

    const [modalVisible,             setModalVisible] = useState(false);

    const [title1,                         setTitle1] = useState([]);
    const [title2,                         setTitle2] = useState([]);
    const [title3,                         setTitle3] = useState([]);

    //Status Filter
    const [status, setStatus] = useState("All");

    useEffect(() => {
      agentList();
      agentData();
      todayData();
      setModalVisible(true);
      setTitle1(`Lifetime performance`);
      setTitle2(`Today Performance`);
      setTitle3(`Individual Detail`);
    }, [status]);

    useEffect(()=> {
      setTrxDateFrom1(trxDateFrom1);
      setTrxDateTo1(trxDateTo1);
    },[trxDateFrom1,trxDateTo1]);

    useEffect(()=> {
      setTrxDateFrom2(trxDateFrom2);
      setTrxDateTo2(trxDateTo2);
    },[trxDateFrom2,trxDateTo2]);

    useEffect(()=> {
      setAgentSelection(agentSelection);
    },[agentSelection]);

    ////#region Load Lists
    const agentList = async () => {
      let requestData = {
        role : role,
        compCode : compCode,
        username : username // Used for tkb555 only as 555 want only this senior user can see agent list
      }
      const response = await reportAgentList(requestData);
      let result = await response.json();
      
      if (result.status == 'ok'){
        setAgentOptions(result.msg != 'No record.' ? result.data : []);
      }
      else{
        alertData.title = "Failed";
        alertData.msg = result.msg;
        sharedFailAlert(alertData);
      }
    }

    const onChangeTransferTab = (key)=> {
      if (key == 1) {
        setSearchTab1(true);
        setSearchTab2(false);
        setSearchTab3(false);
      }
      else if (key == 2) {
        setSearchTab1(false);
        setSearchTab2(true);
        setSearchTab3(false);
      }
      else if (key == 3) {
        setSearchTab1(false);
        setSearchTab2(false);
        setSearchTab3(true);
      }
    }

    const marketingData = async () => {
      let count = 0 ;

      let requestData = {
        secret : agentSelection,
        datefrom : moment(trxDateFrom2).format("YYYY-MM-DD"),
        dateto: moment(trxDateTo2).format("YYYY-MM-DD"),
      }

      setModal3Loading(true);
      const result = await reportMarketingData(requestData);
      let resp = await result.json();
      if (resp.status === "ok" ) {
      //if (resp.status ==="ok" && resp.msg !='No records found') {  
        if (Object.keys(resp.data).length > 0 ) {
          setTrxLoading(false);
          const respData2 = Object.keys(resp.data.allFullData).length
          ? resp.data.allFullData.map((obj) => {
            setModal3Loading(false);
              return {
                key             : count++,
                trxnDate        : obj.TrxnDate,
                mobileList      : obj.MobileList,
                agentCode       : obj.AgentCode,
                regUser         : obj.RegUser,
                invUser         : obj.InvUser,
                bindBankUser    : obj.BindBankUser,
                depSameDayCnt   : obj.DepSameDayCnt,
                depSameDayAmt   : obj.DepSameDayAmt,
                regUserNoDep    : obj.RegUserNoDep,
                depCnt          : obj.DepCnt,
                depPpl          : obj.DepPpl,
                depAmt          : obj.DepAmt,
                depMax          : obj.DepMax,
                dep1stCnt       : obj.Dep1stCnt,
                dep1stTime      : obj.Dep1stTime,
                dep1stAmt       : obj.Dep1stAmt,
                wthCnt          : obj.WthCnt,
                wthPpl          : obj.WthPpl,
                wthAmt          : obj.WthAmt,
                wthMax          : obj.WthMax,
                comm2Bank       : FormatDataMobile(obj.Comm2Bank),
                comm2Wallet     : FormatDataMobile(obj.Comm2Wallet),
                regUserDirect   : obj.RegUserDirect,
                agentComm       : FormatDataMobile(obj.AgentComm),
              };
            })
          : [];

          let summaryDepAmt = 0, summaryWthAmt = 0, summaryAgentComm = 0;
          let reportSummary = [...respData2];

            reportSummary.forEach(({ depAmt, wthAmt, agentComm
            }) => {
              summaryDepAmt                   += Number(FormatDataMobile(depAmt));
              summaryWthAmt                   += Number(FormatDataMobile(wthAmt));
              summaryAgentComm                += Number(FormatDataMobile(agentComm));
            });

            reportSummary= [
                {
                  key: "Summary",
                  trxnDate        : "",
                  mobileList      : "",
                  agentCode       : "",
                  regUser         : "",
                  invUser         : "",
                  bindBankUser    : "",
                  depSameDayCnt   : "",
                  depSameDayAmt   : "",
                  regUserNoDep    : "",
                  depCnt          : "",
                  depPpl          : "",
                  depAmt          : FormatDataMobile(summaryDepAmt),
                  depMax          : "",
                  dep1stCnt       : "",
                  dep1stTime      : "",
                  dep1stAmt       : "",
                  wthCnt          : "",
                  wthPpl          : "",
                  wthAmt          : FormatDataMobile(summaryWthAmt),
                  wthMax          : "",
                  comm2Bank       : "",
                  comm2Wallet     : "",
                  regUserDirect   : "",
                  agentComm       : FormatDataMobile(summaryAgentComm),
                },
                ...reportSummary,
              ];

          setMarketingReport(reportSummary);

          if (respData2.length === 0 ) {
            alertData.title = "Error";
            alertData.msg = "No agent data!";
            sharedErrorAlert(alertData);
            setModal3Loading(false);
            return;
          }
        }
      }
      else {
        setTrxLoading(false);
      }
    }

    const agentData = async () => {
      let count = 1 ;

      let requestData = {
        datefrom : moment(trxDateFrom1).format("YYYY-MM-DD") == 'Invalid date' ? "" : moment(trxDateFrom1).format("YYYY-MM-DD"),
        dateto: moment(trxDateTo1).format("YYYY-MM-DD") == 'Invalid date' ? "" : moment(trxDateTo1).format("YYYY-MM-DD"),
      }

      setModal1Loading(true);
      const result = await reportAgentData(requestData);
      let resp = await result.json();
      if (resp.status === "ok" ) {
      //if (resp.status ==="ok" && resp.msg !='No records found') {  
        if (Object.keys(resp.data).length > 0 ) {
          setModal1Loading(false);
          const respAgentData = Object.keys(resp.data.agentData).length
          ? resp.data.agentData.map((obj) => {
            setModal1Loading(false);
              return {
                key             : count++,
                NickName        : obj.NickName,
                ReferCode       : obj.ReferCode,
                RegUser         : obj.RegUser,
                RegUserDirect   : obj.RegUserDirect,
                BindBankUser    : obj.BindBankUser,
                InvUser         : obj.InvUser,
                DepCnt          : obj.DepCnt,
                DepPpl          : obj.DepPpl,
                DepAmt          : obj.DepAmt.toFixed(2),
                WthCnt          : obj.WthCnt,
                WthPpl          : obj.WthPpl,
                WthAmt          : obj.WthAmt.toFixed(2),
                Dep1stCnt       : obj.Dep1stCnt,
                Dep1stAmt       : obj.Dep1stAmt.toFixed(2),
                Dep1stTime      : obj.Dep1stTime.toFixed(2),
                DepSameDayCnt   : obj.DepSameDayCnt,
                DepSameDayAmt   : obj.DepSameDayAmt.toFixed(2),
                Comm2Bank       : obj.Comm2Bank.toFixed(2),
                Comm2Wallet     : obj.Comm2Wallet.toFixed(2),

                Level1RegisterUser          : obj.Level1RegisterUser,
                Level1DepositAmount         : obj.Level1DepositAmount.toFixed(2),
                Level1WithdrawalAmount      : obj.Level1WithdrawalAmount.toFixed(2),
                Level1WinlostAmount         : obj.Level1WinlostAmount.toFixed(2),

                Level2RegisterUser          : obj.Level2RegisterUser,
                Level2DepositAmount         : obj.Level2DepositAmount.toFixed(2),
                Level2WithdrawalAmount      : obj.Level2WithdrawalAmount.toFixed(2),
                Level2WinlostAmount         : obj.Level2WinlostAmount.toFixed(2),

                Level3RegisterUser          : obj.Level3RegisterUser,
                Level3DepositAmount         : obj.Level3DepositAmount.toFixed(2),
                Level3WithdrawalAmount      : obj.Level3WithdrawalAmount.toFixed(2),
                Level3WinlostAmount         : obj.Level3WinlostAmount.toFixed(2),

              };
            })
          : [];

          let summaryRegUser = 0, summaryRegUserDirect = 0, summaryBindBankUser = 0;
          let summaryInvUser = 0, summaryDepCnt = 0, summaryDepPpl = 0;
          let summaryDepAmt = 0, summaryWthCnt = 0, summaryWthPpl = 0;
          let summaryWthAmt = 0, summaryDep1stCnt = 0, summaryDep1stAmt = 0;
          let summaryDep1stTime = 0, summaryDepSameDayCnt = 0, summaryDepSameDayAmt = 0;
          let summaryComm2Bank = 0, summaryComm2Wallet = 0;

          let summaryLevel1RegisterUser = 0, summaryLevel1DepositAmount = 0;
          let summaryLevel1WithdrawalAmount = 0, summaryLevel1WinlostAmount = 0;

          let summaryLevel2RegisterUser = 0, summaryLevel2DepositAmount = 0;
          let summaryLevel2WithdrawalAmount = 0, summaryLevel2WinlostAmount = 0;

          let summaryLevel3RegisterUser = 0, summaryLevel3DepositAmount = 0;
          let summaryLevel3WithdrawalAmount = 0, summaryLevel3WinlostAmount = 0;

          let reportSummary = [...respAgentData];

            reportSummary.forEach(({ RegUser, RegUserDirect, BindBankUser,
                                     InvUser, DepCnt, DepPpl,
                                     DepAmt, WthCnt, WthPpl,
                                     WthAmt, Dep1stCnt, Dep1stAmt,
                                     Dep1stTime, DepSameDayCnt, DepSameDayAmt,
                                     Comm2Bank, Comm2Wallet, 
                                    Level1RegisterUser, Level1DepositAmount, Level1WithdrawalAmount, Level1WinlostAmount,
                                    Level2RegisterUser, Level2DepositAmount, Level2WithdrawalAmount, Level2WinlostAmount,
                                    Level3RegisterUser, Level3DepositAmount, Level3WithdrawalAmount, Level3WinlostAmount
            }) => {
              summaryRegUser                += Number(FormatDataMobile(RegUser));
              summaryRegUserDirect          += Number(FormatDataMobile(RegUserDirect));
              summaryBindBankUser           += Number(FormatDataMobile(BindBankUser));
              summaryInvUser                += Number(FormatDataMobile(InvUser));
              summaryDepCnt                 += Number(FormatDataMobile(DepCnt));
              summaryDepPpl                 += Number(FormatDataMobile(DepPpl));
              summaryDepAmt                 += Number(FormatDataMobile(DepAmt));
              summaryWthCnt                 += Number(FormatDataMobile(WthCnt));
              summaryWthPpl                 += Number(FormatDataMobile(WthPpl));
              summaryWthAmt                 += Number(FormatDataMobile(WthAmt));
              summaryDep1stCnt              += Number(FormatDataMobile(Dep1stCnt));
              summaryDep1stAmt              += Number(FormatDataMobile(Dep1stAmt));
              summaryDep1stTime             += Number(FormatDataMobile(Dep1stTime));
              summaryDepSameDayCnt          += Number(FormatDataMobile(DepSameDayCnt));
              summaryDepSameDayAmt          += Number(FormatDataMobile(DepSameDayAmt));
              summaryComm2Bank              += Number(FormatDataMobile(Comm2Bank));
              summaryComm2Wallet            += Number(FormatDataMobile(Comm2Wallet));

              summaryLevel1RegisterUser     += Number(FormatDataMobile(Level1RegisterUser));
              summaryLevel1DepositAmount    += Number(FormatDataMobile(Level1DepositAmount));
              summaryLevel1WithdrawalAmount += Number(FormatDataMobile(Level1WithdrawalAmount));
              summaryLevel1WinlostAmount    += Number(FormatDataMobile(Level1WinlostAmount));

              summaryLevel2RegisterUser     += Number(FormatDataMobile(Level2RegisterUser));
              summaryLevel2DepositAmount    += Number(FormatDataMobile(Level2DepositAmount));
              summaryLevel2WithdrawalAmount += Number(FormatDataMobile(Level2WithdrawalAmount));
              summaryLevel2WinlostAmount    += Number(FormatDataMobile(Level2WinlostAmount));

              summaryLevel3RegisterUser     += Number(FormatDataMobile(Level3RegisterUser));
              summaryLevel3DepositAmount    += Number(FormatDataMobile(Level3DepositAmount));
              summaryLevel3WithdrawalAmount += Number(FormatDataMobile(Level3WithdrawalAmount));
              summaryLevel3WinlostAmount    += Number(FormatDataMobile(Level3WinlostAmount));
            });

            reportSummary= [
                {
                  key             : "Summary",
                  NickName        : "",
                  ReferCode       : "",
                  RegUser         : summaryRegUser,
                  RegUserDirect   : summaryRegUserDirect,
                  BindBankUser    : summaryBindBankUser,
                  InvUser         : summaryInvUser,
                  DepCnt          : summaryDepCnt,
                  DepPpl          : summaryDepPpl,
                  DepAmt          : (summaryDepAmt).toFixed(2),
                  WthCnt          : summaryWthCnt,
                  WthPpl          : summaryWthPpl,
                  WthAmt          : (summaryWthAmt).toFixed(2),
                  Dep1stCnt       : summaryDep1stCnt,
                  Dep1stAmt       : (summaryDep1stAmt).toFixed(2),
                  Dep1stTime      : (summaryDep1stTime).toFixed(2),
                  DepSameDayCnt   : summaryDepSameDayCnt,
                  DepSameDayAmt   : summaryDepSameDayAmt.toFixed(2),
                  Comm2Bank       : (summaryComm2Bank).toFixed(2),
                  Comm2Wallet     : (summaryComm2Wallet).toFixed(2),
                  
                  Level1RegisterUser : (summaryLevel1RegisterUser),
                  Level1DepositAmount : (summaryLevel1DepositAmount).toFixed(2),
                  Level1WithdrawalAmount : (summaryLevel1WithdrawalAmount).toFixed(2),
                  Level1WinlostAmount : (summaryLevel1WinlostAmount).toFixed(2),

                  Level2RegisterUser : (summaryLevel2RegisterUser),
                  Level2DepositAmount : (summaryLevel2DepositAmount).toFixed(2),
                  Level2WithdrawalAmount : (summaryLevel2WithdrawalAmount).toFixed(2),
                  Level2WinlostAmount : (summaryLevel2WinlostAmount).toFixed(2),

                  Level3RegisterUser : (summaryLevel3RegisterUser),
                  Level3DepositAmount : (summaryLevel3DepositAmount).toFixed(2),
                  Level3WithdrawalAmount : (summaryLevel3WithdrawalAmount).toFixed(2),
                  Level3WinlostAmount : (summaryLevel3WinlostAmount).toFixed(2),

                },
                ...reportSummary,
              ];

          setAgentReport(reportSummary);

          if (respAgentData.length === 0 ) {
            alertData.title = "Success";
            alertData.msg = "No agent data found!";
            sharedSuccessAlert(alertData);
            setModal1Loading(false);
            return;
          }
        }
      }
      else {
        setTrxLoading(false);
      }
    }

    const todayData = async () => {
      let count = 1 ;

      let requestData = {
      }

      setModal2Loading(true);
      const result = await reportAgentData(requestData);
      let resp = await result.json();
      if (resp.status === "ok" ) {
      //if (resp.status ==="ok" && resp.msg !='No records found') {  
        if (Object.keys(resp.data).length > 0 ) {
          setTrxLoading(false);
          const respTodayData = Object.keys(resp.data.todayData).length
          ? resp.data.todayData.map((obj) => {
            setModal2Loading(false);
              return {
                key             : count++,
                NickName        : obj.NickName,
                ReferCode       : obj.ReferCode,
                RegUser         : obj.RegUser,
                RegUserDirect   : obj.RegUserDirect,
                BindBankUser    : obj.BindBankUser,
                InvUser         : obj.InvUser,
                DepCnt          : obj.DepCnt,
                DepPpl          : obj.DepPpl,
                DepAmt          : obj.DepAmt,
                WthCnt          : obj.WthCnt,
                WthPpl          : obj.WthPpl,
                WthAmt          : obj.WthAmt,
                Dep1stCnt       : obj.Dep1stCnt,
                Dep1stAmt       : obj.Dep1stAmt,
                Dep1stTime      : obj.Dep1stTime,
                DepSameDayCnt   : obj.DepSameDayCnt,
                DepSameDayAmt   : obj.DepSameDayAmt,
                Comm2Bank       : obj.Comm2Bank,
                Comm2Wallet     : obj.Comm2Wallet,
                LatestUpdate    : obj.LatestUpdate,
                SaleAmountOfMemberLevel1     : obj.SaleAmountOfMemberLevel1,
                SaleAmountOfMemberLevel2     : obj.SaleAmountOfMemberLevel2,
                SaleAmountOfMemberLevel3     : obj.SaleAmountOfMemberLevel3,
                SaleAmountOfMemberLevelOther : obj.SaleAmountOfMemberLevelOther
              };
            })
          : [];

          let summaryRegUser = 0, summaryRegUserDirect = 0, summaryBindBankUser = 0;
          let summaryInvUser = 0, summaryDepCnt = 0, summaryDepPpl = 0;
          let summaryDepAmt = 0, summaryWthCnt = 0, summaryWthPpl = 0;
          let summaryWthAmt = 0, summaryDep1stCnt = 0, summaryDep1stAmt = 0;
          let summaryDep1stTime = 0, summaryDepSameDayCnt = 0, summaryDepSameDayAmt = 0;
          let summaryComm2Bank = 0, summaryComm2Wallet = 0, summaryLatestUpdate = '';
          let summarySaleAmountOfMemberLevel1 = 0, summarySaleAmountOfMemberLevel2 = 0;
          let summarySaleAmountOfMemberLevel3 = 0, summarySaleAmountOfMemberLevelOther = 0;
          let reportSummary = [...respTodayData];

            reportSummary.forEach(({ RegUser, RegUserDirect, BindBankUser,
                                     InvUser, DepCnt, DepPpl,
                                     DepAmt, WthCnt, WthPpl,
                                     WthAmt, Dep1stCnt, Dep1stAmt,
                                     Dep1stTime, DepSameDayCnt, DepSameDayAmt,
                                     Comm2Bank, Comm2Wallet, LatestUpdate,
                                     SaleAmountOfMemberLevel1, SaleAmountOfMemberLevel2,
                                     SaleAmountOfMemberLevel3, SaleAmountOfMemberLevelOther
            }) => {
              summaryRegUser                += Number(FormatDataMobile(RegUser));
              summaryRegUserDirect          += Number(FormatDataMobile(RegUserDirect));
              summaryBindBankUser           += Number(FormatDataMobile(BindBankUser));
              summaryInvUser                += Number(FormatDataMobile(InvUser));
              summaryDepCnt                 += Number(FormatDataMobile(DepCnt));
              summaryDepPpl                 += Number(FormatDataMobile(DepPpl));
              summaryDepAmt                 += Number(FormatDataMobile(DepAmt));
              summaryWthCnt                 += Number(FormatDataMobile(WthCnt));
              summaryWthPpl                 += Number(FormatDataMobile(WthPpl));
              summaryWthAmt                 += Number(FormatDataMobile(WthAmt));
              summaryDep1stCnt              += Number(FormatDataMobile(Dep1stCnt));
              summaryDep1stAmt              += Number(FormatDataMobile(Dep1stAmt));
              summaryDep1stTime             += Number(FormatDataMobile(Dep1stTime));
              summaryDepSameDayCnt          += Number(FormatDataMobile(DepSameDayCnt));
              summaryDepSameDayAmt          += Number(FormatDataMobile(DepSameDayAmt));
              summaryComm2Bank              += Number(FormatDataMobile(Comm2Bank));
              summaryComm2Wallet            += Number(FormatDataMobile(Comm2Wallet));
              summaryLatestUpdate            = LatestUpdate;
              summarySaleAmountOfMemberLevel1 += Number(FormatDataMobile(SaleAmountOfMemberLevel1));
              summarySaleAmountOfMemberLevel2 += Number(FormatDataMobile(SaleAmountOfMemberLevel2));
              summarySaleAmountOfMemberLevel3 += Number(FormatDataMobile(SaleAmountOfMemberLevel3));
              summarySaleAmountOfMemberLevelOther += Number(FormatDataMobile(SaleAmountOfMemberLevelOther));

            });

            reportSummary= [
                {
                  key             : "Summary",
                  NickName        : "",
                  ReferCode       : "",
                  RegUser         : summaryRegUser,
                  RegUserDirect   : summaryRegUserDirect,
                  BindBankUser    : summaryBindBankUser,
                  InvUser         : summaryInvUser,
                  DepCnt          : summaryDepCnt,
                  DepPpl          : summaryDepPpl,
                  DepAmt          : (summaryDepAmt).toFixed(2),
                  WthCnt          : summaryWthCnt,
                  WthPpl          : summaryWthPpl,
                  WthAmt          : (summaryWthAmt).toFixed(2),
                  Dep1stCnt       : summaryDep1stCnt,
                  Dep1stAmt       : (summaryDep1stAmt).toFixed(2),
                  Dep1stTime      : (summaryDep1stTime).toFixed(2),
                  DepSameDayCnt   : summaryDepSameDayCnt,
                  DepSameDayAmt   : summaryDepSameDayAmt,
                  Comm2Bank       : (summaryComm2Bank).toFixed(2),
                  Comm2Wallet     : (summaryComm2Wallet).toFixed(2),
                  LatestUpdate    : summaryLatestUpdate,
                  SaleAmountOfMemberLevel1 : (summarySaleAmountOfMemberLevel1).toFixed(2),
                  SaleAmountOfMemberLevel2 : (summarySaleAmountOfMemberLevel2).toFixed(2),
                  SaleAmountOfMemberLevel3 : (summarySaleAmountOfMemberLevel3).toFixed(2),
                  SaleAmountOfMemberLevelOther : (summarySaleAmountOfMemberLevelOther).toFixed(2)
                },
                ...reportSummary,
              ];

          setTodayLastUpdate(reportSummary[0].LatestUpdate)
          setTodayReport(reportSummary);
          setModal2Loading(false);
          // if (respTodayData.length === 0 ) {
          //   alertData.title = "Error";
          //   alertData.msg = "No Today data!";
          //   sharedErrorAlert(alertData);
          //   setModal2Loading(false);
          //   return;
          // }
        }
      }
      else {
        setTrxLoading(false);
      }
    }
    ////#endregion

    
    ////#region  Components
    let agentColumns = [
      {
        align: "center",
        title: "No",
        dataIndex:"key",
        width: "100px",
        style: [{"word-wrap":"break-word"}],
        fixed:"left",
      },
      {
        align: "center",
        title: "Agent",
        dataIndex:"NickName",
        width: "200px",
        style: [{"word-wrap":"break-word"}],
        fixed:"left",
      },
      {
        align: "center",
        title: "Agent Code",
        dataIndex:"ReferCode",
        width: "130px",
        style: [{"word-wrap":"break-word"}],
      },
      {
        align: "center",
        title: "Register User (All)",
        dataIndex:"RegUser",
        width: "130px",
        style: [{"word-wrap":"break-word"}],
        render:function(record) {
          return <p style={{color:"blue", marginTop: "13px"}}>{record}</p>
        }
      },
      {
        align: "center",
        title: "Register User Direct (Level 1)",
        dataIndex:"RegUserDirect",
        width: "140px",
        style: [{"word-wrap":"break-word"}],
        render:function(record) {
          return <p style={{color:"blue", marginTop: "13px"}}>{record}</p>
        }
      },
      {
        align: "center",
        title: "Invite User",
        dataIndex:"InvUser",
        width: "130px",
        style: [{"word-wrap":"break-word"}],
        render:function(record) {
          return <p style={{color:"blue", marginTop: "13px"}}>{record}</p>
        }
      },
      {
        align: "center",
        title: "Bind Bank User (All)",
        dataIndex:"BindBankUser",
        width: "130px",
        style: [{"word-wrap":"break-word"}],
        render:function(record) {
          return <p style={{color:"blue", marginTop: "13px"}}>{record}</p>
        }
      },
      {
        align: "center",
        title: "Same Day Register + Deposit User",
        dataIndex:"DepSameDayCnt",
        width: "130px",
        style: [{"word-wrap":"break-word"}],
        render:function(record) {
          return <p style={{color:"blue", marginTop: "13px"}}>{record}</p>
        }
      },
      {
        align: "center",
        title: "Deposit Count",
        dataIndex:"DepCnt",
        width: "130px",
        style: [{"word-wrap":"break-word"}],
      },
      {
        align: "center",
        title: "Deposit Person",
        dataIndex:"DepPpl",
        width: "130px",
        style: [{"word-wrap":"break-word"}],
      },
      {
        align: "center",
        title: "Deposit Amount",
        dataIndex:"DepAmt",
        width: "130px",
        style: [{"word-wrap":"break-word"}],
      },
      {
        align: "center",
        title: "First Deposit User",
        dataIndex:"Dep1stCnt",
        width: "130px",
        style: [{"word-wrap":"break-word"}],
        render:function(record) {
          return <p style={{color:"blue", marginTop: "13px"}}>{record}</p>
        }
      },
      {
        align: "center",
        title: "First Time Amount",
        dataIndex:"Dep1stTime",
        width: "130px",
        style: [{"word-wrap":"break-word"}],
        render:function(record) {
          return <p style={{color:"blue", marginTop: "13px"}}>{record}</p>
        }
      },
      {
        align: "center",
        title: "First Deposit Amount",
        dataIndex:"Dep1stAmt",
        width: "130px",
        style: [{"word-wrap":"break-word"}],
        render:function(record) {
          return <p style={{color:"blue", marginTop: "13px"}}>{record}</p>
        }
      },
      {
        align: "center",
        title: "Withdraw Count",
        dataIndex:"WthCnt",
        width: "130px",
        style: [{"word-wrap":"break-word"}],
      },
      {
        align: "center",
        title: "Withdraw Person",
        dataIndex:"WthPpl",
        width: "130px",
        style: [{"word-wrap":"break-word"}],
      },
      {
        align: "center",
        title: "Withdraw Amount",
        dataIndex:"WthAmt",
        width: "130px",
        style: [{"word-wrap":"break-word"}],
      },
      {
        align: "center",
        title: "Comm2Bank",
        dataIndex:"Comm2Bank",
        width: "130px",
        style: [{"word-wrap":"break-word"}],
      },
      {
        align: "center",
        title: "Comm2Wallet",
        dataIndex:"Comm2Wallet",
        width: "130px",
        style: [{"word-wrap":"break-word"}],
      },
      {
        align: "center",
        title: "Level 1 Register User",
        dataIndex:"Level1RegisterUser",
        width: "140px",
        style: [{"word-wrap":"break-word"}],
      },
      {
        align: "center",
        title: "Level 1 Deposit Amount",
        dataIndex:"Level1DepositAmount",
        width: "140px",
        style: [{"word-wrap":"break-word"}],
      },
      {
        align: "center",
        title: "Level 1 Withdrawal Amount",
        dataIndex:"Level1WithdrawalAmount",
        width: "140px",
        style: [{"word-wrap":"break-word"}],
      },
      {
        align: "center",
        title: "Level 1 Winlost Amount",
        dataIndex:"Level1WinlostAmount",
        width: "140px",
        style: [{"word-wrap":"break-word"}],
      },
      {
        align: "center",
        title: "Level 2 Register User",
        dataIndex:"Level2RegisterUser",
        width: "140px",
        style: [{"word-wrap":"break-word"}],
      },
      {
        align: "center",
        title: "Level 2 Deposit Amount",
        dataIndex:"Level2DepositAmount",
        width: "140px",
        style: [{"word-wrap":"break-word"}],
      },
      {
        align: "center",
        title: "Level 2 Withdrawal Amount",
        dataIndex:"Level2WithdrawalAmount",
        width: "140px",
        style: [{"word-wrap":"break-word"}],
      },
      {
        align: "center",
        title: "Level 2 Winlost Amount",
        dataIndex:"Level2WinlostAmount",
        width: "140px",
        style: [{"word-wrap":"break-word"}],
      },
      {
        align: "center",
        title: "Level 3 Register User",
        dataIndex:"Level3RegisterUser",
        width: "140px",
        style: [{"word-wrap":"break-word"}],
      },
      {
        align: "center",
        title: "Level 3 Deposit Amount",
        dataIndex:"Level3DepositAmount",
        width: "140px",
        style: [{"word-wrap":"break-word"}],
      },
      {
        align: "center",
        title: "Level 3 Withdrawal Amount",
        dataIndex:"Level3WithdrawalAmount",
        width: "140px",
        style: [{"word-wrap":"break-word"}],
      },
      {
        align: "center",
        title: "Level 3 Winlost Amount",
        dataIndex:"Level3WinlostAmount",
        width: "140px",
        style: [{"word-wrap":"break-word"}],
      },
    ]

    let todayColumns = [
      {
        align: "center",
        title: "No",
        dataIndex:"key",
        width: "100px",
        style: [{"word-wrap":"break-word"}],
        fixed:"left",
      },
      {
        align: "center",
        title: "Agent",
        dataIndex:"NickName",
        width: "200px",
        style: [{"word-wrap":"break-word"}],
        fixed:"left",
      },
      {
        align: "center",
        title: "Agent Code",
        dataIndex:"ReferCode",
        width: "130px",
        style: [{"word-wrap":"break-word"}],
      },
      {
        align: "center",
        title: "Register User (All)",
        dataIndex:"RegUser",
        width: "130px",
        style: [{"word-wrap":"break-word"}],
        render:function(record) {
          return <p style={{color:"blue", marginTop: "13px"}}>{record}</p>
        }
      },
      {
        align: "center",
        title: "Register User Direct (Level 1)",
        dataIndex:"RegUserDirect",
        width: "140px",
        style: [{"word-wrap":"break-word"}],
        render:function(record) {
          return <p style={{color:"blue", marginTop: "13px"}}>{record}</p>
        }
      },
      {
        align: "center",
        title: "Invite User",
        dataIndex:"InvUser",
        width: "130px",
        style: [{"word-wrap":"break-word"}],
        render:function(record) {
          return <p style={{color:"blue", marginTop: "13px"}}>{record}</p>
        }
      },
      {
        align: "center",
        title: "Bind Bank User (All)",
        dataIndex:"BindBankUser",
        width: "130px",
        style: [{"word-wrap":"break-word"}],
        render:function(record) {
          return <p style={{color:"blue", marginTop: "13px"}}>{record}</p>
        }
      },
      {
        align: "center",
        title: "Same Day Register + Deposit User",
        dataIndex:"DepSameDayCnt",
        width: "130px",
        style: [{"word-wrap":"break-word"}],
        render:function(record) {
          return <p style={{color:"blue", marginTop: "13px"}}>{record}</p>
        }
      },
      {
        align: "center",
        title: "Deposit Count",
        dataIndex:"DepCnt",
        width: "130px",
        style: [{"word-wrap":"break-word"}],
      },
      {
        align: "center",
        title: "Deposit Person",
        dataIndex:"DepPpl",
        width: "130px",
        style: [{"word-wrap":"break-word"}],
      },
      {
        align: "center",
        title: "Deposit Amount",
        dataIndex:"DepAmt",
        width: "130px",
        style: [{"word-wrap":"break-word"}],
      },
      {
        align: "center",
        title: "First Deposit User",
        dataIndex:"Dep1stCnt",
        width: "130px",
        style: [{"word-wrap":"break-word"}],
        render:function(record) {
          return <p style={{color:"blue", marginTop: "13px"}}>{record}</p>
        }
      },
      {
        align: "center",
        title: "First Time Amount",
        dataIndex:"Dep1stTime",
        width: "130px",
        style: [{"word-wrap":"break-word"}],
        render:function(record) {
          return <p style={{color:"blue", marginTop: "13px"}}>{record}</p>
        }
      },
      {
        align: "center",
        title: "First Deposit Amount",
        dataIndex:"Dep1stAmt",
        width: "130px",
        style: [{"word-wrap":"break-word"}],
        render:function(record) {
          return <p style={{color:"blue", marginTop: "13px"}}>{record}</p>
        }
      },
      {
        align: "center",
        title: "Withdraw Count",
        dataIndex:"WthCnt",
        width: "130px",
        style: [{"word-wrap":"break-word"}],
      },
      {
        align: "center",
        title: "Withdraw Person",
        dataIndex:"WthPpl",
        width: "130px",
        style: [{"word-wrap":"break-word"}],
      },
      {
        align: "center",
        title: "Withdraw Amount",
        dataIndex:"WthAmt",
        width: "130px",
        style: [{"word-wrap":"break-word"}],
      },
      {
        align: "center",
        title: "Comm2Bank",
        dataIndex:"Comm2Bank",
        width: "130px",
        style: [{"word-wrap":"break-word"}],
      },
      {
        align: "center",
        title: "Comm2Wallet",
        dataIndex:"Comm2Wallet",
        width: "130px",
        style: [{"word-wrap":"break-word"}],
      },
      // {
      //   align: "center",
      //   title: "Sale Amount of Member Level 1",
      //   dataIndex:"SaleAmountOfMemberLevel1",
      //   width: "140px",
      //   style: [{"word-wrap":"break-word"}],
      // },
      // {
      //   align: "center",
      //   title: "Sale Amount of Member Level 2",
      //   dataIndex:"SaleAmountOfMemberLevel2",
      //   width: "140px",
      //   style: [{"word-wrap":"break-word"}],
      // },
      // {
      //   align: "center",
      //   title: "Sale Amount of Member Level 3",
      //   dataIndex:"SaleAmountOfMemberLevel3",
      //   width: "140px",
      //   style: [{"word-wrap":"break-word"}],
      // },
      // {
      //   align: "center",
      //   title: "Sale Amount of Member Level Other",
      //   dataIndex:"SaleAmountOfMemberLevelOther",
      //   width: "140px",
      //   style: [{"word-wrap":"break-word"}],
      // },
    ]

    let feedColumns = [
        {
          align: "center",
          title: "Date / Time",
          dataIndex:"trxnDate",
          width: "200px",
          style: [{"word-wrap":"break-word"}],
          fixed:"left",
        },
        {
          align: "center",
          title: "Agent",
          dataIndex:"agentCode",
          width: "130px",
          style: [{"word-wrap":"break-word"}],
        },
        {
          align: "center",
          title: "Register User (All)",
          dataIndex:"regUser",
          width: "130px",
          style: [{"word-wrap":"break-word"}],
          render:function(record) {
            return <p style={{color:"blue", marginTop: "13px"}}>{record}</p>
          }
        },
        // {
        //   align: "center",
        //   title: "Register User (First Line)",
        //   dataIndex:"firstRegUser",
        //   width: "0px",
        //   style: [{"word-wrap":"break-word"}],
        //   ellipsis: true,
        // },
        {
          align: "center",
          title: "Register User (Direct Level 1)",
          dataIndex:"regUserDirect",
          width: "140px",
          style: [{"word-wrap":"break-word"}],
          // ellipsis: true,
          render:function(record) {
            return <p style={{color:"blue", marginTop: "13px"}}>{record}</p>
          }
        },
        {
          align: "center",
          title: "Invite User",
          dataIndex:"invUser",
          width: "130px",
          style: [{"word-wrap":"break-word"}],
          render:function(record) {
            return <p style={{color:"blue", marginTop: "13px"}}>{record}</p>
          }
        },
        {
          align: "center",
          title: "Bind Bank User (All)",
          dataIndex:"bindBankUser",
          width: "130px",
          style: [{"word-wrap":"break-word"}],
          render:function(record) {
            return <p style={{color:"blue", marginTop: "13px"}}>{record}</p>
          }
        },
        // {
        //   align: "center",
        //   title: "SAME DAY REG + NO DEP USER",
        //   dataIndex:"regUserNoDep",
        //   width: "130px",
        //   style: [{"word-wrap":"break-word"}],
        //   render:function(record) {
        //     return <p style={{color:"blue", marginTop: "13px"}}>{record}</p>
        //   }
        // },
        {
          align: "center",
          title: "Same Day Register + Deposit User",
          dataIndex:"depSameDayCnt",
          width: "130px",
          style: [{"word-wrap":"break-word"}],
          render:function(record) {
            return <p style={{color:"blue", marginTop: "13px"}}>{record}</p>
          }
        },
        // {
        //   align: "center",
        //   title: "Register User [No Deposit]",
        //   dataIndex:"regUserNoDep",
        //   width: "130px",
        //   style: [{"word-wrap":"break-word"}],
        //   render:function(record) {
        //     return <p style={{color:"#40bf80", marginTop: "13px"}}><b>{record}</b></p>
        //   }
        // },
        // {
        //   align: "center",
        //   title: "Register User [First Deposit]",
        //   dataIndex:"depSameDayCnt",
        //   width: "130px",
        //   style: [{"word-wrap":"break-word"}],
        //   render:function(record) {
        //     return <p style={{color:"#40bf80", marginTop: "13px"}}><b>{record}</b></p>
        //   }
        // },
        // {
        //   align: "center",
        //   title: "Register User [First Deposit Amount]",
        //   dataIndex:"firstDep",
        //   width: "130px",
        //   style: [{"word-wrap":"break-word"}],
        //   render:function(record) {
        //     return <p style={{color:"#40bf80", marginTop: "13px"}}><b>{FormatDataMobile(record)}</b></p>
        //   }
        // },
        // {
        //   align: "center",
        //   title: "Old User [First Deposit]",
        //   dataIndex:"oldUser1stDep",
        //   width: "130px",
        //   style: [{"word-wrap":"break-word"}],
        //   render:function(record) {
        //     return <p style={{color:"#40bf80", marginTop: "13px"}}><b>{record}</b></p>
        //   }
        // },
        // {
        //   align: "center",
        //   title: "Old User [First Deposit Amount]",
        //   dataIndex:"oldUser1stDepAmt",
        //   width: "130px",
        //   style: [{"word-wrap":"break-word"}],
        //   render:function(record) {
        //     return <p style={{color:"#40bf80", marginTop: "13px"}}><b>{FormatDataMobile(record)}</b></p>
        //   }
        // },
        {
          align: "center",
          title: "Deposit Count",
          dataIndex:"depCnt",
          width: "130px",
          style: [{"word-wrap":"break-word"}],
          // render:function(record, record2) {
          //   return record2.firstDepCnt == 0 ? <p style={{marginTop: "14px"}}>{record}</p> 
          //          :<div style={{marginTop: "14px"}}>
          //             <p><b>{record}</b>
          //               <span style={{color:"blue", marginTop: "14px"}}>&#x5B;<b>{record2.firstDepCnt}</b>&#93;</span>
          //             </p>
          //           </div>
          // }
        },
        // {
        //   align: "center",
        //   title: "Deposit Count (First Line)",
        //   dataIndex:"firstDepCnt",
        //   width: "0px",
        //   style: [{"word-wrap":"break-word"}],
        //   ellipsis: true,
        // },
        {
          align: "center",
          title: "Deposit User",
          dataIndex:"depPpl",
          width: "130px",
          style: [{"word-wrap":"break-word"}],
        },
        // {
        //   align: "center",
        //   title: "Deposit User (First Line)",
        //   dataIndex:"firstDepPpl",
        //   width: "0px",
        //   style: [{"word-wrap":"break-word"}],
        //   ellipsis: true,
        // },
        {
          align: "center",
          title: "Deposit Amount",
          dataIndex:"depAmt",
          width: "130px",
          style: [{"word-wrap":"break-word"}],
          render:function(record) {
            return <p style={{color:"red", marginTop: "13px"}}>{record}</p>
          }
        },
        // {
        //   align: "center",
        //   title: "Deposit Amount (First Line)",
        //   dataIndex:"firstDepAmt",
        //   width: "0px",
        //   style: [{"word-wrap":"break-word"}],
        //   ellipsis: true,
        // },
        {
          align: "center",
          title: "Max Deposit",
          dataIndex:"depMax",
          width: "130px",
          style: [{"word-wrap":"break-word"}],
        },
        {
          align: "center",
          title: "First Deposit User",
          dataIndex:"dep1stCnt",
          width: "130px",
          style: [{"word-wrap":"break-word"}],
          render:function(record) {
            return <p style={{color:"blue", marginTop: "13px"}}>{record}</p>
          }
        },
        {
          align: "center",
          title: "First Time User",
          dataIndex:"dep1stTime",
          width: "130px",
          style: [{"word-wrap":"break-word"}],
          render:function(record) {
            return <p style={{color:"blue", marginTop: "13px"}}>{record}</p>
          }
        },
        {
          align: "center",
          title: "First Deposit Amount",
          dataIndex:"dep1stAmt",
          width: "130px",
        },
        // {
        //   align: "center",
        //   title: "Max Deposit (First Line)",
        //   dataIndex:"firstMaxDep",
        //   width: "0px",
        //   style: [{"word-wrap":"break-word"}],
        //   ellipsis: true,
        // },
        {
          align: "center",
          title: "Withdraw Count",
          dataIndex:"wthCnt",
          width: "130px",
          style: [{"word-wrap":"break-word"}],
        },
        {
          align: "center",
          title: "Withdraw User",
          dataIndex:"wthPpl",
          width: "130px",
          style: [{"word-wrap":"break-word"}],
        },
        {
          align: "center",
          title: "Withdraw Amount",
          dataIndex:"wthAmt",
          width: "130px",
          style: [{"word-wrap":"break-word"}],
          render:function(record) {
            return <p style={{color:"red", marginTop: "13px"}}>{record}</p>
          }
        },
        // {
        //   align: "center",
        //   title: "Withdraw Amount (First Line)",
        //   dataIndex:"firstWthAmt",
        //   width: "0px",
        //   style: [{"word-wrap":"break-word"}],
        //   ellipsis: true,
        // },
        {
          align: "center",
          title: "Max Withdraw",
          dataIndex:"wthMax",
          width: "130px",
          style: [{"word-wrap":"break-word"}],
        },
        // {
        //   align: "center",
        //   title: "Max Withdraw (First Line)",
        //   dataIndex:"firstMaxWth",
        //   width: "0px",
        //   style: [{"word-wrap":"break-word"}],
        //   ellipsis: true,
        // },
        {
          align: "center",
          title: "Comm2Bank",
          dataIndex:"comm2Bank",
          width: "130px",
          // render:function(record) {
          //   return <p style={{marginTop: "10px"}}>{FormatDataMobile(record)}</p>
          // }
        },
        {
          align: "center",
          title: "Comm2Wallet",
          dataIndex:"comm2Wallet",
          width: "130px",
          // render:function(record) {
          //   return <p style={{marginTop: "10px"}}>{FormatDataMobile(record)}</p>
          // }
        },
        {
          align: "center",
          title: "Agent Comm (0.2%)",
          dataIndex:"agentComm",
          width: "130px",
          // render:function(record) {
          //   return <p style={{marginTop: "10px"}}>{FormatDataMobile(record)}</p>
          // }
        },
        // {
        //   align: "center",
        //   title: "Agent Commission (0.2%)",
        //   dataIndex:"agentCommission",
        //   width: "200px",
        //   render:function(record) {
        //     return <p style={{marginTop: "10px"}}>{FormatDataMobile(record)}</p>
        //   }
        // },
        // {
        //   align: "center",
        //   title: "Member Turnover",
        //   dataIndex:"memberTurnover",
        //   width: "130px",
        //   render:function(record) {
        //     return <p style={{marginTop: "10px"}}>{FormatDataMobile(record)}</p>
        //   }
        // },
        // {
        //   align: "center",
        //   title: "Member Mobile",
        //   dataIndex:"mobileList",
        //   width: "0px",
        //   ellipsis: true,
        // },
      ];
    //#endregion

    //#region Functions
    const onClickExportBtn1 = ()=> {

      if (agentReport.length === 0 ) {
        alertData.title = "Error";
        alertData.msg = "No data to download !";
        sharedErrorAlert(alertData);
        return;
      }

      document.getElementById("export-button-1").click();
    }

    const onClickExportBtn2 = ()=> {

      if (todayReport.length === 0 ) {
        alertData.title = "Error";
        alertData.msg = "No data to download !";
        sharedErrorAlert(alertData);
        return;
      }

      document.getElementById("export-button-2").click();
    }

    const onClickExportBtn3 = ()=> {

      if (marketingReport.length === 0 ) {
        alertData.title = "Error";
        alertData.msg = "No data to download !";
        sharedErrorAlert(alertData);
        return;
      }

      document.getElementById("export-button-3").click();
    }

    const resetBtn = () => {
      setTrxDateFrom1(null);
      setTrxDateTo1(null);
      setTrxDateFrom2(null);
      setTrxDateTo2(null);
      setMarketingReport([]);
      setAgentOptions();
    };

    const trxDatePicker11 = (date, dateString) => {
      setType1("custom");
      setTrxDateFrom1(date);
    };
  
    const trxDatePicker12 = (date, dateString) => {
      setType1("custom");
      setTrxDateTo1(date);
    };

    const trxDatePicker21 = (date, dateString) => {
      setType2("custom");
      setTrxDateFrom2(date);
    };
  
    const trxDatePicker22 = (date, dateString) => {
      setType2("custom");
      setTrxDateTo2(date);
    };

    const PickerWithType1 = ({ type, onChange }) => {
      if (type === 'custom'){
          setTrxDateFrom1(trxDateFrom1);
          setTrxDateTo1(trxDateTo1);
      }
      if (type === 'today'){
        let today = new Date().toLocaleDateString("en-CA", {day: "numeric"});
        setTrxDateFrom1(moment().set('date', today))
        setTrxDateTo1(moment().set('date', today))
      } 
      if (type === 'yesterday'){
        let yesterday = new Date(new Date().getTime());
        yesterday.setDate(yesterday.getDate()-1);
        let yestDay = yesterday.toLocaleDateString("en-CA", {day: "numeric"});
        let yestMonth = yesterday.toLocaleDateString("en-CA", {month: "numeric"});
        let yestYear = yesterday.toLocaleDateString("en-CA", {year: "numeric"});
        yestMonth = yestMonth - 1
  
        setTrxDateFrom1(moment().set({'year': yestYear, 'month': yestMonth, 'date': yestDay}))
        setTrxDateTo1(moment().set({'year': yestYear, 'month': yestMonth, 'date': yestDay}))
      }
      if (type === 'thisweek'){
        let today = new Date();
        let firstDay = new Date(today.setDate(today.getDate() - today.getDay() + 1));
        let firstDayOfWeek = firstDay.toLocaleDateString("en-CA", {day: "numeric"});
        let firstMonthOfWeek = firstDay.toLocaleDateString("en-CA", {month: "numeric"});
        let firstYearOfWeek = firstDay.toLocaleDateString("en-CA", {year: "numeric"});
            firstMonthOfWeek = firstMonthOfWeek - 1; 
        let lastDay = new Date(today.setDate(today.getDate() - today.getDay() + 7));
        let lastDayOfWeek = lastDay.toLocaleDateString("en-CA", {day: "numeric"});
        let lastMonthOfWeek = lastDay.toLocaleDateString("en-CA", {month: "numeric"});
        let lastYearOfWeek = lastDay.toLocaleDateString("en-CA", {year: "numeric"});
            lastMonthOfWeek = lastMonthOfWeek - 1;
        setTrxDateFrom1(moment().set({'year': firstYearOfWeek, 'month': firstMonthOfWeek, 'date': firstDayOfWeek}));
        setTrxDateTo1(moment().set({'year': lastYearOfWeek, 'month': lastMonthOfWeek, 'date': lastDayOfWeek}));
      }
      if (type === 'thismonth'){
        let date = new Date(), y = date.getFullYear(), m = date.getMonth();
        let firstDay = new Date(y, m, 1);
        let lastDay = new Date(y, m + 1, 0);
  
        let month = firstDay.toLocaleDateString("en-CA", {month: "numeric"})
  
        let year = lastDay.toLocaleDateString("en-CA", {year: "numeric"})
        lastDay = lastDay.toLocaleDateString("en-CA", {day: "numeric"})
        month = month - 1
  
        setTrxDateFrom1(moment().set({'year': year, 'month': month, 'date': 1}))
        setTrxDateTo1(moment().set({'year': year, 'month': month, 'date': lastDay}))
      }
      if (type === 'lastmonth'){
        let date = new Date(), y = date.getFullYear(), m = date.getMonth();
        let firstDay = new Date(y, m-1, 1);
        let lastDay = new Date(y, m, 0);
  
        let month = firstDay.toLocaleDateString("en-CA", {month: "numeric"})
        let yearOfFirstDay = firstDay.toLocaleDateString("en-CA", {year: "numeric"})
  
        let yearOfLastDay = lastDay.toLocaleDateString("en-CA", {year: "numeric"})
        lastDay = lastDay.toLocaleDateString("en-CA", {day: "numeric"})
        month = month - 1
  
        setTrxDateFrom1(moment().set({'year': yearOfFirstDay, 'month': month, 'date': 1}))
        setTrxDateTo1(moment().set({'year': yearOfLastDay, 'month': month, 'date': lastDay}))
      }
      return <DatePicker picker={type} onChange={onChange} />;
    };

    const PickerWithType2 = ({ type, onChange }) => {
      if (type === 'custom'){
          setTrxDateFrom2(trxDateFrom2);
          setTrxDateTo2(trxDateTo2);
      }
      if (type === 'today'){
        let today = new Date().toLocaleDateString("en-CA", {day: "numeric"});
        setTrxDateFrom2(moment().set('date', today))
        setTrxDateTo2(moment().set('date', today))
      } 
      if (type === 'yesterday'){
        let yesterday = new Date(new Date().getTime());
        yesterday.setDate(yesterday.getDate()-1);
        let yestDay = yesterday.toLocaleDateString("en-CA", {day: "numeric"});
        let yestMonth = yesterday.toLocaleDateString("en-CA", {month: "numeric"});
        let yestYear = yesterday.toLocaleDateString("en-CA", {year: "numeric"});
        yestMonth = yestMonth - 1
  
        setTrxDateFrom2(moment().set({'year': yestYear, 'month': yestMonth, 'date': yestDay}))
        setTrxDateTo2(moment().set({'year': yestYear, 'month': yestMonth, 'date': yestDay}))
      }
      if (type === 'thisweek'){
        let today = new Date();
        let firstDay = new Date(today.setDate(today.getDate() - today.getDay() + 1));
        let firstDayOfWeek = firstDay.toLocaleDateString("en-CA", {day: "numeric"});
        let firstMonthOfWeek = firstDay.toLocaleDateString("en-CA", {month: "numeric"});
        let firstYearOfWeek = firstDay.toLocaleDateString("en-CA", {year: "numeric"});
            firstMonthOfWeek = firstMonthOfWeek - 1; 
        let lastDay = new Date(today.setDate(today.getDate() - today.getDay() + 7));
        let lastDayOfWeek = lastDay.toLocaleDateString("en-CA", {day: "numeric"});
        let lastMonthOfWeek = lastDay.toLocaleDateString("en-CA", {month: "numeric"});
        let lastYearOfWeek = lastDay.toLocaleDateString("en-CA", {year: "numeric"});
            lastMonthOfWeek = lastMonthOfWeek - 1;
        setTrxDateFrom2(moment().set({'year': firstYearOfWeek, 'month': firstMonthOfWeek, 'date': firstDayOfWeek}));
        setTrxDateTo2(moment().set({'year': lastYearOfWeek, 'month': lastMonthOfWeek, 'date': lastDayOfWeek}));
      }
      if (type === 'thismonth'){
        let date = new Date(), y = date.getFullYear(), m = date.getMonth();
        let firstDay = new Date(y, m, 1);
        let lastDay = new Date(y, m + 1, 0);
  
        let month = firstDay.toLocaleDateString("en-CA", {month: "numeric"})
  
        let year = lastDay.toLocaleDateString("en-CA", {year: "numeric"})
        lastDay = lastDay.toLocaleDateString("en-CA", {day: "numeric"})
        month = month - 1
  
        setTrxDateFrom2(moment().set({'year': year, 'month': month, 'date': 1}))
        setTrxDateTo2(moment().set({'year': year, 'month': month, 'date': lastDay}))
      }
      if (type === 'lastmonth'){
        let date = new Date(), y = date.getFullYear(), m = date.getMonth();
        let firstDay = new Date(y, m-1, 1);
        let lastDay = new Date(y, m, 0);
  
        let month = firstDay.toLocaleDateString("en-CA", {month: "numeric"})
        let yearOfFirstDay = firstDay.toLocaleDateString("en-CA", {year: "numeric"})
  
        let yearOfLastDay = lastDay.toLocaleDateString("en-CA", {year: "numeric"})
        lastDay = lastDay.toLocaleDateString("en-CA", {day: "numeric"})
        month = month - 1
  
        setTrxDateFrom2(moment().set({'year': yearOfFirstDay, 'month': month, 'date': 1}))
        setTrxDateTo2(moment().set({'year': yearOfLastDay, 'month': month, 'date': lastDay}))
      }
      return <DatePicker picker={type} onChange={onChange} />;
    };

    const searchBar1 = () => {
      if (!trxDateFrom1) {
        reportDateFrom1.current.focus();
        alertData.title = "Field Required";
        alertData.msg = "Please select Date From !";
        sharedErrorAlert(alertData);
        return;
      }
  
      if (!trxDateTo1) {
        reportDateTo1.current.focus();
        alertData.title = "Field Required";
        alertData.msg = "Please select Date To !";
        sharedErrorAlert(alertData);
        return;
      }

      if (trxDateTo1 < trxDateFrom1 || trxDateFrom1 > trxDateTo1) {
        alertData.title = "Error";
        alertData.msg = "Please select a valid Date Range !";
        sharedErrorAlert(alertData);
        return;
      }

      setTrxLoading(true);
      agentData();
    }

    const searchBar2 = () => {
      if (!agentSelection) {
        reportAgentRef.current.focus();
        alertData.title = "Field Required";
        alertData.msg = "Please select An Agent !";
        sharedErrorAlert(alertData);
        return;
      }

      if (!trxDateFrom2) {
        reportDateFrom2.current.focus();
        alertData.title = "Field Required";
        alertData.msg = "Please select Date From !";
        sharedErrorAlert(alertData);
        return;
      }
  
      if (!trxDateTo2) {
        reportDateTo2.current.focus();
        alertData.title = "Field Required";
        alertData.msg = "Please select Date To !";
        sharedErrorAlert(alertData);
        return;
      }

      if (trxDateTo2 < trxDateFrom2 || trxDateFrom2 > trxDateTo2) {
        alertData.title = "Error";
        alertData.msg = "Please select a valid Date Range !";
        sharedErrorAlert(alertData);
        return;
      }

      setTrxLoading(true);
      // agentData();
      marketingData();
    }

    return (
        <div>
          {
            ReportAgentVw(role,username)?
            <div>
            <PageHeader ghost={false}>
              <div style={style.pageHeaderContainer}>
                <div 
                  style={{
                    justifyContent: "left",
                    textAlign: "center",
                    display: "flex"
                  }}
                  >
                  {/* {searchTab1 == true ?
                    <DatePicker
                      key="1"
                      style={{...style.datePickerStyle, ...style.refreshBtn}}
                      format="YYYY-MM-DD"
                      onChange={trxDatePicker}
                      value={trxDateFrom}
                      ref={reportDateFrom}
                      refs
                      placeholder={"Select Date From"}
                      disabledDate={(current)=> {
                        return current > moment().subtract(1, 'day');
                      }}
                    />
                  : ""}
                  {searchTab1 == true ?
                    <DatePicker
                      key="2"
                      style={{...style.datePickerStyle, ...style.refreshBtn}}
                      format="YYYY-MM-DD"
                      onChange={trxDatePicker2}
                      value={trxDateTo}
                      ref={reportDateTo}
                      placeholder={"Select Date To"}
                      disabledDate={(current)=> {
                        let dateFrom = trxDateFrom;
                        if (dateFrom) {
                          return current < dateFrom || current > moment().subtract(1, 'day');
                        }else {
                          return  current > moment().subtract(1, 'day');
                        }
                      }}
                    />
                  : ""} */}
                  {searchTab3 == true ?
                    <Select
                        style={{...style.datePickerStyle, ...style.refreshBtn}}
                        optionFilterProp="children"
                        ref={reportAgentRef}
                        filterOption={(input, option) => {
                          return option.children.includes(input);
                        }}
                        placeholder="Please select an agent"
                        getPopupContainer={(trigger) => trigger.parentNode}
                        onChange={(value, event) => {
                          setAgentSelection(value);
                          for (var i = 0; i < agentOptions.length; i++) {
                            if (agentOptions[i].NickName === agentSelection) {
                            }
                          }
                        }}
                      >
                        {agentOptions.map((option) => {
                          return (
                            <Option key={option.key} value={`${option.Agent}`}>
                              {option.NickName}
                            </Option>
                          );
                        })}
                      </Select>
                    : ""}
                  {searchTab1 == true ?
                    <DatePicker
                      key="1"
                      style={{...style.datePickerStyle, ...style.refreshBtn}}
                      format="YYYY-MM-DD"
                      onChange={trxDatePicker11}
                      value={trxDateFrom1}
                      ref={reportDateFrom1}
                      refs
                      placeholder={"Select Date From"}
                      disabledDate={(current)=> {
                        return current > moment().subtract(1, 'day');
                      }}
                    />
                  : ""}
                  {searchTab1 == true ?
                    <DatePicker
                      key="2"
                      style={{...style.datePickerStyle, ...style.refreshBtn}}
                      format="YYYY-MM-DD"
                      onChange={trxDatePicker12}
                      value={trxDateTo1}
                      ref={reportDateTo1}
                      placeholder={"Select Date To"}
                      disabledDate={(current)=> {
                        let dateFrom = trxDateFrom1;
                        if (dateFrom) {
                          return current < dateFrom || current > moment().subtract(1, 'day');
                        }else {
                          return  current > moment().subtract(1, 'day');
                        }
                      }}
                    />
                  : ""}
                  {searchTab1 == true ?
                  <Space>
                    <Select style={{...style.datePickerSelectionStyle}} value={type1} type={type1} onChange={setType1}>
                      <Option value="custom">Custom</Option>
                      <Option value="today">Today</Option>
                      <Option value="yesterday">Yesterday</Option>
                      <Option value="thisweek">This Week</Option>
                      <Option value="thismonth">This Month</Option>
                      <Option value="lastmonth">Last Month</Option>
                    </Select>
                  </Space>
                  : ""}
                  {searchTab3 == true ?
                    <DatePicker
                      key="1"
                      style={{...style.datePickerStyle, ...style.refreshBtn}}
                      format="YYYY-MM-DD"
                      onChange={trxDatePicker21}
                      value={trxDateFrom2}
                      ref={reportDateFrom2}
                      refs
                      placeholder={"Select Date From"}
                      disabledDate={(current)=> {
                        return current > moment().subtract(1, 'day');
                      }}
                    />
                  : ""}
                  {searchTab3 == true ?
                    <DatePicker
                      key="2"
                      style={{...style.datePickerStyle, ...style.refreshBtn}}
                      format="YYYY-MM-DD"
                      onChange={trxDatePicker22}
                      value={trxDateTo2}
                      ref={reportDateTo2}
                      placeholder={"Select Date To"}
                      disabledDate={(current)=> {
                        let dateFrom = trxDateFrom2;
                        if (dateFrom) {
                          return current < dateFrom || current > moment().subtract(1, 'day');
                        }else {
                          return  current > moment().subtract(1, 'day');
                        }
                      }}
                    />
                  : ""}
                  {searchTab3 == true?
                  <Space>
                    <Select style={{...style.datePickerSelectionStyle}} value={type2} type={type2} onChange={setType2}>
                      <Option value="custom">Custom</Option>
                      <Option value="today">Today</Option>
                      <Option value="yesterday">Yesterday</Option>
                      <Option value="thisweek">This Week</Option>
                      <Option value="thismonth">This Month</Option>
                      <Option value="lastmonth">Last Month</Option>
                    </Select>
                  </Space>
                  : ""}
                  {searchTab1 == true ?
                    <Button
                      type={"primary"}
                      style={style.searchResetStyle}
                      icon={<SearchOutlined
                      onClick={searchBar1}
                      />}
                    />
                  : ""}
                  {searchTab3 == true ?
                    <Button
                      type={"primary"}
                      style={style.searchResetStyle}
                      icon={<SearchOutlined
                      onClick={searchBar2}
                      />}
                    />
                  : ""}
                  {searchTab3 == true ?
                    <Button style={{...style.resetBtn,...style.refreshBtn}} onClick={resetBtn}>
                      Reset
                    </Button>
                  : ""}
                {
                  ReportAgentVw(role)?
                  searchTab1 == true ?
                    <Button 
                        type={"primary"} 
                        style={style.refreshBtn} 
                        icon={<FileExcelOutlined />}
                        onClick={() => {
                            onClickExportBtn1();
                        }}
                        
                    >
                      Export to Excel
                    </Button>
                  :
                  searchTab2 == true ?
                    <Button 
                        type={"primary"} 
                        style={style.refreshBtn} 
                        icon={<FileExcelOutlined />}
                        onClick={() => {
                            onClickExportBtn2();
                        }}
                        
                    >
                      Export to Excel
                    </Button>
                  :
                  searchTab3 == true ?
                    <Button 
                        type={"primary"} 
                        style={style.refreshBtn} 
                        icon={<FileExcelOutlined />}
                        onClick={() => {
                            onClickExportBtn3();
                        }}
                        
                    >
                      Export to Excel
                    </Button>
                  : ""
                  :<></>
                }
                </div>
              </div>

            </PageHeader>
            <div style={style.tabelContainer}>
              <Tabs defaultActiveKey="1" onChange={onChangeTransferTab}>
                <TabPane tab={title1} key="1">
                  <Table
                    loading={modal1Loading}
                    columns ={agentColumns}
                    dataSource= {agentReport}
                    scroll={{ x: isMobile ? "110%" : "100%", y: null }}
                    pagination={{
                      defaultPageSize: 5,
                      showSizeChanger: true,
                      pageSizeOptions: ["50"],
                      style: style.paginationStyle,
                      alignmentBottom: 'right',
                    }}
                    exportableProps={{
                      // feedFields,
                      fileName: "AgentListReport_"+todayDate,
                      btnProps: {
                        type: "primary",
                        icon: <FileExcelOutlined />,
                        id:"export-button-1",
                        children: <span>Export to Excel</span>,
                        style: {display:"none"}
                      },
                    }}
                  />
                  <br></br>
                    <p><b>REMARK :</b></p>
                    <p><span style={{color:"blue"}}><b>[1]</b></span> The first automatic search is lifetime data</p>
                    <p><span style={{color:"blue"}}><b>[2]</b></span> Register User = All users under the agent.</p>
                    <p><span style={{color:"blue"}}><b>[3]</b></span> Register User = Direct User + Invite User</p>
                    <p><span style={{color:"blue"}}><b>[4]</b></span> Direct User = Agents' Direct User at Level-1, Invite User = = Agents' User starting Level-2 onwards (unlimited level.)</p>
                    <p><span style={{color:"blue"}}><b>[5]</b></span> 'First Deposit Count' and 'First Deposit Amt' is the 1st Time Deposit, if User registered on 15th, but 1st deposit at 18th, so First Deposit Amt is under 18th.</p>
                </TabPane>
                <TabPane tab={title2} key="2">
                  {/* <Table
                    loading={modal2Loading}
                    columns ={userIDColumns}
                    dataSource= {userIdResult}
                    scroll={{ x: isMobile ? "110%" : "100%", y: null }}
                    pagination={settings.pagination}
                  /> */}
                  <Table
                    loading={modal2Loading}
                    columns ={todayColumns}
                    dataSource= {todayReport}
                    scroll={{ x: isMobile ? "110%" : "100%", y: null }}
                    pagination={{
                      defaultPageSize: 5,
                      showSizeChanger: true,
                      pageSizeOptions: ["50"],
                      style: style.paginationStyle,
                      alignmentBottom: 'right',
                    }}
                    exportableProps={{
                      // feedFields,
                      fileName: "TodayListReport_"+todayDate,
                      btnProps: {
                        type: "primary",
                        icon: <FileExcelOutlined />,
                        id:"export-button-2",
                        children: <span>Export to Excel</span>,
                        style: {display:"none"}
                      },
                    }}
                  />
                  <p><b>REMARK :</b></p>
                    <p><span style={{color:"blue"}}><b>[1]</b></span> Last update at <b>{todayLastUpdate}</b></p>
                </TabPane>
                <TabPane tab={title3} key="3">
                  {/* <Table
                    loading={modal3Loading}
                    columns ={todayColumns}
                    dataSource= {agent}
                    scroll={{ x: isMobile ? "110%" : "100%", y: null }}
                    pagination={settings.pagination}
                  /> */}
                  <Table
                      loading={modal3Loading}
                      columns ={feedColumns}
                      dataSource= {marketingReport}
                      scroll={{ x: isMobile ? "110%" : "100%", y: null }}
                      exportable={true}
                      pagination={{
                        defaultPageSize: 5,
                        showSizeChanger: true,
                        pageSizeOptions: settings.pageSizeOptions,
                        style: style.paginationStyle,
                        alignmentBottom: 'right',
                      }}
                      exportableProps={{
                        // feedFields,
                        fileName: "MarketingPerformanceReport_"+todayDate,
                        btnProps: {
                          type: "primary",
                          icon: <FileExcelOutlined />,
                          id:"export-button-3",
                          children: <span>Export to Excel</span>,
                          style: {display:"none"}
                        },
                      }}
                    />
                    <br></br>
                    <p><b>REMARK :</b></p>
                    <p><span style={{color:"blue"}}><b>[1]</b></span> All transactions are under this agent direct downline only.</p>
                    <p><span style={{color:"blue"}}><b>[2]</b></span> Amount in RED color is &#8250;= 5000 OR &#8249;= -5000 (THB)</p>
                    <p><span style={{color:"blue"}}><b>[3]</b></span> Transaction listed in last 30 days.</p>
                    <p><span style={{color:"blue"}}><b>[4]</b></span> DEP mean Deposit. WTH mean Withdraw</p>
                </TabPane>
                    <div style={{...style.inline, ...style.hide}}>
                      <PickerWithType1 type={type1} onChange={(value) => console.log(value)} />
                      <PickerWithType2 type={type2} onChange={(value) => console.log(value)} />
                    </div>
              </Tabs>
            </div>
            </div> :
            <div style={{textAlign:"center",color:"red",fontSize:"150%"}}><h1 style={{color:"red"}}>Not Allowed to Access This Page</h1></div>
          }      
        </div>
    );
}