import React, { useState, useEffect } from "react";
import {
    Table,
    Form,
    Button,
    PageHeader,
    Modal,
    Input,
    InputNumber,
    Select,
    Radio,
    Badge,
    Typography,
    Card,
    Row,
    Col,
    Statistic,
    Space,
    Spin,
    Divider,
    Image
} from "antd";
import { 
    QuestionCircleOutlined, 
    SearchOutlined, 
    StopOutlined, 
    CheckCircleOutlined,
    ReloadOutlined,
    FileImageOutlined,
} from "@ant-design/icons";
import { 
    csWithdrawListV3,
    csWithdrawListV4,
    csWithdrawCheckV3,
    csWithdrawCheckV4,
    csVerifyMobile,
    userBankList,
    csCreateWithdrawalV4,
    csWithdrawApproveV4,
    csWithdrawManualApproveV3,
    csWithdrawRejectV5,
    getReqToken,
    switchWithdrawMode,
    getCompanyProfileData,
    reprocessWithdrawV2,
    getBankBalanceWithAPI,
    reloadBankService,
    getUnconfirmPuppetList,
    getUnconfirmPuppetListV2,
    reprocessUnconfirmTrxn,
    clearUnconfirmTrxn,
    rejectUnconfirmTrxn,
    getBankBalanceWithAPIV2,
    getBankBalanceWithAPIV3,
    getBankDetailList,
    updateWithdrawBalanceOnyx,
    getLowestBalanceOnyx,
    getTrxnScreenshot,
    getProcessingWithdrawList,
    extractTransactionLabel
} from "../../config/networkConfig";
import Highlighter from "react-highlight-words";
import { isMobile } from "react-device-detect";
import { settings } from "../../config/appConfig";
import { PROCESS_TYPE } from "../../common/constants/processType";
import { WALLET_TO_BANK, COMM_TO_BANK, COMM_TO_WALLET } from "../../common/constants/constant";
import { FormatPt , ShowMobileNumber} from "../../library/numbers";
import style from "../../resource/style/style";
import { useSelector } from "react-redux";
import { witMgmtManCrWit, witMgmtManApprRej, switchWithdrawModeVw } from "../../library/permission";
import md5 from "md5";
import { alertData, sharedSuccessAlert, sharedErrorAlert, sharedFailAlert, sharedConfirmationAlert, } from "../../common/constants/sharedMsgBox";
import { BANK_INFO_TYPE } from "../../common/constants/bankType";
import moment from "moment";
// import SwipeButton from 'react-swipezor';
// import styles from "./resource/style/style.css";

export default function ManualWithdrawV5() {
const { TextArea } = Input;
const { Paragraph } = Typography;
const { confirm } = Modal;
const { Option } = Select;

const { userId, username, role, companyId } = useSelector((state) => state.login);
const [status, setStatus] = useState("Pending");
const [searchText, setSearchText] = useState("");
const [tableLoading, setTableLoading] = useState(false);
const [data, setData] = useState([]);

// const [reset, setReset] = useState("");
// const [autoReset, setAutoReset] = useState("");
// const [manualReset, setManualReset] = useState("");
// const [rejectReset, setRejectReset] = useState("");

const [currentPage, setCurrentPage] = useState(1)
const [currentPageSize, setCurrentPageSize] = useState(10)
const [dataCount, setDataCount] = useState(0)

const [formCreateWithdraw] = Form.useForm();
const [manualWithdraw, setManualWithdrawVisible] = useState(false);
const [countryCode, setCountryCode] = useState("66");
const [phoneNum, setPhoneNum] = useState("");
const [withdrawType, setWithdrawType] = useState("");
const [withdrawTypeDisable, setWithdrawTypeDisable] = useState(true);
const [withdrawHideBank, setWithdrawHideBank] = useState(true);
const [acctno, setAcctNo] = useState(""); 
const [acctname, setAcctname] = useState("");
const [withdrawToBank, setWithdrawToBank] = useState("");
const [withdrawToBankAccNum, setWithdrawToBankAccNum] = useState("");
const [withdrawToBankCode, setWithdrawToBankCode] = useState("");
const [withdrawToBankData, setWithdrawToBankData] = useState("");
const [withdrawAmtLabel, setWithdrawAmtLabel] = useState("Amount");
const [withdrawMinAmt, setWithdrawMinAmt] = useState(0);
const [withdrawAmt, setWithdrawAmt] = useState(0);
const [createWithdrawtDisable, setCreateWithdrawDisable] = useState(true);
const [createButtonLoading, setCreateButtonLoading] = useState(false);

const [csAvaBankList, setCSAvaBankListOptions] = useState([]);
const [bankcode, setBankCode] = useState("");

const [withdrawBankList, setWithdrawBankList] = useState([]);
const [withdrawBankListFilter, setWithdrawBankListFilter] = useState([]);

const [withdrawTrxnID, setWithdrawTrxnID] = useState("");
const [withdrawMemberID, setWithdrawMemberID] = useState("");

const [formRejectWithdraw] = Form.useForm();
const [rejectReason, setRejectReason] = useState("");
const [rejectWithdrawVisible, setRejectWithdrawVisible] = useState(false);
const [rejectButtonLoading, setRejectButtonLoading] = useState(false);

const [toggleCheck, setToggleCheck] = useState(false);
const [toggleLoading, setToggleLoading] = useState(false);
const [commToBankMin, setCommToBankMin] = useState(1);
const [commToWalletMin, setCommToWalletMin] = useState(1);

const now = new Date().toLocaleTimeString();
const [time, setTime] = useState(now);

const [bankListLoading, setBankListLoading] = useState(true);
const [reloadPinLoading, setReloadPinLoading] = useState(false);

const [accBalanceForm] = Form.useForm();
const [modifyAccBalanceToggle, setModifyAccBalanceToggle] = useState(false);
const [selectedAcc, setSelectedAcc] = useState('');
const [selectedAccBankCode, setSelectedAccBankCode] = useState('');
const [lowestBalanceAccNo, setLowestBalanceAccNo] = useState('');
const [lowestBalanceBankCode, setLowestBalanceBankCode] = useState('');

const [unconfirmScreenshotModal, setUnconfirmScreenshotModal] = useState(false);
const [unconfirmScreenshotName, setUnconfirmScreenshotName] = useState("");
const [screenshotRecord, setScreenshotRecord] = useState("");
const [showScreenshot, setShowScreenshot] = useState(false);

// usestate for OCR function
const [OCRAction, setOCRAction] = useState('');        // To store the OCR result (label)
const [OCRLoading, setOCRLoading] = useState(false); // To show loading state
const [OCRError, setOCRError] = useState(''); 
const [OCRRetry, setOCRRetry] = useState(false)
const [transactionRetryCounts, setTransactionRetryCounts] = useState({}); // if retry attempts exceeded 3 times, contact IT
const [currentTransaction, setCurrentTransaction] = useState('');

// This refresh the page every 120 seconds
// useEffect(()=> {
//     const interval = setInterval(() => {
//         ManualWithdrawList();
//         getCompanyWithdrawBankList();
//         setStatus(status)

//         const newTime = new Date().toLocaleTimeString();
//         setTime(newTime);
//     }, 1000 * 120);

//     return () => {
//       clearInterval(interval);
//     };
// }, [time] )
useEffect(() => {
    getCompanyProfile();
}, []);

useEffect(() => {
    ManualWithdrawList();
    getCompanyWithdrawBankList();
}, [status, currentPage, currentPageSize]);

useEffect(() => {
    submitWithdrawCheckList();
}, [phoneNum,withdrawToBank,withdrawToBankAccNum,withdrawToBankCode,withdrawAmt]);

useEffect(()=> {
    if (withdrawToBankData != null) {
        let withdrawBankData = withdrawToBankData.split("||");
        setWithdrawToBankCode((withdrawBankData[3]||'').trim());
        setWithdrawToBankAccNum((withdrawBankData[1]||'').trim());
        setWithdrawToBank((withdrawBankData[2]||'').trim());
        setBankCode((withdrawBankData[0]||'').trim());
    }
},[withdrawToBankData])

// Onchange for checking all manual withdraw field before unlock submit button
const submitWithdrawCheckList = async () => {
    let phoneCheck = true, withdrawToBankCheck = true, withdrawAmtCheck = true;

    if(!phoneNum) { 
        phoneCheck = false; 
    }
  
    if(!bankcode) { 
        withdrawToBankCheck = false; 
    }

    if(withdrawAmt <= 0) {
        withdrawAmtCheck = false; 
    }
    
    if (withdrawType == COMM_TO_WALLET) {
        if (phoneCheck && withdrawAmtCheck) {
            setCreateWithdrawDisable(false);
        }
    }
    else{
        if (phoneCheck && withdrawToBankCheck && withdrawAmtCheck) {
            setCreateWithdrawDisable(false);
        }
    }
}

// companyId is from useSelector, to get data of enablewithdrawbyAPI, setCommToBankMin, setCommToWalletMin
const getCompanyProfile = async () => {
    let requestData = {
      companyID: companyId,
      columnRequire: ['EnableWithdrawByAPI', 'setCommToBankMin', 'setCommToWalletMin']
    }
    setToggleLoading(true);

    const resCompanyProfile = await getCompanyProfileData(requestData);
    const response = await resCompanyProfile.json();
    if (response.status === "ok") {
        setToggleCheck(response.data[0].EnableWithdrawByAPI == 'Y' ? true : false);
        setCommToBankMin(response.data[0].setCommToBankMin > 0 ? response.data[0].setCommToBankMin : 1);
        setCommToWalletMin(response.data[0].setCommToWalletMin > 0 ? response.data[0].setCommToWalletMin : 1);
        setToggleLoading(false);
    }
    else{
        setToggleLoading(false);
        alertData.title = "Error";
        alertData.msg = response.msg;
        sharedErrorAlert(alertData);
    }
  };

const getCompanyWithdrawBankList = async () => {
    setBankListLoading(true);

    let requestData = {
        bankType : BANK_INFO_TYPE.WIT,
        columnRequire : ['AccountName','AccountNum', 'BankCode', 'apiAuthKey', 'WithdrawLimit', 'Status', 'TransactionMethod']
    };

    let itemList = [];

    // const resWithdrawBankList = await getBankBalanceWithAPIV2(requestData);
    const resWithdrawBankList = await getBankBalanceWithAPIV3(requestData);
    const response = await resWithdrawBankList.json();
    if (response.status === "ok") {
        setTableLoading(false);
        setWithdrawBankList(response.data.bankList.length > 0 ? response.data.bankList : []);
        // Process bank list and create as filter
        response.data.bankList.forEach((item) => {
            let bankExist = itemList.some(item2 => item2.accNo === item.accNo);
            if (!bankExist) { 
                itemList.push({ 
                    'text' : item.accNo,
                    'value' : String(item.accNo).slice(0, 10)
                })
            }
        })
        setWithdrawBankListFilter(itemList);
        setBankListLoading(false);
    }
    // else {
    //     alertData.title = "Failed";
    //     alertData.msg = "An unexpected error occurred. Please refresh and try again.";
    //     sharedFailAlert(alertData);
    //     setTableLoading(false);
    //     setBankListLoading(false);
        
    // }
}

const ManualWithdrawList = async () => {
    setTableLoading(true);
    // var count = 0;
    let count = (currentPage -1) * currentPageSize
    let startIndex = (currentPage - 1) * currentPageSize
    if (status == 'Pending' || status == 'Completed' || status == 'Rejected'){
        let requestData = {
            userId : userId, // from useSelector 
            processtype : `('A','M')`,
            status : (status == 'Pending') ? `('1','0')` :
                    (status == "Completed") ? `('3')` :
                    (status == "Rejected") ? `('9')` :
                    `('1')`,
            listFilter : status,
            pageLimit: currentPageSize,
            recordLimit: 2000,
            startIndex: startIndex.toString(),
        }

    const resWithdrawList = await csWithdrawListV4(requestData);
    let result = await resWithdrawList.json();

    if (result.status == "ok") {

        const bankDataCount = result.data[0]?.totalCount || 0
        setDataCount(bankDataCount);

        const newData = Object.keys(result.data).length
        ? result.data.map((x) => {
            return {
                key : count++,
                trxnId : x.trxnId,
                transactionID : x.trxnId,
                userId : x.userId,
                toNickname : x.toNickName,
                nickname : x.nickname,
                mobile  : x.mobile,
                newNickname : ShowMobileNumber(role, x.nickname, x.mobile),
                amount : x.trxnMode == WALLET_TO_BANK ? Number(x.amount * -1).toFixed(2) : x.trxnMode == COMM_TO_BANK ? Number(x.commission * -1).toFixed(2) : 0.00,
                date : x.dateTime,
                time : x.sysCreateTime,
                bankNameOut : x.bankName,
                bankAcctOut : x.bankAcc,
                bankCodeOut : x.bank,
                processBy : x.processBy,
                puppetRemark : x.puppetRemark,
                remark : x.remark,
                processType : x.processType,
                processByCS : x.processByCS,
                completeByCS : x.completeByCS,
                createdBy : x.sysCreateBy,
                bankAccount : x.mbBank != '' ? `${x.mbBank} (${x.mbBankAcc})` : '',
                transferFrom : x.bank != '' ? `${x.bank} (${x.bankAcc})` : '',
                // transferFromBankBalance : Number(x.transferFromBankBalance) > 0 ? Number(x.transferFromBankBalance).toFixed(2) : '0.00',
                trxnMode : x.trxnMode,
                agentId : x.agentId,
                // new details
                bankAcctTo : x.mbBankAcc,
                bankCodeTo : x.mbBank,
                bankNameTo : x.mbBankAccName,
                checkStatus:
                x.status === "0" || x.status === "1"
                ? "Pending"
                // : x.status === "1"
                // ? "Approved"
                // : (x.status === "3" && x.puppetRemark == "We are taking longer than usual to complete your transaction. If you have performed a financial transaction, please check your transaction details before proceeding.")
                // ? "Unconfirm"
                : x.status === "5"
                ? "On-hold"
                : x.status === "3"
                ? "Completed"
                : x.status === "9"
                ? "Rejected"
                : "-",
            };
            })
        : [];

        // const pending = newData.filter((val) => {
        //     return val.checkStatus === "Pending" && val.remark != 'processing';
        // });
        // const approve = newData.filter((val) => {
        //     return val.checkStatus === "Completed";
        // });
        // const unconfirm = newData.filter((val) => {
        //     return val.checkStatus === "Unconfirm" || val.checkStatus === 'On-hold' || val.checkStatus === "Approved";
        // });
        // const reject = newData.filter((val) => {
        //     return val.checkStatus === "Rejected";
        // });

        // const sortData =
        // status === "Pending"
        //     ? pending
        //     : status === "Completed"
        //     ? approve
        //     : status === "Unconfirm"
        //     ? unconfirm
        //     : status === "Rejected"
        //     ? reject
        //     : [...newData];

        setData(newData);
        setTableLoading(false);
    }
    else {
        setTableLoading(false);
        alertData.title = "Failed";
        alertData.msg = result.msg;
        sharedFailAlert(alertData);
    }}

    // Go to puppet_joblist to retrieve data that have PptJobStatus = 5 
    else if (status == 'Unconfirm'){

        let requestData = {
            pageLimit: currentPageSize,
            startIndex: startIndex.toString(), 
            recordLimit: 2000,
        }

        const resUnconfirmPuppetList = await getUnconfirmPuppetListV2(requestData);
        let result = await resUnconfirmPuppetList.json();

        if (result.status == "ok") {      

            const bankDataCount = result.data[0]?.totalCount || 0
            setDataCount(bankDataCount);

            const newData = Object.keys(result.data).length
            ? result.data.map((x) => {
                return {
                    key : count++,
                    userId : x.userId,
                    date : x.DateTime,
                    newNickname : x.Mobile,
                    transactionID : x.TransactionID,
                    bankAccount : x.ToBankAcc,
                    trxnMode : x.PptJobType,
                    transferFrom : x.FromAccNo,
                    status: x.PptJobStatus,
                    amount: Number(x.Amount).toFixed(2),
                    checkStatus: x.PptJobStatus === 5
                    ? "Unconfirm" : "",
                    trxnId : x.TransactionID,
                };
                })
            : [];

            setData(newData);
            setTableLoading(false);
        }
        else {
            setData([]);
            setTableLoading(false);
        }

    }else if(status == 'Processing'){
        let requestData = {
            pageLimit: currentPageSize,
            startIndex: startIndex.toString(), 
            recordLimit: 2000,
        }

        const processingWithdrawList = await getProcessingWithdrawList(requestData);
        let result = await processingWithdrawList.json();
        console.log(result);
        if (result.status == "ok") {      

            // const bankDataCount = result.data[0]?.totalCount || 0
            const bankDataCount = result.data[0]?.length || 0
            setDataCount(bankDataCount);

            const newData = Object.keys(result.data).length
            ? result.data.map((x) => {
                return {
                    key : count++,
                    userId : x.userId,
                    date : x.dateTime,
                    newNickname : x.mobile,
                    transactionID : x.trxnId,
                    bankAccount : x.mbBankAcc,
                    trxnMode : x.trxnMode,
                    transferFrom : `${x.bank} (${x.bankAcc})`,
                    // status: x.PptJobStatus,
                    amount: x.amount.toFixed(2) || '0.00',
                    bankAccount : x.mbBank ? `${x.mbBank} (${x.mbBankAcc})` : '',
                    checkStatus: 0,
                    // checkStatus: x.PptJobStatus === 5
                    // ? "Unconfirm" : "",
                    trxnId : x.TransactionID,
                    processByCS: x.processByCS,
                    processType: x.processType
                };
                })
            : [];

            setData(newData);
            setTableLoading(false);
        }
        else {
            setData([]);
            setTableLoading(false);
        }
    }
}

// reset the retry count in view transaction feature
const resetRetryCount = (transactionID) => {
    setTransactionRetryCounts(prevCounts => {
        const updatedCounts = { ...prevCounts };
        delete updatedCounts[transactionID]; // Remove the successful transactionId
        return updatedCounts;
    });
}

const handleCancel = () => {
    resetRetryCount(currentTransaction); 
    setUnconfirmScreenshotModal(false);  // Close the modal
};

// Function to handle the OCR process
const handleOCRProcess = async (imageUrl, transactionId) => {
    setOCRLoading(true);
    setOCRError('');
    setOCRAction(''); 
    setOCRRetry(false);
    
    const MAX_RETRIES = 5; // Maximum retry limit
    let TIMEOUT_DURATION = 10000 // 10 secs timeout

    const currentRetryCount = transactionRetryCounts[transactionId] || 0;

    const executeOCR = async () => {
        
        setTransactionRetryCounts(prevCounts => ({
            ...prevCounts,
            [transactionId]: currentRetryCount + 1,
        }));

        let timedOut = false;

        // Set timeout for the OCR process
        const timeout = setTimeout(() => {
            timedOut = true;
            
            if (currentRetryCount < MAX_RETRIES) {
                setOCRError('The process is taking too long. Please try again.');
            } else {
                setOCRError('The process failed after multiple attempts');
            }

            setOCRLoading(false);
            setOCRRetry(currentRetryCount < MAX_RETRIES); // Show retry option if attempts are less than max
        }, TIMEOUT_DURATION);

        try {
            const response = await extractTransactionLabel(imageUrl); 

            if (!response.ok) {
                setOCRError('Backend responded with an error'); 
            }

            const result = await response.json();

            // Clear the timeout if the request is successful and hasn't timed out
            if (!timedOut) {
                clearTimeout(timeout);  

                if (result.status === 'ok' && result.data && result.data.action) {
                    const action = result.data.action; // Access the action in the response
                    setOCRAction(action); // Set the action

                    // Remove the transactionId from the retry count state on success
                    resetRetryCount(transactionId);
                } else {
                    setOCRError('No valid action found in response'); // Trigger catch block
                }
            }
        } catch (err) {
            clearTimeout(timeout); // Clear the timeout if an error occurs before timeout
            setOCRError('Error processing the image: ' + err.message);

            // Retry logic
            if (currentRetryCount < MAX_RETRIES) {
                executeOCR(); // Retry the OCR process
            } else {
                setOCRLoading(false); // Stop loading after max retries
                setOCRRetry(true); // Show retry option after max attempts
            }
        } finally {
            if (!timedOut) {
                setOCRLoading(false); // Ensure loading is stopped if not timed out
            }
        }
    };

    // Start the OCR process
    executeOCR();
};


const handleViewScreenshot = async (record) => {
    setUnconfirmScreenshotName(record.newNickname+"_"+Number(record.amount).toFixed(2))
    setScreenshotRecord("");

    const transactionID =  record.transactionID;

    setCurrentTransaction(transactionID);

    let requestData = {
        transactionID: transactionID
    }
    
    const resUnconfirmScreenshot = await getTrxnScreenshot(requestData);
    let resultScreenshot = await resUnconfirmScreenshot.json();

    if (resultScreenshot.status == "ok") {  
        const imageUrl = resultScreenshot.data.url

        handleOCRProcess(imageUrl, transactionID)

        setScreenshotRecord(imageUrl);
        setShowScreenshot(imageUrl ? true : false);
    }
    else{
        alertData.title = "Failed";
        alertData.msg = resultScreenshot.msg;   
        sharedFailAlert(alertData);
    }

    setUnconfirmScreenshotModal(true)
}

const verifyMobile = async () => {
    await csVerifyMobile({
        mobile: countryCode + phoneNum
    })
    .then((response) => response.json())
    .then(async (json) => {
        if (json.status === "ok") {
            if ((json.data['userid']) != undefined) {
                setWithdrawTypeDisable(false);
                setWithdrawMemberID(json.data['userid']);
            }  
        }
        else {
            clearAllFields();
            alertData.title = "Error";
            alertData.msg = `Member does not exist !`;
            sharedErrorAlert(alertData);
      }
    })
    .catch((error) => {
        console.log("error: ", error);
        alertData.title = "Error";
        alertData.msg = `An unexpected error occurred. Please contact your system administrator.`;
        sharedErrorAlert(alertData);
    });
}

const handleWithdrawType = async (value) => {
    setWithdrawType(value);

    if (withdrawMemberID != ''){

        if (value == WALLET_TO_BANK || value == COMM_TO_BANK) {
            setWithdrawHideBank(false);
            setWithdrawAmtLabel(value == WALLET_TO_BANK ? "Amount" : `Amount (min ${commToBankMin})`);
            setWithdrawMinAmt(value == WALLET_TO_BANK ? 1 : commToBankMin);
            setWithdrawAmt(value == WALLET_TO_BANK ? 1 : commToBankMin);

            const getUserBank =  await userBankList({userid: withdrawMemberID});
            let result = await getUserBank.json();

            if (result.status == "ok") {
                let bankResp = result.data;

                if (Object.keys(result.data).length == 0 ) {
                    clearAllFields();
                    alertData.title = "Error";
                    alertData.msg = `Please create Bank Account for user +${countryCode+phoneNum}`;
                    sharedErrorAlert(alertData);
                }
                else {
                    setCSAvaBankListOptions(bankResp);
                    let firstBank = bankResp.find(function(bd) { return bd.bankcode != '' });
                    setBankCode(`${firstBank.bank}`);
                    setAcctNo(`${firstBank.acctnum}`);
                    setAcctname(`${firstBank.acctname}`)
                    setWithdrawToBankData(`${firstBank.bank} || ${firstBank.acctnum} || ${firstBank.acctname} || ${firstBank.bankcode}`);
                }
            }
        }
        else{
            setWithdrawHideBank(true);
            setWithdrawAmtLabel(`Amount (min ${commToWalletMin})`);
            setWithdrawMinAmt(commToWalletMin);
            setWithdrawAmt(commToWalletMin);
        }
      
    }
}

const clearAllFields = () => {
    setCountryCode("66");
    setPhoneNum("");
    setWithdrawMemberID("");
    setWithdrawToBankData("");
    setCSAvaBankListOptions([]);
    setBankCode("");
    setWithdrawToBankCode("");
    setWithdrawToBankAccNum("");
    setWithdrawToBank("");
    setAcctNo("");
    setAcctname("");
    setWithdrawAmt(0);
    setCreateWithdrawDisable(true);
    setWithdrawTypeDisable(true);
    setWithdrawType("");
    setWithdrawHideBank(true);
    setWithdrawAmtLabel("Amount");
    setWithdrawMinAmt(0);
    formCreateWithdraw.resetFields();
}

const handleCreateWithdraw = async () => {
    setCreateButtonLoading(true);

    let requestData = {
        userid: withdrawMemberID,
        bankcode: withdrawToBankCode,
        acctnum: withdrawToBankAccNum,
        acctname: withdrawToBank,
        type: withdrawType,
        csUserId: userId,
        reqToken : md5(getReqToken("wallet2bankManualCreate")),
        processtype : PROCESS_TYPE.MANUAL
    };

    if (withdrawType == WALLET_TO_BANK) {
        requestData.amt = withdrawAmt;
    }

    if (withdrawType == COMM_TO_BANK || withdrawType == COMM_TO_WALLET) {
        requestData.comm = withdrawAmt;
    }
    
    const resCreateManualWithdraw = await csCreateWithdrawalV4(requestData);
    let result = await resCreateManualWithdraw.json();

    if (result.status == "ok") {
        if (dataCount !== 1 && dataCount === ((currentPage - 1) * currentPageSize) + 1) {
            setCurrentPage(prevPage => Math.max(prevPage - 1, 1));
        } else {
            ManualWithdrawList();
        }
        onCancelCreateWithdraw();
        setCreateButtonLoading(false);
        alertData.title = "Success";
        alertData.msg = result.msg;
        sharedSuccessAlert(alertData);
    }
    else {
        setCreateButtonLoading(false);
        alertData.title = "Failed";
        alertData.msg = result.msg;
        sharedFailAlert(alertData);
    }
}

const onCancelCreateWithdraw = ()=>{
    clearAllFields();
    setPhoneNum("");
    setManualWithdrawVisible(false);
}

const handleUnconfirmReprocess = async (record) => {
    
    setTableLoading(true);
    confirm({
        className: "confirmationModalStyle",
        title: "Confirmation",
        centered: true,
        icon: <QuestionCircleOutlined />,
        content: 'Are you sure you want to Re-Process this withdraw record?',
        onOk() {
            unconfirmTrxnReprocess(record);
            ManualWithdrawList();
        },
        onCancel() {          
        },
      });
    setTableLoading(false);
}

const handleUnconfirmClear = async (record) => {
    
    setTableLoading(true);
    confirm({
        className: "confirmationModalStyle",
        title: "Confirmation",
        centered: true,
        icon: <QuestionCircleOutlined />,
        content: 'Are you sure you want to Clear this withdraw record?',
        onOk() {
            unconfirmTrxnClear(record);
            ManualWithdrawList();
        },
        onCancel() {          
        },
      });
    setTableLoading(false);
}

const handleUnconfirmReject = async (record) => {
    
    setTableLoading(true);
    confirm({
        className: "confirmationModalStyle",
        title: "Confirmation",
        centered: true,
        icon: <QuestionCircleOutlined />,
        content: 'Are you sure you want to Reject this withdraw record?',
        onOk() {
            unconfirmTrxnReject(record);
            ManualWithdrawList();
        },
        onCancel() {          
        },
      });
    setTableLoading(false);
}

const handleAuto = async (record) => {
    setTableLoading(true);

    let requestData = {
        userId: record.userId, 
        witTrxnId: record.trxnId 
    }

    // const resWithdrawCheck =  await csWithdrawCheckV2(requestData);
    const resWithdrawCheck =  await csWithdrawCheckV3(requestData);
    let result = await resWithdrawCheck.json();

    if (result.status == "ok") {
        let currentBalance = 0, userTurnover = 0, lastDepDate = '', lastDepAmount = 0;
        currentBalance = result.data['usrBal'] > 0 ? Number(result.data['usrBal']).toFixed(2) : 0;
        userTurnover = result.data['usrTurnover'] > 0 ? Number(result.data['usrTurnover']).toFixed(2) : 0;
        lastDepDate = result.data['lastDepDate'] != '' ? result.data['lastDepDate'] : 'N/A';
        lastDepAmount = result.data['lastDepAmount'] > 0 ? Number(result.data['lastDepAmount']).toFixed(2) : 0;
        
        let msg = `Are you sure you want to Auto Transfer?`;
        let msg2 = `Current Balance`, msg3 = ` : `, msgCurrentBalance = currentBalance > 0 ? `${currentBalance}` : `0.00`;
        let msg4 = record.trxnMode == WALLET_TO_BANK ? `Withdraw Amount` : record.trxnMode == COMM_TO_BANK ? `Commission Amount` : `Withdraw Amount` , msg5 = ` : `, msgWithdrawAmt = `${record.amount}`;
        let msg6 = `User Turnover`, msg7 = ` : `, msgUserTurnover = userTurnover > 0 ? `${userTurnover}` : `N/A`;
        let msg8 = lastDepDate != 'N/A' ? `Last Deposit on ${lastDepDate}, Amount: ${lastDepAmount}` : 'Last Deposit : N/A';

        let hideBalance = record.trxnMode == COMM_TO_BANK ? true : false;

        confirm({
            className: 'confirmationModalStyle',
            title: 'Confirmation',
            width: '500px',
            centered: true,
            icon: <QuestionCircleOutlined />,
            content: 
                <Paragraph>{msg}
                    <ul>
                        {
                            hideBalance == true ? 
                                <li style={{display: "none"}}> 
                                    {msg2}<span>{msg3} {msgCurrentBalance}</span>
                                </li>
                            : 
                                <li> 
                                    {msg2}<span>{msg3} {msgCurrentBalance}</span>
                                </li>
                        }
                        <li>
                            {msg4} <span>{msg5} {msgWithdrawAmt}</span>
                        </li>
                        <li>
                            {msg6}<span>{msg7} {msgUserTurnover}</span>
                        </li>
                        {
                            msg8 != '' ? 
                                <li>
                                   {msg8}
                                </li>
                             : ''
                        }
                    </ul>
                </Paragraph>,
            onOk() {
                onSubmitAutoWithdraw(record);
            },
            onCancel() {
            },
        });
    }
    else {
        alertData.title = "Failed";
        alertData.msg = "An unexpected error occurred. Please refresh and try again.";
        sharedFailAlert(alertData);
    }

    setTableLoading(false);
}

const onSubmitAutoWithdraw = async (record) => {
    setTableLoading(true);

    let requestData = {
        trxnId : record.trxnId,
        csId : userId,
        csUsername: username,
        reqToken : md5(getReqToken('withdrawManualApprove')),
    };

    const resWithdrawAutoApprove = await csWithdrawApproveV4(requestData);
    let result = await resWithdrawAutoApprove.json();

    if (result.status == "ok") {
        // setAutoReset(autoReset+1);
        if (dataCount !== 1 && dataCount === ((currentPage - 1) * currentPageSize) + 1) {
            setCurrentPage(prevPage => Math.max(prevPage - 1, 1));
        } else {
            ManualWithdrawList();
        }
        alertData.title = "Success";
        alertData.msg = result.msg;
        sharedSuccessAlert(alertData);
        setTableLoading(false);
    }
    else {
        ManualWithdrawList();
        alertData.title = "Failed";
        alertData.msg = result.msg;
        sharedFailAlert(alertData);
        setTableLoading(false);
    }
}

const handleManual = async (record) => {
    setTableLoading(true);

    let requestData = {
        userId: record.userId, 
        witTrxnId: record.trxnId 
    }

    // const resWithdrawCheck = await csWithdrawCheckV2(requestData);
    const resWithdrawCheck = await csWithdrawCheckV3(requestData);
    let result = await resWithdrawCheck.json();
    
    if (result.status == "ok") {
        let currentBalance = 0, userTurnover = 0, lastDepDate = '', lastDepAmount = 0;
        currentBalance = result.data['usrBal'] > 0 ? Number(result.data['usrBal']).toFixed(2) : 0;
        userTurnover = result.data['usrTurnover'] > 0 ? Number(result.data['usrTurnover']).toFixed(2) : 0;
        lastDepDate = result.data['lastDepDate'] != '' ? result.data['lastDepDate'] : 'N/A';
        lastDepAmount = result.data['lastDepAmount'] > 0 ? Number(result.data['lastDepAmount']).toFixed(2) : 0;
        
        let msg = `Are you sure you want to Manual Transfer?`;
        let msg2 = `Current Balance`, msg3 = ` : `, msgCurrentBalance = currentBalance > 0 ? `${currentBalance}` : `0.00`;
        let msg4 = record.trxnMode == WALLET_TO_BANK ? `Withdraw Amount` : record.trxnMode == COMM_TO_BANK ? `Commission Amount` : `Withdraw Amount` , msg5 = ` : `, msgWithdrawAmt = `${record.amount}`;
        let msg6 = `User Turnover`, msg7 = ` : `, msgUserTurnover = userTurnover > 0 ? `${userTurnover}` : `N/A`;
        let msg8 = lastDepDate != 'N/A' ? `Last Deposit on ${lastDepDate}, Amount: ${lastDepAmount}` : 'Last Deposit : N/A';

        let hideBalance = record.trxnMode == COMM_TO_BANK ? true : false;

        confirm({
            className: "confirmationModalStyle",
            title: 'Confirmation',
            width: '500px',
            centered: true,
            icon: <QuestionCircleOutlined />,
            content: 
                <Paragraph>{msg}
                    <ul>
                        {
                            hideBalance == true ? 
                                <li style={{display: "none"}}> 
                                    {msg2}<span>{msg3} {msgCurrentBalance}</span>
                                </li>
                            : 
                                <li> 
                                    {msg2}<span>{msg3} {msgCurrentBalance}</span>
                                </li>
                        }
                        <li>
                            {msg4} <span>{msg5} {msgWithdrawAmt}</span>
                        </li>
                        <li>
                            {msg6}<span>{msg7} {msgUserTurnover}</span>
                        </li>
                        {
                            msg8 != '' ? 
                                <li>
                                   {msg8}
                                </li>
                             : ''
                        }
                    </ul>
                </Paragraph>,
            onOk() {
            onSubmitManualWithdraw(record);
            },
            onCancel() {
            },
        });
    }
    else {
        alertData.title = "Failed";
        alertData.msg = "An unexpected error occurred. Please refresh and try again.";
        sharedFailAlert(alertData);
    }

    setTableLoading(false);
}

const onSubmitManualWithdraw = async (record) => {
    setTableLoading(true);

    let requestData = {
        trxnId: record.trxnId,
        csId: userId,
        csUsername: username,
    };

    const resWithdrawManualApprove = await csWithdrawManualApproveV3(requestData);
    let result = await resWithdrawManualApprove.json();

    if (result.status == "ok") {
        // setManualReset(manualReset+1);
        if (dataCount !== 1 && dataCount === ((currentPage - 1) * currentPageSize) + 1) {
            setCurrentPage(prevPage => Math.max(prevPage - 1, 1));
        } else {
            ManualWithdrawList();
        }
        alertData.title = "Success";
        alertData.msg = result.msg;
        sharedSuccessAlert(alertData);
        setTableLoading(false);
    }
    else {
        ManualWithdrawList();
        alertData.title = "Failed";
        alertData.msg = result.msg;
        sharedFailAlert(alertData);
        setTableLoading(false);
    }
}

const handleReject = async () => {
    setRejectButtonLoading(true);

    if (!rejectReason) {
        setRejectButtonLoading(false);
        alertData.title = "Field Required";
        alertData.msg = "Please fill in Reject Withdraw Reason !";
        sharedErrorAlert(alertData);
    }

    let requestData = {
        trxnId: withdrawTrxnID,
        reason: rejectReason,
        // userId: withdrawMemberID,
        csUsername: username,
        csId: userId,
        reqToken   : md5(getReqToken('withdrawManualReject')),
    };

    const resWithdrawReject = await csWithdrawRejectV5(requestData);
    let result = await resWithdrawReject.json();

    if (result.status == "ok") {
        onCancelRejectWithdraw();
        if (dataCount !== 1 && dataCount === ((currentPage - 1) * currentPageSize) + 1) {
            setCurrentPage(prevPage => Math.max(prevPage - 1, 1));
        } else {
            ManualWithdrawList();
        }
        setRejectButtonLoading(false);
        alertData.title = "Success";
        alertData.msg = result.msg;
        sharedSuccessAlert(alertData);
    }
    else {
        onCancelRejectWithdraw();
        ManualWithdrawList();
        setRejectButtonLoading(false);
        alertData.title = "Error";
        alertData.msg = result.msg;
        sharedErrorAlert(alertData);
    }
}

const handleReload = async (record) => {
    const requestData0 = {
        BankType : 'Withdraw'
      }
  
    const resBankMach = await getBankDetailList(requestData0);
    const bankDetails = await resBankMach.json();
    const bankStatus = Object.values(bankDetails.data).filter((data) => {
        return data.acctnum === record.bankAcc;
    });

    if (bankStatus[0].status !== 0 && bankStatus[0].status !== 1) {
        alertData.title = "Failed";
        alertData.msg = "Unable to reload, Bank API is not enabled!";
        sharedFailAlert(alertData);
        setReloadPinLoading(false);
        return;
    }
    
    let requestData = {
        bankNumber: record.bankAcc,
        bank: record.accNo.split('(')[1].split(')')[0],
        authToken: record.authToken,
        csId: userId,
    };

    const resReloadBs = await reloadBankService(requestData);
    let result = await resReloadBs.json();

    if (result.status == "ok") {
        setReloadPinLoading(false);
        ManualWithdrawList();
        getCompanyWithdrawBankList();
        alertData.title = "Success";
        alertData.msg = result.msg;
        sharedSuccessAlert(alertData);
    }
    else {
        setReloadPinLoading(false);
        alertData.title = "Failed";
        alertData.msg = result.msg;
        sharedFailAlert(alertData);
    }
}

const onCancelRejectWithdraw = () => {
    setRejectReason("");
    setWithdrawType("");
    // setRejectReset(rejectReset+1);
    setRejectWithdrawVisible(false);
};

const handleReprocess = (record) => {
    setTableLoading(true);
    confirm({
        className: "confirmationModalStyle",
        title: "Confirmation",
        centered: true,
        icon: <QuestionCircleOutlined />,
        content: 'Are you sure you want to Re-Process this withdraw record?',
        onOk() {
            onReprocessUnconfirmWithdraw(record);
        },
        onCancel() {          
        },
      });
    setTableLoading(false);
}

const onReprocessUnconfirmWithdraw = async (record) => {
    setTableLoading(true);

    let requestData = {
        jobId: record.trxnId,
        type: record.checkStatus == 'Unconfirm' ? '1' : '2',
        jobStatus: 1,
        csUserId: userId,
        trxnMode: record.trxnMode,
        amt: record.amount
    };

    const resReprocessWithdraw = await reprocessWithdrawV2(requestData);
    let result = await resReprocessWithdraw.json();

    if (result.status == "ok") {
        if (dataCount !== 1 && dataCount === ((currentPage - 1) * currentPageSize) + 1) {
            setCurrentPage(prevPage => Math.max(prevPage - 1, 1));
        } else {
            ManualWithdrawList();
        }
        setTableLoading(false);
        alertData.title = "Success";
        alertData.msg = result.msg;
        sharedSuccessAlert(alertData);
    }
    else {
        ManualWithdrawList();
        setTableLoading(false);
        alertData.title = "Failed";
        alertData.msg = result.msg;
        sharedFailAlert(alertData);
    }
}

const unconfirmTrxnReprocess = async (record) => {
    setTableLoading(true);

    let requestData = {
        TransactionID: record.transactionID,
        UpdateBy: username
    };
    
    const resReprocessUnconfirmTrxn = await reprocessUnconfirmTrxn(requestData);
    let result = await resReprocessUnconfirmTrxn.json();

    if (result.status == "ok") {
        if (dataCount !== 1 && dataCount === ((currentPage - 1) * currentPageSize) + 1) {
            setCurrentPage(prevPage => Math.max(prevPage - 1, 1));
        } else {
            ManualWithdrawList();
        }
        setTableLoading(false);
        alertData.title = "Success";
        alertData.msg = result.msg;
        sharedSuccessAlert(alertData);
    }
    else {
        ManualWithdrawList();
        setTableLoading(false);
        alertData.title = "Failed";
        alertData.msg = result.msg;
        sharedFailAlert(alertData);
    }
}

const unconfirmTrxnClear = async (record) => {
    setTableLoading(true);

    let requestData = {
        TransactionID: record.transactionID,
        UpdateBy: username
    };
    
    const resClearUnconfirmTrxn = await clearUnconfirmTrxn(requestData);
    let result = await resClearUnconfirmTrxn.json();

    if (result.status == "ok") {
        if (dataCount !== 1 && dataCount === ((currentPage - 1) * currentPageSize) + 1) {
            setCurrentPage(prevPage => Math.max(prevPage - 1, 1));
        } else {
            ManualWithdrawList();
        }
        setTableLoading(false);
        alertData.title = "Success";
        alertData.msg = result.msg;
        sharedSuccessAlert(alertData);
    }
    else {
        ManualWithdrawList();
        setTableLoading(false);
        alertData.title = "Failed";
        alertData.msg = result.msg;
        sharedFailAlert(alertData);
    }
}

const unconfirmTrxnReject = async (record) => {
    setTableLoading(true);

    let requestData = {
        TransactionID: record.transactionID,
        UpdateBy: username
    };
    
    const resRejectUnconfirmTrxn = await rejectUnconfirmTrxn(requestData);
    let result = await resRejectUnconfirmTrxn.json();

    if (result.status == "ok") {
        if (dataCount !== 1 && dataCount === ((currentPage - 1) * currentPageSize) + 1) {
            setCurrentPage(prevPage => Math.max(prevPage - 1, 1));
        } else {
            ManualWithdrawList();
        }
        setTableLoading(false);
        alertData.title = "Success";
        alertData.msg = result.msg;
        sharedSuccessAlert(alertData);
    }
    else {
        ManualWithdrawList();
        setTableLoading(false);
        alertData.title = "Failed";
        alertData.msg = result.msg;
        sharedFailAlert(alertData);
    }
}

const getBankForDeposit = async() => {
    const response = await getLowestBalanceOnyx();
    const depBank = await response.json();
    if(depBank.status === 'err'){
        setModifyAccBalanceToggle(false);
        alertData.title = "Failed";
        alertData.msg = "Failed to get withdraw bank";
        sharedFailAlert(alertData);
    }else{
        setModifyAccBalanceToggle(true);
        setLowestBalanceAccNo(depBank.data.accountNumber);
        setLowestBalanceBankCode(depBank.data.bankCode);
    }
}
const selectCountryCode = (value) => {
    setCountryCode(value);
};

const handleToggle = async (data)=> {
    setToggleLoading(true);
    setTableLoading(true);
    let mode = '';

    mode = toggleCheck == true ? "OFF" : "ON";
    setToggleCheck( toggleCheck == true ? false : true);

    let requestData = {
        bank_api_mode : mode,
        companyId : companyId
    }
    
    switchWithdrawMode(requestData)
        .then((response) => response.json())
        .then((json) => {
            if (json.status === "ok") {
                setToggleLoading(false);
                setTableLoading(false);
                alertData.title = "Success";
                alertData.msg = json.msg;
                sharedSuccessAlert(alertData);
              }
              else {
                setToggleCheck(toggleCheck);
                setToggleLoading(false);
                setTableLoading(false);
                alertData.title = "Error";
                alertData.msg = json.msg;
                sharedErrorAlert(alertData);
              }
          })
            .catch((error) => {
                console.log("error: ", error);
                setToggleCheck(toggleCheck);
                setToggleLoading(false);
                setTableLoading(false);
                alertData.title = "Error";
                alertData.msg = "An unexpected error occurred. Please contact your system administrator.";
                sharedErrorAlert(alertData);
            });
  }

const styles = {
    pageHearderContainer: {
        justifyContent: isMobile ? "" : "space-between",
        display: "flex",
        flexDirection: isMobile ? "column" : "",
        alignItems: isMobile ? "center" : "",
        width: "100%"
    },
    refreshBtn:      { marginLeft: 10 },
    tableContainer:  { backgroundColor: "#FFFFFF", padding: 24 },
    paginationStyle: { marginTop: 50 },
    memberTrxnStyle: { marginLeft:"280px"},
    actionCompleteBtn: {
        marginRight: 20,
        marginLeft: 20,
        backgroundColor: "#4190f7",
        borderColor: "#4190f7",
    },
    actionBottomBtn: {
        flexDirection: "row",
        display: "flex",
        textAlign: "center",
    },
    statusApproveBtn: {
        marginRight: 20,
        marginLeft: 20,
        backgroundColor: "#4190f7",
        borderColor: "#4190f7",
    },
    statusBottomBtn: {
        flexDirection: "row",
        display: "flex",
        textAlign: "center",
    },
    hide : {
        display:"none",
    },
    alertText : {
        color: "Red",
    },
    test_class : {
        backgroundColor: 'Red'
    }
};

const layout = {
    labelCol: {
        span: 10,
    },
    wrapperCol: {
        span: 160,
    },
};
const handleSearch = (selectedKeys, confirm) => {
    confirm();
    setSearchText(selectedKeys[0]);
  };
  
const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
};

const ModifyAccBalanceForm = ({...props}) => {
    const [amount, setAmount] = useState(0);
    const onChange = (value) => {
        setAmount(value);
    };
    const updateWithdrawBalance = async() => {
        const data = {bankCode: lowestBalanceBankCode,accountNo: lowestBalanceAccNo,topupAmount: amount, cs: userId};
        const response = await updateWithdrawBalanceOnyx(data);
        const updateStatus = await response.json();
        if(updateStatus.status === 'ok'){
            alertData.title = "Success";
            alertData.msg = updateStatus.msg;
            sharedSuccessAlert(alertData);
            setModifyAccBalanceToggle(false);
            accBalanceForm.resetFields();
            setAmount(0);
            await getCompanyWithdrawBankList();
        }else{
            alertData.title = "Failed";
            alertData.msg = 'Failed to update balance';
            sharedErrorAlert(alertData);
            accBalanceForm.resetFields();
            setAmount(0);
            setModifyAccBalanceToggle(false);
        }
    }
    return (
        <Form form={accBalanceForm} {...props} layout="vertical" onFinish={updateWithdrawBalance}>
            <div style={{display:'flex', flexDirection:'column', alignItems:'center', marginTop:25, width:'100%'}}>
                <div style={{ width:'50%', display:'flex', flexDirection:'column'}}>
                {/* <div style={{ display:'flex', flexDirection: 'column', marginTop:20}}>
                <div>
                <label style={{fontWeight:'bold'}}>Account Number: </label>
                </div>
                <div>
                <label>{selectedAcc}</label>
                </div>
                </div>
                <div style={{ display:'flex', flexDirection: 'column', marginTop:25}}>
                <div>
                <label style={{fontWeight:'bold'}}>Bank Code: </label>
                </div>
                <div>
                <label>{selectedAccBankCode}</label>
                </div>
                </div> */}
                <div style={{ display:'flex', flexDirection: 'column', marginTop:20}}>
                <div>
                <label style={{fontWeight:'bold'}}>Account Number: </label>
                </div>
                <div>
                <label>{lowestBalanceAccNo}</label>
                </div>
                </div>
                <div style={{ display:'flex', flexDirection: 'column', marginTop:25}}>
                <div>
                <label style={{fontWeight:'bold'}}>Bank Code: </label>
                </div>
                <div>
                <label>{lowestBalanceBankCode}</label>
                </div>
                </div>
                <Form.Item
                style={{marginTop:25, fontWeight: 'bold'}}
                name="Deposit Amount"
                label="Deposit Amount:"
                rules={[
                    {
                    required: true,
                    },
                ]}
                >
                    <InputNumber style={{ width:'100%'}} onChange={onChange} value={amount}></InputNumber>
                </Form.Item>
                <div style={{ display: 'flex', justifyContent:'center', marginTop: 25}}>
                    <Button onClick={()=>{accBalanceForm.resetFields();setModifyAccBalanceToggle(false)}}>
                    Cancel
                    </Button>
                    <Button style={{marginLeft:20}} type="primary" htmlType="submit">
                    Submit
                    </Button>
                    
                </div>
                </div>
            </div>
        </Form>
    )
}

const cardList = () => {
    let convertRecord = Object.values(withdrawBankList);
    let sortData = [...convertRecord].sort((a, b) => a.accEnable > b.accEnable ? -1 : 1);
    if (withdrawBankList.length > 0 && sortData[0].accNo != 'undefined (undefined)') {
        const listItems = sortData.map((item,index) =>
            <Col key={index} span={10} style={{ width: "100%", paddingLeft: "8px", paddingRight: "8px" }}>
                <Card
                    key={index}
                    style={{
                        backgroundColor: "#e2f2f0",
                        borderRadius: 10,
                        marginTop: "3%",
                        minHeight: 300,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        margin: 5,
                    }}
                >
                    {bankListLoading ? (
                  <Spin 
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyItems: "center",
                      alignItems: "center",
                    }}
                    size="large"
                  ></Spin>
                  ) : (
                        <Col>
                            {   
                                item.accEnable == true ?
                                    <div style={{ height: "25%", fontSize: "24px" }}>
                                        <span style={{ color: "green" }}>
                                            <CheckCircleOutlined /> Active
                                        </span>
                                        { witMgmtManApprRej(role) ?
                                            <span style={{ position: "absolute", top: "0px", right: "0px" }}>
                                                {item.transactionMethod === 'onyx' ?
                                                 <Button
                                                    style={{
                                                        backgroundColor: "initial",
                                                        color: "black",
                                                        borderColor: "black",
                                                    }}
                                                    type="default"
                                                    shape="round"
                                                    // loading={reloadPinLoading}
                                                    onClick={(e) => {
                                                        // setModifyAccBalanceToggle(true)
                                                        setSelectedAcc(item.acctNo);
                                                        setSelectedAccBankCode(item.bankCode);
                                                        getBankForDeposit();
                                                        
                                                    }}
                                                >
                                                    Deposit
                                                </Button>:
                                                <Button
                                                    style={{
                                                        backgroundColor: "initial",
                                                        color: "black",
                                                        borderColor: "black",
                                                        marginLeft: 10
                                                    }}
                                                    type="default"
                                                    shape="round"
                                                    icon={<ReloadOutlined/>}
                                                    loading={reloadPinLoading}
                                                    onClick={(e) => {
                                                        handleReload(item);
                                                        setReloadPinLoading(true);
                                                    }}
                                                >
                                                    Retry
                                                </Button>}
                                            </span>
                                        :
                                            <></>
                                        }
                                    </div>
                                :
                                    <div style={{ height: "25%", fontSize: "24px" }}>
                                        <span style={{ color: "red" }}>
                                            <StopOutlined /> Inactive
                                        </span>
                                        { witMgmtManApprRej(role) ?
                                            <span style={{ position: "absolute", top: "0px", right: "0px" }}>
                                                 {item.transactionMethod === 'onyx'?
                                                 <Button
                                                    style={{
                                                        backgroundColor: "initial",
                                                        color: "black",
                                                        borderColor: "black",

                                                    }}
                                                    type="default"
                                                    shape="round"
                                                    // loading={reloadPinLoading}
                                                    onClick={(e) => {
                                                        // setModifyAccBalanceToggle(true)
                                                        setSelectedAcc(item.acctNo);
                                                        setSelectedAccBankCode(item.bankCode);
                                                        getBankForDeposit();

                                                    }}
                                                >
                                                    Deposit
                                                </Button>:
                                                <Button
                                                    style={{
                                                        backgroundColor: "initial",
                                                        color: "black",
                                                        borderColor: "black",
                                                        marginLeft: 10
                                                    }}
                                                    type="default"
                                                    shape="round"
                                                    icon={<ReloadOutlined/>}
                                                    loading={reloadPinLoading}
                                                    onClick={(e) => {
                                                        handleReload(item);
                                                        setReloadPinLoading(true);
                                                    }}
                                                >
                                                    Retry
                                                </Button>}
                                            </span>
                                        :
                                            <></>
                                        }
                                    </div>
                            }

                            <div style={{ height: "25%", marginTop: "2%" }}>
                                <span style={{ color: "rgba(0, 0, 0, 0.45)", fontSize: "14px" }}>
                                    Account Number
                                </span> 
                                <br/>
                                <span style={{ fontSize: "24px" }}>
                                    {item.accNo}
                                </span>
                            </div>

                            <div style={{ height: "25%", marginTop: "2%" }}>
                                <span style={{ color: "rgba(0, 0, 0, 0.45)", fontSize: "14px" }}>
                                    Account Name
                                </span>
                                <br/>
                                <span style={{ fontSize: "24px" }}>
                                    {item.accName}
                                </span>
                            </div>

                            <div style={{ marginTop: "2%" }}>
                            {item.transactionMethod !== 'onyx'?<div style={{ width: "37%", display: "inline-block" }}>
                                    <Statistic className="withdrawPageBankList_title" title="Account Balance (Baht)" value={item.accBal} precision={2} style={{ }} />
                                </div>:''}
                                {item.transactionMethod !== 'onyx'?
                                <div style={{ marginRight:"10px", width: "33%", display: "inline-block" }}>
                                    <Statistic className="withdrawPageBankList_title" title="Withdrawable Funds" value={item.accWithdrawableFund} precision={2} style={{ }} />
                                </div>:''}
                                {item.transactionMethod !== 'onyx'?
                                <div style={{ width: "27%", display: "inline-block" }}>
                                    <Statistic className="withdrawPageBankList_title" title="Withdraw Counts" value={item.accWithdrawCount}  style={{ }} />
                                </div>:''}
                                {item.transactionMethod === 'onyx'?<div>
                                    {/* <span style={{color:'#919c9a'}}>Lifetime Statistics</span>
                                    <Divider style={{margin:5}}></Divider>
                                    <div style={{width:'100%'}}>
                                        <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between'}}>
                                            <div style={{margin:10,minWidth:'20%'}}>
                                                <div>
                                                    <span style={{fontSize:14}}>Account Balance</span>
                                                </div>
                                                <div>
                                                    <span style={{fontWeight:'bold', fontSize:18 ,wordWrap:'break-word', wordBreak:'break-all'}}>{!item?.accBal || item?.accBal !== 'N/A'?item.accBal:'0.00'}</span>
                                                </div>
                                            </div>
                                            <div style={{display:'flex', alignItems:'center', margin:10}}>=</div>
                                            <div style={{margin:10, minWidth:'20%'}}>
                                                <div>
                                                    <span style={{fontSize:14}}>Total Deposit</span>
                                                </div>
                                                <div>
                                                    <span style={{fontWeight:'bold', fontSize:18 ,wordWrap:'break-word', wordBreak:'break-all'}}>{!item?.totalDeposit || item?.totalDeposit !== 'N/A'? item.totalDeposit:'0.00'}</span>
                                                </div>
                                            </div>
                                            <div style={{display:'flex', alignItems:'center', margin:10}}><span style={{fontSize:18}}>-</span></div>
                                            <div style={{margin:10, minWidth:'20%'}}>
                                                <div>
                                                    <span style={{fontSize:14}}>Total Withdraw</span>
                                                </div>
                                                <div>
                                                    <span style={{fontWeight:'bold', fontSize:18 ,wordWrap:'break-word', wordBreak:'break-all'}}>{!item?.totalWithdraw || item?.totalWithdraw !== 'N/A' ? item.totalWithdraw:'0.00'}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                <span style={{color:'#919c9a'}}>{item.currentMonth} Statistics</span>
                                    <Divider style={{margin:5}}></Divider>
                                    <div style={{width:'100%'}}>
                                        <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between'}}>
                                            <div style={{margin:10,minWidth:'20%'}}>
                                                <div>
                                                    <span style={{fontSize:14}}>Account Balance</span>
                                                </div>
                                                <div>
                                                    <span style={{fontWeight:'bold', fontSize:18 ,wordWrap:'break-word', wordBreak:'break-all'}}>{!item?.monthAccBal || item?.monthAccBal !== 'N/A'?item.monthAccBal:'0.00'}</span>
                                                </div>
                                            </div>
                                            <div style={{display:'flex', alignItems:'center', margin:10}}>=</div>
                                            <div style={{margin:10, minWidth:'20%'}}>
                                                <div>
                                                    <span style={{fontSize:14}}>Total Deposit</span>
                                                </div>
                                                <div>
                                                    <span style={{fontWeight:'bold', fontSize:18 ,wordWrap:'break-word', wordBreak:'break-all'}}>{!item?.monthTotalDeposit || item?.monthTotalDeposit !== 'N/A'?item.monthTotalDeposit:'0.00'}</span>
                                                </div>
                                            </div>
                                            <div style={{display:'flex', alignItems:'center', margin:10}}><span style={{fontSize:18}}>-</span></div>
                                            <div style={{margin:10, minWidth:'20%'}}>
                                                <div>
                                                    <span style={{fontSize:14}}>Total Withdraw</span>
                                                </div>
                                                <div>
                                                    <span style={{fontWeight:'bold', fontSize:18 ,wordWrap:'break-word', wordBreak:'break-all'}}>{!item?.monthTotalWithdraw || item?.monthTotalWithdraw !== 'N/A' ? item.monthTotalWithdraw:'0.00'}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                    <span style={{color:'#919c9a'}}>{item.currentMonth} Statistics   (Last Updated: {moment(Number(item.lastUpdatedTime) * 1000).utcOffset(420).format('YYYY-MM-DD HH:mm:ss')}) </span>
                                    <Divider style={{margin:5}}></Divider>
                                    <div style={{width:'100%'}}>
                                        <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between'}}>
                                            <div style={{margin:10,minWidth:'20%'}}>
                                                <div>
                                                    <span style={{fontSize:14}}>Account Balance</span>
                                                </div>
                                                <div>
                                                    <span style={{fontWeight:'bold', fontSize:18 ,wordWrap:'break-word', wordBreak:'break-all'}}>{!item?.monthAccBal || item?.monthAccBal !== 'N/A'?item.monthAccBal:'0.00'}</span>
                                                </div>
                                            </div>
                                            <div style={{display:'flex', alignItems:'center', margin:10}}>=</div>
                                            <div style={{margin:10, minWidth:'20%'}}>
                                                <div>
                                                    <span style={{fontSize:14}}>Total Deposit</span>
                                                </div>
                                                <div>
                                                    <span style={{fontWeight:'bold', fontSize:18 ,wordWrap:'break-word', wordBreak:'break-all'}}>{!item?.monthTotalDeposit || item?.monthTotalDeposit !== 'N/A'?item.monthTotalDeposit:'0.00'}</span>
                                                </div>
                                            </div>
                                            <div style={{display:'flex', alignItems:'center', margin:10}}><span style={{fontSize:18}}>-</span></div>
                                            <div style={{margin:10, minWidth:'20%'}}>
                                                <div>
                                                    <span style={{fontSize:14}}>Total Withdraw</span>
                                                </div>
                                                <div>
                                                    <span style={{fontWeight:'bold', fontSize:18 ,wordWrap:'break-word', wordBreak:'break-all'}}>{!item?.monthTotalWithdraw || item?.monthTotalWithdraw !== 'N/A' ? item.monthTotalWithdraw:'0.00'}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                :''}
                                </div>
                        </Col>
                    )}
                </Card>
            </Col>
        )

        return listItems
    }
};

  
let searchInput = null;
  
const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
    }) => (
        <div style={{ padding: 8 }}>
        <Input
            ref={(node) => {
            searchInput = node;
            }}
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90, marginRight: 8 }}
        >
            Search
        </Button>
        <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
        >
            Reset
        </Button>
        </div>
    ),
    filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
        record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : false,
    onFilterDropdownVisibleChange: (visible) => {
        if (visible) setTimeout(() => searchInput.select());
    },
    render: (text) =>
        text ? (
        <Highlighter
            highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text.toString()}
        />
        ) : (
        false
        ),
});

const withdrawColumns = [
    {
        title: status == "Processing" ? "Process Time" : "Date / Time",
        dataIndex: "date",
        width: "200px",
        align: "center",
        ellipsis: true,
    },
    {
        title: "Member Mobile",
        dataIndex: "newNickname",
        ...getColumnSearchProps("newNickname"),
        width: "200px",
        align: "center",
        ellipsis: true,
    },
    {
        title: "Amount",
        dataIndex: "amount",
        sorter: (a, b) => a.amount - b.amount,
        ...getColumnSearchProps("amount"),
        width: "150px",
        align: "center",
        ellipsis: true,
    },
    {
        title: "Member Bank Account",
        dataIndex: "bankAccount",
        ...getColumnSearchProps("bankAccount"),
        width: "200px",
        align: "center",
        ellipsis: true,
    },
    {
        title: "Trxn Type",
        dataIndex: "trxnMode",
        width: "200px",
        align: "center",
        ellipsis: true,
        filters: [
            {
                text: 'wallet2bank',
                value: 'wallet2bank',
            },

            {
                text: 'comm2bank',
                value: 'comm2bank',
            },
        ],   
        filterMultiple: false,
        onFilter: (value, record) => record.trxnMode.indexOf(value) === 0,
    },

    {
        title: "Transfer From",
        dataIndex: "transferFrom",
        width: "200px",
        align: "center",
        ellipsis: true,
        hidden: status == "Completed" || status == "Approved" || status == "Processing" ? false : true,
        filters: withdrawBankListFilter,      
        filterMultiple: false,
        onFilter: (value, record) => record.transferFrom.indexOf(value) === 5,
    },

    // {
    //     title: "Bank Balance",
    //     dataIndex: "transferFromBankBalance",
    //     width: "200px",
    //     align: "center",
    //     ellipsis: true,
    //     hidden: status == "Completed" || status == "Approved" ? false : true,
    // },
  
    // {
    //     ellipsis: true,
    //     title: "Bank Name (Out)",
    //     dataIndex: "bankNameOut",
    //     width: "200px",
    //     align: "center",
    //     ...getColumnSearchProps("bankNameOut"),
    // },
    // {
    //     ellipsis: true,
    //     title: "Bank Account (Out)",
    //     dataIndex: "bankAcctOut",
    //     width: "200px",
    //     align: "center",
    // },
    {
        ellipsis: true,
        title: ((status == "Pending" || status == "Unconfirm") && role == 'CS_Super' || role == 'CS_Senior') ? "Action" : "Status",
        dataIndex: "status",
        width: "370px",
        align: "center",
        render: (text, record) => {
        switch (record.checkStatus) {
            case "Pending":
                return (
                    <>  
                        {/* <Badge status="warning" text="Pending" />
                        <div 
                            style={{
                                marginTop: '10px',
                                width: '100%', 
                                height:'50px',
                                align: 'center'
                            }}
                        >
                            <SwipeButton
                                mainText="Auto" 
                                overlayText="Processing..."
                                classList={"swipezor-container1"}
                                overlayClassList={"swipezor-overlay"}
                                onSwipeDone={function () {
                                    handleAuto(record);
                                }} 
                                reset={autoReset}
                            />
                        </div>
                        
                        <div style={{width:'100%', height:'50px'}}>
                            <SwipeButton 
                                mainText="Manual" 
                                overlayText="Processing..."
                                classList={"swipezor-container2"}
                                overlayClassList={"swipezor-overlay"}
                                onSwipeDone={function () {
                                    handleManual(record);
                                }} 
                                reset={manualReset}
                            />
                        </div>

                        <div style={{width:'100%', height:'50px'}}>
                            <SwipeButton 
                                mainText="Reject" 
                                overlayText="Processing..."
                                classList={"swipezor-container3"}
                                overlayClassList={"swipezor-overlay"}
                                onSwipeDone={function () {
                                    setWithdrawTrxnID(record.trxnId);
                                    setWithdrawMemberID(record.userId);
                                    setRejectWithdrawVisible(true);
                                }} 
                                reset={rejectReset}
                            />
                        </div>

                        <Button onClick={() => {
                            setReset(counter => counter + 1)
                        }}
                        >
                            Reset
                            </Button> */}
                            
                        {  witMgmtManApprRej(role) ?
                            <div>
                                <span>
                                    <Button
                                        style={{ backgroundColor: "green", border: "none", color: "white" }}
                                        type="primary"
                                        onClick={(event) => {
                                            handleAuto(record, event.target);
                                        }}
                                    >
                                        Auto
                                    </Button>
                                </span>

                                <span> &nbsp;&nbsp;
                                    <Button
                                        style={{ backgroundColor: "#F98401", border: "none", color: "white"}}
                                        type="primary"
                                        onClick={(event) => {
                                            handleManual(record, event.target);
                                        }}
                                    >
                                        Manual
                                    </Button>
                                </span>

                                <span> &nbsp;&nbsp;
                                    <Button
                                        style={{ border: "none", color: "white" }}
                                        danger
                                        type="primary"
                                        onClick={(event) => {
                                            setWithdrawTrxnID(record.trxnId);
                                            setWithdrawMemberID(record.userId);
                                            setWithdrawType(record.trxnMode);
                                            setRejectWithdrawVisible(true);
                                        }}
                                    >
                                        Reject
                                    </Button>
                                </span>
                            </div>
                            : 
                            ( 
                                record.remark == "processing" 
                                ? <Badge status="warning" text="Processing"/>
                                : <Badge status="warning" text="Pending"/>
                            )
                        }
                    </>
                );

            case "Rejected":
                return (
                    <>
                        <Badge status="error" text="Rejected" />
                    </>
                );

            case "Approved":
                return (
                    <>
                        {  witMgmtManApprRej(role) && record.remark != "processing" ?
                            <div>
                                <span>
                                    <Button
                                        style={{ border: "none", color: "white"}}
                                        type="danger"
                                        onClick={(event) => {
                                            handleReprocess(record, event.target);
                                        }}
                                    >
                                        Re-process
                                    </Button>
                                </span>

                                <span> &nbsp;&nbsp;
                                    <Button
                                        style={{ backgroundColor: "#F98401", border: "none", color: "white"}}
                                        type="primary"
                                        onClick={(event) => {
                                            handleManual(record, event.target);
                                        }}
                                    >
                                        Manual
                                    </Button>
                                </span>
                            </div>
                            : 
                            ( 
                                record.remark == "processing" 
                                ? <Badge status="warning" text="Processing"/>
                                : <Badge status="warning" text="Pending"/>
                            )
                        }
                    </>
                );

            case "Processing":
                return (
                    <>              
                        <Badge
                            status="processing"
                            text={
                            record.processBy !== username
                                ? `Processing by ${record.processBy}`
                                : "Processing"
                            }
                        />
                    </>
                );

                case "On-hold":
                    return (
                        <>
                            {  witMgmtManApprRej(role) && record.remark != "processing" ?
                                <div>
                                    <span>
                                        <Button
                                            style={{ border: "none", color: "white"}}
                                            type="danger"
                                            onClick={(event) => {
                                                handleReprocess(record, event.target);
                                            }}
                                        >
                                            Re-process
                                        </Button>
                                    </span>

                                    <span> &nbsp;&nbsp;
                                    <Button
                                        style={{ backgroundColor: "#F98401", border: "none", color: "white"}}
                                        type="primary"
                                        onClick={(event) => {
                                            handleManual(record, event.target);
                                        }}
                                    >
                                        Manual
                                    </Button>
                                </span>
                                </div>
                                : 
                                ( 
                                    record.remark == "processing" 
                                    ? <Badge status="warning" text="Processing"/>
                                    : <Badge status="warning" text="Pending"/>
                                )
                            }
                        </>
                    );

            case "Completed":
                return (
                    <>
                        <Badge status="success" text="Completed" />
                    </>
                );

            case "Unconfirm":
                return (
                    <>     
                        { witMgmtManApprRej(role) ?
                            <div>
                                <span>
                                    <Button
                                        style={{ backgroundColor: "green", border: "none", color: "white" }}
                                        type="primary"
                                        onClick={(event) => {
                                            handleUnconfirmReprocess(record, event.target);
                                        }}
                                    >
                                        Re-Process
                                    </Button>
                                </span>
                                <span> &nbsp;&nbsp;
                                    <Button
                                        style={{ backgroundColor: "#0040FF", border: "none", color: "white"}}
                                        type="primary"
                                        onClick={(event) => {
                                            handleUnconfirmClear(record, event.target);
                                        }}
                                    >
                                        Clear
                                    </Button>
                                </span>
                                <span> &nbsp;&nbsp;
                                    <Button
                                        style={{ border: "none", color: "white" }}
                                        danger
                                        type="primary"
                                        onClick={(event) => {
                                            handleUnconfirmReject(record, event.target);
                                        }}
                                    >
                                        Reject
                                    </Button>
                                </span>
                            </div>
                            : 
                            ( 
                                <Badge status="warning" text="Unconfirm"/>
                            )
                        }
                    </>
                );

            default:
                return (
                    <>
                        <Badge status="default" text="ERROR" />
                    </>
                );
        }
        },
        hidden: status == "Processing" ? true : false,
    },
    {
        ellipsis: true,
        title: "Process Type",
        dataIndex: "processType",
        width: "150px",
        align: "center",
        render: (text, record) => {
            switch (record.processType) {
                case "M":
                    return <>Manual</>;

                case "A":
                    return <>Auto</>;

                default:
                    return <></>;
            }
        },
        hidden: status == "Pending" || status == "Unconfirm" || status == "Processing" ? true : false,
    },
    {
        ellipsis: true,
        title: "Created By",
        dataIndex: "createdBy",
        ...getColumnSearchProps("createdBy"),
        width: "150px",
        align: "center",
        render: (text, record) => {
            // STAGING 6e204578-6b65-546f-7069-726564000000
            // TKB 6e204578-6b65-546f-7069-726564000000
            // TKB555 6e204578-6b65-546f-7069-726564000000
            // TKB 777
            if (text != null) {
                return <>{text}</>;
            }
            else{
                return <>System</>;
            }
        },
        hidden: status == "Processing" ? true : false,
    },
    {
        ellipsis: true,
        title: "Process By",
        dataIndex: "processByCS",
        width: "150px",
        align: "center",
        render: (text, record) => {
            if(status === 'Processing'){
                if(record.processByCS === 'SYSTEM-AUTO-WIT'){
                    return <>System</>
                }else{
                    return <>{text}</>
                }
            }
            else{
                switch (record.processType) {
                    case "M":
                        if (record.checkStatus == "Completed" || record.remark == "processing") {
                            return <>{text}</>;
                        }
                        else {
                            return <></>;
                        }

                    case "A":
                        return <>System</>;

                    default:
                        return <></>;
                }
            }
        },
        hidden: status == "Pending" || status == "Rejected" || status == "Unconfirm" ? true : false,
    },
    {
        ellipsis: true,
        title: "Reject By",
        dataIndex: "completeByCS",
        width: "150px",
        align: "center",
        render: (text, record) => {
            switch (record.processType) {
                case "M":
                    if (record.checkStatus == "Rejected") {
                        return <>{text}</>;
                    }
                    else {
                        return <></>;
                    }

                case "A":
                    return <>System</>;

                default:
                    return <></>;
            }
        },
        hidden: status != "Rejected" ? true : false,
    },
    {
        title: status == "Rejected" ? "Reject Reason" : "Remark",
        dataIndex: "remark",
        width: "200px",
        // align: "center",
        style: [{"word-wrap":"break-word"}],
        render: (text, record) => {
            switch (record.checkStatus) {
                case "Rejected":
                    return <>{text}</>;
                    
                case "Completed":
                    if (text != ''){
                        return <>{record.puppetRemark}</>
                    }
                    else {
                        return "";
                    }

                case "Approved":
                    if (text != ''){
                        return <>{text}</>
                    }
                    else {
                        return "";
                    }

                default:
                    return <></>;
            }
        },
        hidden: status == "Pending" || status == "Unconfirm" || status == "Processing" ? true : false,
    },
    {
        ellipsis: true,
        title: "Screenshot",
        dataIndex: "screenshot",
        width: "200px",
        align: "center",
        render: (text, record) => {
            return(
                <div>
                    <Button
                        style={{backgroundColor: "#0040FF", border: "none", color: "white"}}
                        onClick={() => {
                            handleViewScreenshot(record);
                        }}>
                        View Screenshot
                    </Button>
                </div>
            )
        },
        hidden: status == "Pending" || status == "Processing" ? true : false,
    }
].filter(item => !item.hidden);

const handlePageAndSizeChange = (page, pageSize) => {
    setCurrentPage(page);
    setCurrentPageSize(pageSize);
};

const download = async () => {
    const response = await fetch(screenshotRecord); // Fetch the image data
    const blob = await response.blob();
    var element = document.createElement("a");
    var file = new Blob([screenshotRecord], { type: "image/*" });
    element.href = URL.createObjectURL(blob);
    element.download = unconfirmScreenshotName+'.jpg';
    element.click();
};  

const getDataRecord = async (targetID) => {
    return data.find(dict => dict.transactionID === targetID) || null;
}

const handleRetryClick = async (transactionID) => {
    const record = await getDataRecord(transactionID)
    handleViewScreenshot(record);
}

return (
    <div className="manualWithdrawV5">
        <PageHeader ghost={false}>
            <div style={styles.pageHearderContainer}>
                <div style={{width:"60%"}}>
                    <div style={{float:"left", marginRight:"0.5%"}}>
                        <Button
                            style={styles.refreshBtn}
                            type="primary"
                            onClick={() => {
                                setTableLoading(true);
                                ManualWithdrawList();
                                getCompanyWithdrawBankList();
                            }}
                        >
                            Refresh Page
                        </Button>
                    </div>
                    
                    {/* {  witMgmtManCrWit(role) ?
                        <div style={{float:"left", marginRight:"1%"}}>
                            <Button
                                style={styles.refreshBtn}
                                type="primary"
                                onClick={() => {
                                    setManualWithdrawVisible(true);
                                }}
                            >
                                Create Withdrawal
                            </Button>
                        </div>
                        : <></>
                    } */}
                    
                    {/* {
                        !(switchWithdrawModeVw(role))?
                        <></>
                        :
                        <div>
                            <div style={{float:"left", marginTop:"0.5%"}}>
                                <span><b>SCB API Switch: </b></span> 
                            </div>
                            <div style={{float:"left", marginTop:"0.5%"}}>
                                <Switch 
                                    style={{marginLeft:"25%"}}
                                    checkedChildren="ON"
                                    unCheckedChildren="OFF"
                                    onChange={(e) => {
                                        handleToggle(e);
                                    }}
                                    checked={toggleCheck}
                                    loading={toggleLoading}
                                />
                            </div>
                         </div>
                    } */}
                </div>
                <div styles={{width:"30%"}}>
                    <Radio.Group
                        buttonStyle={"solid"}
                        value={status}
                        onChange={(e) => {
                            setStatus(e.target.value);
                            setCurrentPageSize(10);
                            setCurrentPage(1);
                        }}
                    >
                        <Radio.Button value="Pending">Pending</Radio.Button>
                        <Radio.Button value="Unconfirm">Unconfirm</Radio.Button>
                        <Radio.Button value="Processing">Processing</Radio.Button>
                        <Radio.Button value="Completed">Completed</Radio.Button>
                        <Radio.Button value="Rejected">Reject</Radio.Button>
                    </Radio.Group>
                </div>
            </div>
        </PageHeader>

        {
            withdrawBankList.length > 0 ?
                <Row style={{ backgroundColor: "#FFFFFF", marginLeft: "0px", marginRight: "0px" }} gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                        { cardList() }
                </Row>
            :
                <></>
        }

        <Modal
            style={{ marginTop: '-2%' }} // Adjust modal position relative to the top
            bodyStyle={{ marginTop: '-2%', height: '80%', overflowY: 'auto' }} // Set body height to 80% of the container
            title="Screenshot"
            visible={unconfirmScreenshotModal}
            onCancel={handleCancel}
            footer={null} // No footer buttons
            width="60%" // Set width to 90% of the screen
        >
            {showScreenshot == true ? (
                <>
                    {/* Flex container to align image and text side by side */}
                    <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                        {/* Image Section */}
                        <div style={{ flex: '1', paddingRight: '1%' }}>
                            <Image 
                                src={screenshotRecord} 
                                alt="Screenshot" 
                                style={{ width: '100%' }} />
                        </div>

                        {/* Text Section */}
                        { status === "Unconfirm" ? 
                            <div style={{ flex: '1', paddingLeft: '4%', alignItems: 'center', textAlign: 'center'}}>
                                <h3 style={{ marginBottom: '5%', fontSize: '2vw' }}>Recommended Action</h3>

                                {OCRRetry ? (
                                    // Display the error message if there is an error and show the retry button
                                    <div style={{ flex: '1', color: 'red', fontWeight: 'bold', fontSize: '1.2vw', textAlign: 'center', marginBottom: '10px' }}>
                                        <p>{OCRError}</p>
                                        <button 
                                            style={{ marginTop: '10px', padding: '10px 20px', fontSize: '1.2vw', cursor: 'pointer', backgroundColor: '#f0ad4e', color: 'white', border: 'none', borderRadius: '5px' }}
                                            onClick={() => handleRetryClick(currentTransaction)} // Call a retry handler when clicked
                                        >
                                            Retry
                                        </button>
                                    </div>
                                ) : OCRError ? (
                                    // Display the error message if there is an error
                                    <div style={{ flex: '1', color: 'red', fontWeight: 'bold', fontSize: '1.2vw', textAlign: 'center', marginBottom: '10px' }}>
                                        <p>{OCRError}, please contact IT.</p>
                                    </div>
                                ) : OCRLoading ? (
                                    // Show loading spinner when loading is true
                                    <div style={{ flex: '1', textAlign: 'center', marginBottom: '10px' }}>
                                        <Spin size="large" /> {/* Ant Design spinner */}
                                        <p style={{ marginTop: '2%' }}>Loading...</p>
                                    </div>
                                ) : (
                                    <div style={{ flex: '1', textAlign: 'center', marginBottom: '10px' }}>
                                        <p style={{ fontSize: '1.8vw', color: 'black' }}>
                                            <span style={{ color: 'red'}}>{' '}<b>{OCRAction}</b></span>
                                        </p>
                                    </div>
                                )}

                                {/* Additional content can be added here if needed */}
                            </div> : <></>
                        }
                        
                    </div>

                    {/* Download Button */}
                    <div style={{ marginTop: '2%', display: 'flex', justifyContent: 'center' }}>
                        <Button
                            style={{ backgroundColor: "#0040FF", border: "none", color: "white" }}
                            download
                            onClick={(e) => {
                                download(); // Call the download function
                            }}
                        >
                            Download
                        </Button>
                    </div>
                </>
            ) : (
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "60vh"  }}>
                    <span style={{ color: "grey", fontSize: '2vw', textAlign: 'center' }}>
                        <i>Screenshot not available, please contact IT.</i>
                    </span>
                </div>
            )}
        </Modal>

        <Modal title="Create Withdrawal Form"
            visible={manualWithdraw}
            closable={false}
            maskClosable={false}
            getContainer={false}
            okButtonProps={{ style: style.hide}}
            cancelText="Close"
            onCancel ={() => {
                onCancelCreateWithdraw();
            }}
            cancelButtonProps={{ type: "primary", loading: createButtonLoading }}   
          >
            <div>
                <div>
                    <Form 
                        form={formCreateWithdraw} 
                        layout="vertical" 
                        name="withdrawform">
                            <Form.Item
                                label="Phone Number"
                                required={true}
                            >
                                <Input.Group compact>
                                    <Select 
                                        value={countryCode}
                                        onChange={selectCountryCode}>
                                            <Option value="66">+66</Option>
                                            <Option value="60">+60</Option>
                                    </Select>
                                    <Input style={{width:"66%"}} defaultValue="" placeholder="phone number" value={phoneNum}
                                        onChange={(e) => {
                                            setPhoneNum(e.target.value.replace(/\D|^0/g, ""));
                                        }}
                                        onBlur={(e)=> {
                                            verifyMobile();
                                        }}
                                    />
                                </Input.Group>
                            </Form.Item>

                            <Form.Item
                                label="Withdrawal Type"
                                required={true}
                                >
                                    <Select
                                        value={withdrawType}
                                        onChange={handleWithdrawType}
                                        disabled={withdrawTypeDisable}
                                    >
                                        <Option value="wallet2bank">Wallet to Bank</Option>
                                        <Option value="comm2bank">Commission to Bank</Option>
                                        <Option value="comm2wallet">Commission to Wallet</Option>
                                    </Select>

                            </Form.Item>

                            <Form.Item
                                id="bankcode"
                                label="Bank"
                                hidden={withdrawHideBank}
                                required={true}
                            >
                                <Select
                                    style={styles.formItem}
                                    showSearch
                                    optionFilterProp="children"
                                    filterOption={(input, option) => {
                                        return option.children.includes(input);
                                    }}
                                    getPopupContainer={(trigger) => trigger.parentNode}
                                    value={bankcode}
                                    onChange={(value, event) => {
                                        setWithdrawToBankData(value);
                                    }}
                                    >
                                        {
                                            csAvaBankList.map((option) => {
                                                return (
                                                    <Option key={option.acctnum} value={`${option.bank} || ${option.acctnum} || ${option.acctname} || ${option.bankcode}`}>
                                                        {option.bank}
                                                    </Option>
                                                );
                                            })
                                        }
                                </Select>
                            </Form.Item>

                            <Form.Item
                                label="Bank Account Number"
                                hidden={withdrawHideBank}
                            >
                                <div>
                                    {acctno}
                                </div>                    
                            </Form.Item>

                            <Form.Item 
                                label="Bank Acc Name"
                                hidden={withdrawHideBank}
                            >
                                {acctname}
                            </Form.Item>

                            <Form.Item
                                label={withdrawAmtLabel}
                                required={true}
                            > 
                                <InputNumber
                                    style={{width:"200px"}}
                                    min={withdrawMinAmt}
                                    value={withdrawAmt}
                                    onChange={(value) => {
                                        setWithdrawAmt(value);
                                    }}
                                />
                            </Form.Item>

                            <Button 
                                type="primary" 
                                onClick={handleCreateWithdraw}
                                loading={createButtonLoading}
                                disabled={createWithdrawtDisable}
                            >
                                Submit
                            </Button>
                    </Form>
                </div>
            </div>
        </Modal>

        <Modal
            title={"Reject Withdraw Reason"}
            visible={rejectWithdrawVisible}
            onOk={() => {
                handleReject();
            }}
            maskClosable={false}
            okText="Reject"
            okType="primary"
            okButtonProps={{ 
                type: "danger", 
                loading: rejectButtonLoading 
            }}
            onCancel={onCancelRejectWithdraw}
            cancelButtonProps={{ 
                type: "primary", 
                loading: rejectButtonLoading 
            }}
        >
            <TextArea
                value={rejectReason}
                rows={4}
                onChange={(e) => {
                    setRejectReason(e.target.value);
                }}
            />
        </Modal>
        <Modal footer={null} style={{height:500}} width={600} visible={modifyAccBalanceToggle} onCancel={()=>{setModifyAccBalanceToggle(false)}}>
            <ModifyAccBalanceForm></ModifyAccBalanceForm>
        </Modal>
        <div style={styles.tableContainer}>
            <Table
                scroll={{ x: isMobile ? "110%" : "100%", y: null }}
                pagination={{
                    defaultPageSize: 10,
                    showSizeChanger: true,
                    pageSizeOptions: settings.pageSizeOptions,
                    style: styles.paginationStyle,
                    alignmentBottom: 'right',
                    pageSize: currentPageSize,
                    current: currentPage,
                    total: dataCount,
                    onChange: handlePageAndSizeChange,
                    onShowSizeChange: handlePageAndSizeChange,
                }}
                columns={withdrawColumns}
                dataSource={data}
                loading={tableLoading}
            />
        </div>
    </div>
    )
}