import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Redirect } from "react-router-dom";

export default function Logout() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: "sign_out" });
    dispatch({ type: "sign_out_remove_access" });
    dispatch({ type: "remove_side_menu" });
    dispatch({ type: "remove_current_page_access" });
  });

  return <Redirect to="/" />;
}
