import React, { useEffect, useState , useRef} from "react";
import {
  Form,
  InputNumber,
  Button,
  Switch,
  Spin,
  Divider,
} from "antd";
import { useSelector } from "react-redux";
import {
  csMaintenanceList,
  csMaintenanceUpdate,
} from "../../config/networkConfig";
import { isMobile } from "react-device-detect";
import { mtnMgmtSwt } from "../../library/permission";
import { alertData, sharedSuccessAlert, sharedErrorAlert, sharedFailAlert, } from "../../common/constants/sharedMsgBox";

export default function MaintenanceManagement() {
  const [form] = Form.useForm();
  const modalForm = useRef();

  const {issuper, userId, username , role, companyId, compCode } = useSelector((state) => state.login);

  const [chkboxEnabled, setChkboxEnabled] = useState(false);

  const [mtnSystem,       setMtnSystem]       = useState(false);    //mtn = Maintenance
  const [mtnPlayGame,     setMtnPlayGame]     = useState(false);
  const [mtnDeposit,      setMtnDeposit]      = useState(false);
  const [mtnWithdraw,     setMtnWithdraw]     = useState(false);
  const [mtnMovie,        setMtnMovie]        = useState(false);
  const [mtnSport,        setMtnSport]        = useState(false);
  const [mtnPorn,         setMtnPorn]         = useState(false);
  const [mtnWithdrawByAPI, setMtnWithdrawByAPI] = useState(false);
  const [mtnAutoWithdrawAmt, setMtnAutoWithdrawAmt] = useState(0);
  const [mtnEditAutoWithdrawAmt, setEditAutoWithdrawAmt] = useState(0);
  const [registerSkipOtp, setRegisterSkipOtp] = useState(false);
  const [skipManualTopupReceipt, setSkipManualTopupReceipt] = useState(false);
  const [mtnWebBankVerify, setMtnWebBankVerify] = useState(false);
  const [mtnBotBankVerify, setMtnBotBankVerify] = useState(false);
  const [mtnSmsByAPI,     setMtnSmsByAPI] = useState(false);
  const [mtnSmsByBot,     setMtnSmsByBot] = useState(false);
  const [loginSkipOtp, setLoginSkipOtp] = useState(false);
  const [registerSkipAddBank, setRegisterSkipAddBank] = useState(false);
  const [enableDepLoadBalance, setEnableDepLoadBalance] = useState(false);
  const [skipBankVerify, setSkipBankVerify] = useState(false);

  const [settLoading, setSettLoading] = useState(false);

  useEffect(() => {
    companyData();
    // eslint-disable-next-line
  }, []);

  //Components
  const CLabel = (props) => {
      return <b>{props.loading? <Spin/> : 
                 props.setting ? <span style={styles.on} loading={settLoading}>ON</span>:<span style={styles.off} loading={settLoading}>OFF</span> }</b>
  }

  const getformValue = (name) => {
    return form.getFieldValue(name);
  };

  const formItemLayout = {
    labelCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 8,
      },
    },
    wrapperCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 7,
      },
    },
  };

  const subItemLayout = {
    labelCol: {
      span: 8,
    },
    wrapperCol: {
      span: 18,
    },
  };

  const formItemLayoutWithOutLabel = {
    wrapperCol: {
      span: 17,
      offset: 8,
    },
  };


  const companyData = async () => {
    setSettLoading(true);

    let requestData = {
      companyID : companyId,
      columnRequire : [
        'SetMaintainSystem as mtnSystem', 'SetMaintainPlayGame as mtnPlayGame', 'SetMaintainTrxnDeposit as mtnDeposit', 'SetMaintainTrxnWithdraw as mtnWithdraw', 'SetMaintainMovie as mtnMovie', 'SetMaintainSportTv as mtnSport',
        'SetMaintainPorn as mtnPorn', 'EnableWithdrawByAPI as mtnWithdrawByAPI', 'EnableWebBankVerify as mtnWebBankVerify', 'EnableBotBankVerify as mtnBotBankVerify', 'EnableSmsByAPI as mtnSmsByAPI', 'EnableSmsByBot as mtnSmsByBot',
        'SetAutoWitAmt as autoWitAmt, RegSkipOtp as regSkipOtp, SkipManualTopupReceipt as skipManualTopupReceipt', 'SkipOtp as skipOtp', 'SkipBindBank as skipBindBank', 'SkipBankVerifyBO as skipBankVerifyBO', 'EnableDepLoadBalance as enableDepLoadBalance'
      ]
    }
    
    const result = await csMaintenanceList(requestData);

    let resp = await result.json();
    if (resp.status == "ok") {
        const data = resp.data;
        form.setFieldsValue({
          mtnSystem: data[0].mtnSystem,
          mtnPlayGame: data[0].mtnPlayGame,
          mtnDeposit : data[0].mtnDeposit,
          mtnWithdraw : data[0].mtnWithdraw,
          mtnMovie : data[0].mtnMovie,
          mtnSport : data[0].mtnSport,
          mtnPorn  : data[0].mtnPorn,
          mtnWithdrawByAPI: data[0].PlusOutlinedmtnWithdrawByAPI,
          autoWithdrawAmtMtn : data[0].autoWitAmt,
          mtnWebBankVerify : data[0].mtnWebBankVerify,
          mtnBotBankVerify : data[0].mtnBotBankVerify,
          mtnSmsByAPI : data[0].mtnSmsByAPI,
          mtnSmsByBot : data[0].mtnSmsByBot,
        });

        setMtnSystem(convertToBool(data[0].mtnSystem));
        setMtnPlayGame(convertToBool(data[0].mtnPlayGame));
        setMtnDeposit(convertToBool(data[0].mtnDeposit));
        setMtnWithdraw(convertToBool(data[0].mtnWithdraw));
        setMtnMovie(convertToBool(data[0].mtnMovie));
        setMtnSport(convertToBool(data[0].mtnSport));
        setMtnPorn(convertToBool(data[0].mtnPorn));
        setMtnWithdrawByAPI(convertToBool(data[0].mtnWithdrawByAPI));
        setMtnAutoWithdrawAmt(data[0].autoWitAmt);
        setEditAutoWithdrawAmt(data[0].autoWitAmt);
        setRegisterSkipOtp(intToBool(data[0].regSkipOtp));
        setEnableDepLoadBalance(intToBool(data[0].enableDepLoadBalance));
        setSkipBankVerify(intToBool(data[0].skipBankVerifyBO))
        setMtnWebBankVerify(convertToBool(data[0].mtnWebBankVerify));
        setMtnBotBankVerify(convertToBool(data[0].mtnBotBankVerify));
        setMtnSmsByAPI(convertToBool(data[0].mtnSmsByAPI));
        setMtnSmsByBot(convertToBool(data[0].mtnSmsByBot));
        setSettLoading(false);
        setLoginSkipOtp(intToBool(data[0].skipOtp));
        setRegisterSkipAddBank(intToBool(data[0].skipBindBank))
    }
    else {
      alertData.title = "Failed";
      alertData.msg = resp.msg;
      sharedFailAlert(alertData);
      setSettLoading(false);
    }

  };

  //#region Functions
  let convertToBool = (value)=>{
      if (value == null || value == '') {
        return false;
      }
      else {
         if (value.toString().toUpperCase() == 'Y') {
            return true;
         }
         else {
           return false;
         }
      }
  }

  let boolToString = (value) => {
    if (value == null || value == '') {
      return 'N';
    }
    else {
       if (JSON.parse(value) == true) {
          return 'Y';
       }
       else {
         return 'N';
       }
    }
  }

  let boolToInt = (value) => {
    if (value == null || value == '') {
      return 0;
    }
    else {
       if (JSON.parse(value) == true) {
          return 1;
       }
       else {
         return 0;
       }
    }
  }

  let intToBool = (value)=>{
    if (value == null || value == '') {
      return false;
    }
    else {
       if (value == 1) {
          return true;
       }
       else {
         return false;
       }
    }
  }

  const onChangeMtnSys = (e)=> {

    let status = e;
      setMtnSystem(status);
      setMtnSport(status)
      setMtnPlayGame(status);
      setMtnDeposit(status);
      setMtnWithdraw(status);
      setMtnMovie(status);
      setMtnPorn(status);
  }

  const onFinish = async (values) => {
    setSettLoading(true);

    if (boolToString(mtnWebBankVerify) == 'N' && boolToString(mtnBotBankVerify) == 'N') {
      setSettLoading(false);
      alertData.title = "Warning";
      alertData.msg =  "Please enable at least one BANK VERIFY method !";
      sharedErrorAlert(alertData);
      return;
    }

    if (boolToString(mtnSmsByAPI) == 'N' && boolToString(mtnSmsByBot) == 'N') {
      setSettLoading(false);
      alertData.title = "Warning";
      alertData.msg =  `Please enable at least one SMS method !`;
      sharedErrorAlert(alertData);
      return;
    }

    let requestData = {
      companyId : companyId,
      username: username,
    }

    if (values.sysMtn != undefined) {
      requestData.mtnSystem = boolToString(mtnSystem);
      requestData.mtnPlayGame = boolToString(mtnPlayGame);
      requestData.mtnTrxnDeposit = boolToString(mtnDeposit);
      requestData.mtnTrxnWithdraw = boolToString(mtnWithdraw);
      requestData.mtnMovie = boolToString(mtnMovie);
      requestData.mtnSport = boolToString(mtnSport);
      requestData.mtnPorn = boolToString(mtnPorn);
    }
    else {
      if (values.playGameMtn != undefined) { 
        requestData.mtnPlayGame = boolToString(mtnPlayGame); 
      }

      if (values.depositMtn != undefined) { 
        requestData.mtnTrxnDeposit = boolToString(mtnDeposit);
      }
      
      if (values.withdrawMtn != undefined) { 
        requestData.mtnTrxnWithdraw = boolToString(mtnWithdraw);
      }

      if (values.movieMtn != undefined) { 
        requestData.mtnMovie = boolToString(mtnMovie);
      }

      if (values.sportMtn != undefined) { 
        requestData.mtnSport = boolToString(mtnSport);
      }

      if (values.pornMtn != undefined) { 
        requestData.mtnPorn = boolToString(mtnPorn);
      }
    }

    if (values.autoWithdrawMtn != undefined) {
      requestData.mtnWithdrawByAPI = boolToString(mtnWithdrawByAPI) == 'Y' ? "ON" : "OFF";
    }

    if (values.autoWithdrawAmtMtn != mtnAutoWithdrawAmt) {
      requestData.mtnAutoWithdrawAmt = values.autoWithdrawAmtMtn;
    }

    if (values.registerSkipOtp != undefined && registerSkipOtp) {
      requestData.loginSkipOtp = boolToInt(loginSkipOtp);
      requestData.registerSkipOtp = boolToInt(registerSkipOtp);
    } else if (values.registerSkipOtp != undefined ) {
      requestData.registerSkipOtp = boolToInt(registerSkipOtp);
    }

    if (values.skipManualTopupReceipt != undefined) {
      requestData.skipManualTopupReceipt = boolToString(skipManualTopupReceipt);
    }

    if (values.enableDepLoadBalance != undefined) {
      requestData.enableDepLoadBalance = boolToInt(enableDepLoadBalance);
    }

    if (values.loginSkipOtp != undefined) {
      requestData.loginSkipOtp = boolToInt(loginSkipOtp);
    }

    if (values.enableWebBankVerify != undefined) {
      requestData.mtnWebBankVerify = boolToString(mtnWebBankVerify);
    }

    if (values.enableBotBankVerify != undefined) {
      requestData.mtnBotBankVerify = boolToString(mtnBotBankVerify);
    }

    if (values.enableSendSmsByAPI != undefined) {
      requestData.mtnSmsByAPI = boolToString(mtnSmsByAPI);
    }

    if (values.enableSendSmsByBot != undefined) {
      requestData.mtnSmsByBot = boolToString(mtnSmsByBot);
    }

    if (values.registerSkipAddBank != undefined) {
      requestData.skipBindBank = boolToInt(registerSkipAddBank);
    }

    if (values.skipBankVerify != undefined) {
      requestData.skipBankVerifyBO = boolToInt(skipBankVerify);
    }

    if (Object.keys(requestData).length > 2) {

      const result = await csMaintenanceUpdate(requestData);
      let resp = await result.json();
      if (resp.status == "ok") {
          setSettLoading(false);
          companyData();
          alertData.title = "Success";
          alertData.msg = resp.msg;
          sharedSuccessAlert(alertData);
      }
      else {
          setSettLoading(false);
          alertData.title = "Failed";
          alertData.msg = resp.msg;
          sharedFailAlert(alertData);
      } 
    }
    else {
      setSettLoading(false);
      companyData();
      alertData.title = "Success";
      alertData.msg = "Maintenance setting is updated.";
      sharedSuccessAlert(alertData);
    }
  };
  //#endregion


  const styles = {
    pageHeaderContainer: {
      justifyContent: isMobile ? "" : "space-between",
      display: "flex",
      flexDirection: isMobile ? "column" : "",
      alignItems: isMobile ? "center" : "",
    },
    refreshBtn: {
      marginLeft: 10,
    },
    actionBtn: {
        height:"30px",
        display : issuper? "block":"none",
    },
    submitBtn: {
      marginBottom:"20px",
    },
    tabelContainer: {
      backgroundColor: "#FFFFFF",
      padding: 24,
    },
    paginationStyle: {
      marginTop: 50,
    },
    formItem: {
      width:"80%"
    },
    datePickerStyle: {
      width: 260,
    },
    hide : {
      display:"none",
    },
    memberSearchStyle:{
      width:180,
    },
    on : {
      color:"green",
    },
    off : {
      color :"red"
    }
  };

  // login skip otp become true when reg skip OTP is true;
  useEffect(() => {
    if (registerSkipOtp) {
      setLoginSkipOtp(registerSkipOtp);
    }
  }, [registerSkipOtp])

  return (
    <Form
      {...formItemLayout}
      onFinish={onFinish}
      form={form}
      // initialValues={{
      //   subUrls: subUrl,
      // }}
      style={{backgroundColor:"white",paddingBottom:"5px"}}
    >

      {/*Maintnenance Settings start */}
      <Form.Item label="System Maintenance">
        <Form.Item name="sysMtn" noStyle>
          {
            !mtnMgmtSwt(role, compCode)?
            <>
              <CLabel setting={mtnSystem} loading={settLoading}/>
            </>
            :
              <Switch
              checkedChildren="ON"
              unCheckedChildren="OFF"
              onChange={(e) => {
                onChangeMtnSys(e);
              }}
              checked={mtnSystem}
              loading={settLoading}
            />
          }

        </Form.Item>
      </Form.Item>
      
      <Form.Item label="Play Game Maintenance">
        <Form.Item name="playGameMtn" noStyle>
          {
            !mtnMgmtSwt(role, compCode)?
            <>
              <CLabel setting={mtnPlayGame} loading={settLoading}/>
            </>
            :
            <Switch
              checkedChildren="ON"
              unCheckedChildren="OFF"
              onChange={(e) => {
                setMtnPlayGame(e);
              }}
              checked={mtnPlayGame}
              loading={settLoading}
            />
          }
        </Form.Item>
      </Form.Item>

      <Form.Item label="Deposit Maintenance">
        <Form.Item name="depositMtn" noStyle>
          {
            !mtnMgmtSwt(role, compCode)?
            <>
              <CLabel setting={mtnDeposit} loading={settLoading}/>
            </>
            :
            <Switch
              checkedChildren="ON"
              unCheckedChildren="OFF"
              onChange={(e) => {
                setMtnDeposit(e);
              }}
              checked={mtnDeposit}
              loading={settLoading}
            />
          }
        </Form.Item>
      </Form.Item>

      <Form.Item label="Withdrawal Maintenance">
        <Form.Item name="withdrawMtn" noStyle>
          {
            !mtnMgmtSwt(role, compCode)?
            <>
              <CLabel setting={mtnWithdraw} loading={settLoading}/>
            </>
            :
            <Switch
              checkedChildren="ON"
              unCheckedChildren="OFF"
              onChange={(e) => {
                setMtnWithdraw(e);
              }}
              checked={mtnWithdraw}
              loading={settLoading}
            />
          }
        </Form.Item>
      </Form.Item>

      <Form.Item label="Movie Maintenance">
        <Form.Item name="movieMtn" noStyle>
          {
            !mtnMgmtSwt(role, compCode)?
            <>
              <CLabel setting={mtnMovie} loading={settLoading}/>
            </>
            :
            <Switch
              checkedChildren="ON"
              unCheckedChildren="OFF"
              onChange={(e) => {
                setMtnMovie(e);
              }}
              checked={mtnMovie}
              loading={settLoading}
            />
          }
        </Form.Item>
      </Form.Item>

      {
        (compCode != 'OGT' && compCode != 'TKC') ?
        <></>
      :
        <Form.Item label="Live Sports TV Maintenance">
            <Form.Item name="sportMtn" noStyle>
              {
                !mtnMgmtSwt(role, compCode)?
                <>
                  <CLabel setting={mtnSport} loading={settLoading}/>
                </>
                :
                <Switch
                  checkedChildren="ON"
                  unCheckedChildren="OFF"
                  onChange={(e) => {
                    setMtnSport(e);
                  }}
                  checked={mtnSport}
                  loading={settLoading}
                />
              }
            </Form.Item>
        </Form.Item>
      }


      <Form.Item label="Adult Movie Maintenance">
        <Form.Item name="pornMtn" noStyle>
          {
            !mtnMgmtSwt(role, compCode)?
            <>
              <CLabel setting={mtnPorn} loading={settLoading}/>
            </>
            :
            <Switch
              checkedChildren="ON"
              unCheckedChildren="OFF"
              onChange={(e) => {
                setMtnPorn(e);
              }}
              checked={mtnPorn}
              loading={settLoading}
            />
          }
        </Form.Item>
      </Form.Item>

      <Divider />

      {/* <Form.Item label="Withdraw by API Switch (SCB)">
        <Form.Item name="autoWithdrawMtn" noStyle>
          {
            !mtnMgmtSwt(role, compCode)?
            <>
              <CLabel setting={mtnWithdrawByAPI} loading={settLoading}/>
            </>
            :
            <Switch
              checkedChildren="ON"
              unCheckedChildren="OFF"
              onChange={(e) => {
                setMtnWithdrawByAPI(e);
              }}
              checked={mtnWithdrawByAPI}
              loading={settLoading}
            />
          }
        </Form.Item>
      </Form.Item> */}

      <Form.Item label="Auto Withdraw Amount (Set to 1 to Disable)">
        <Form.Item name="autoWithdrawAmtMtn" noStyle>
          {
            !mtnMgmtSwt(role, compCode)?
            <>
              <InputNumber
                style={{width: "25%"}}
                value={mtnAutoWithdrawAmt}
                disabled={true}
              />
            </>
            :
            <InputNumber
              style={{width: "25%"}}
              value={mtnAutoWithdrawAmt}
              min={0}
              onChange={(e) => {
                setEditAutoWithdrawAmt(e);
              }}
            />
          }
        </Form.Item>
      </Form.Item>

      <Divider />

      <Form.Item label="Disable OTP for New Register">
        <Form.Item name="registerSkipOtp" noStyle>
          {
            !mtnMgmtSwt(role, compCode)?
            <>
              <CLabel setting={registerSkipOtp} loading={settLoading}/>
            </>
            :
            <Switch
              checkedChildren="ON"
              unCheckedChildren="OFF"
              onChange={(e) => {
                setRegisterSkipOtp(e);
              }}
              checked={registerSkipOtp}
              loading={settLoading}
            />
          }
        </Form.Item>
      </Form.Item>

      <Form.Item label="Disable OTP for Login">
        <Form.Item name="loginSkipOtp" noStyle>
          {
            !mtnMgmtSwt(role, compCode)?
            <>
              <CLabel setting={loginSkipOtp} loading={settLoading}/>
            </>
            :
            <Switch
              checkedChildren="ON"
              unCheckedChildren="OFF"
              onChange={(e) => {
                if (!registerSkipOtp) {
                  setLoginSkipOtp(e);
                }
                // only can change value when register skip otp is false
              }}
              checked={loginSkipOtp}
              loading={settLoading}
            />
          }
        </Form.Item>
      </Form.Item>

      <Form.Item label="Disable Receipt for Manual Topup">
        <Form.Item name="skipManualTopupReceipt" noStyle>
          {
            !mtnMgmtSwt(role, compCode)?
            <>
              <CLabel setting={skipManualTopupReceipt} loading={settLoading}/>
            </>
            :
            <Switch
              checkedChildren="ON"
              unCheckedChildren="OFF"
              onChange={(e) => {
                setSkipManualTopupReceipt(e);
              }}
              checked={skipManualTopupReceipt}
              loading={settLoading}
            />
          }
        </Form.Item>
      </Form.Item>
      
      <Form.Item label="Disable Deposit Bank Load Balancing">
        <Form.Item name="enableDepLoadBalance" noStyle>
          {
            !mtnMgmtSwt(role, compCode)?
            <>
              <CLabel setting={enableDepLoadBalance} loading={settLoading}/>
            </>
            :
            <Switch
              checkedChildren="ON"
              unCheckedChildren="OFF"
              onChange={(e) => {
                setEnableDepLoadBalance(e);
              }}
              checked={enableDepLoadBalance}
              loading={settLoading}
            />
          }
        </Form.Item>
      </Form.Item>

      <Divider />

      <Form.Item label="Disable Add Bank for New Register">
        <Form.Item name="registerSkipAddBank" noStyle>
          {
            !mtnMgmtSwt(role, compCode)?
            <>
              <CLabel setting={registerSkipAddBank} loading={settLoading}/>
            </>
            :
            <Switch
              checkedChildren="ON"
              unCheckedChildren="OFF"
              onChange={(e) => {
                setRegisterSkipAddBank(e);
              }}
              checked={registerSkipAddBank}
              loading={settLoading}
            />
          }
        </Form.Item>
      </Form.Item>
      {/* <Form.Item label="Disable Bank Verification for CS User">
        <Form.Item name="skipBankVerify" noStyle>
          {
            !mtnMgmtSwt(role, compCode)?
            <>
              <CLabel setting={skipBankVerify} loading={settLoading}/>
            </>
            :
            <Switch
              checkedChildren="ON"
              unCheckedChildren="OFF"
              onChange={(e) => {
                setSkipBankVerify(e);
              }}
              checked={skipBankVerify}
              loading={settLoading}
            />
          }
        </Form.Item>
      </Form.Item> */}

      {/* <Form.Item label="Enable Web Bank Verify">
        <Form.Item name="enableWebBankVerify" noStyle>
          {
            !mtnMgmtSwt(role, compCode)?
            <>
              <CLabel setting={mtnWebBankVerify} loading={settLoading}/>
            </>
            :
            <Switch
              checkedChildren="ON"
              unCheckedChildren="OFF"
              onChange={(e) => {
                setMtnWebBankVerify(e);
                
              }}
              checked={mtnWebBankVerify}
              loading={settLoading}
            />
          }
        </Form.Item>
      </Form.Item>

      <Form.Item label="Enable Bot Bank Verify">
        <Form.Item name="enableBotBankVerify" noStyle>
          {
            !mtnMgmtSwt(role, compCode)?
            <>
              <CLabel setting={mtnBotBankVerify} loading={settLoading}/>
            </>
            :
            <Switch
              checkedChildren="ON"
              unCheckedChildren="OFF"
              onChange={(e) => {
                setMtnBotBankVerify(e);
              }}
              checked={mtnBotBankVerify}
              loading={settLoading}
            />
          }
        </Form.Item>
      </Form.Item>

      <Divider />

      <Form.Item label="Enable SMS by API">
        <Form.Item name="enableSendSmsByAPI" noStyle>
          {
            !mtnMgmtSwt(role, compCode)?
            <>
              <CLabel setting={mtnSmsByAPI} loading={settLoading}/>
            </>
            :
            <Switch
              checkedChildren="ON"
              unCheckedChildren="OFF"
              onChange={(e) => {
                setMtnSmsByAPI(e);
              }}
              checked={mtnSmsByAPI}
              loading={settLoading}
            />
          }
        </Form.Item>
      </Form.Item>

      <Form.Item label="Enable SMS by Bot">
        <Form.Item name="enableSendSmsByBot" noStyle>
          {
            !mtnMgmtSwt(role, compCode)?
            <>
              <CLabel setting={mtnSmsByBot} loading={settLoading}/>
            </>
            :
            <Switch
              checkedChildren="ON"
              unCheckedChildren="OFF"
              onChange={(e) => {
                setMtnSmsByBot(e);
              }}
              checked={mtnSmsByBot}
              loading={settLoading}
            />
          }
        </Form.Item>
      </Form.Item> */}

      {/*Maintnenance Settings end */}

      <Form.Item label=" " colon={false}>
        {
          !mtnMgmtSwt(role, compCode)?
          <></>
          :        
          <Button 
            type="primary" 
            htmlType="submit" 
            style={styles.submitBtn}
            loading={settLoading}
          >
            Submit
          </Button>
        }
      </Form.Item>
    </Form>
  );
}
