import React, { useState, useEffect, useRef } from "react";
import {
  Table,
  Input,
  Form,
  Button,
  Modal,
  PageHeader,
  Select,
  Checkbox,
  Switch,
} from "antd";
import {
  FolderViewOutlined,
} from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import {
  getBoUserType,
  getPageAccess,
  deletePageAccess,
  setPageAccess,
  getParentPage,
  getBoPage,
  getUserTemplate,
  setUserAclStatus,
  setUserAcl,
  setUserAccessTemplate,
} from "../../config/networkConfig";
import md5 from "md5";
import { useSelector } from "react-redux";
import { CS_USER_TYPE, MARKETING_USER_TYPE, REPORT_USER_TYPE } from "../../common/constants/csuserType";
import { alertData, sharedSuccessAlert, sharedErrorAlert, sharedFailAlert, } from "../../common/constants/sharedMsgBox";
import { boUserVw } from "../../library/permission";
import { CNotAuthorized } from "../../common/components/CNotAuthorized";


export default function UserAccessTemplate() {
  const { role, userId, companyId } = useSelector((state) => state.login);
  const [form] = Form.useForm();
  const [tableLoadings, setTableLoadings]     =     useState(false);
  const [userTemplateData, setUserTemplateData] = useState([])
  const [statusLoading, setStatusLoading] = useState(false);
  const [addUserAccessTemplate, setAddUserAccessTemplate] = useState(false);
  const [editUserAccessTemplate, setEditUserAccessTemplate] = useState(false);
  const [addUserAccessTemplateSubmitLoading, setAddUserAccessTemplateSubmitLoading] = useState(false);
  const [editUserAccessTemplateSubmitLoading, setEditUserAccessTemplateSubmitLoading] = useState(false);
  const [editUserAccessTemplateLoading, setEditUserAccessTemplateLoading] = useState(false);
  const [currentParentPage, setCurrentParentPage] = useState(1);
  const [tableKey, setTableKey] = useState(0);
  const [parentPageOptions, setParentPageOptions] = useState([]);
  const [selectedPage, setSelectedPage] = useState({});
  const { Option } = Select;
  const [userAccessTemplateData, setUserAccessTemplateData] = useState({});
  const [userAccessName, setUserAccessName] = useState("");
  const [editingUser, setEditingUser] = useState("");
  const [boUserTypeList, setBoUserTypeList] = useState([]);
  const [grantAllAccess, setGrantAllAccess] = useState(false);

  useEffect(() => {
    getParentPageList();
    retrieveUserType();
    // eslint-disable-next-line
  }, []);

  const columns = [
    {
      title: "User Type",
      dataIndex: "displayName",
      width: "30%",
      ellipsis: true,
    },
    {
      ellipsis: true,
      title: "Register Date",
      dataIndex: "registerDate",
      width: "30%",
      sorter: (a,b) => new Date(a.registerDate) - new Date(b.registerDate),
    },
    {
      title: "Status",
      dataIndex: "status",
      width: "20%",
      render: (text, record) => {
        return (
          <Switch
            checked={record.status}
            loading={statusLoading}
            onChange={(checked) => handleStatusChange(checked, record)}
            checkedChildren="Active"
            unCheckedChildren="Inactive"
          />
        );
      },
    },
    {
      align: "center",
      ellipsis: true,
      title: "Action",
      width: "20%",
      // dataIndex: "operation",
      render: (text, record) => {
        return (
          <Button type="primary" shape="circle" icon={<FolderViewOutlined />} onClick={() => handleEditUserAccessTemplate(record)} loading={editUserAccessTemplateLoading}/>
        )      
      },
    },
  ];

  const pageAccessColumn = [
    {
      title: ' Page Name',
      dataIndex: 'pageName',
      key: 'pageName',
    },
    {
      title: "Read Access",
      editable: true,
      align: "center",
      render: (record) => {
        return (
          <Checkbox
            defaultChecked={record.read}
            checked={record.read}
            disabled={!record.status}
            onChange={onCheckboxChange(record, 'read')}
          />
        )
      }
    },
    {
      title: "Create Access",
      editable: true,
      align: "center",
      render: (record) => {
        return (
          <Checkbox
            defaultChecked={record.create}
            checked={record.create}
            disabled={!record.status}
            onChange={onCheckboxChange(record, 'create')}
          />
        )
      }
    },
    {
      title: "Update Access",
      editable: true,
      align: "center",
      render: (record) => {
        return (
          <Checkbox
            defaultChecked={record.write}
            checked={record.write}
            disabled={!record.status}
            onChange={onCheckboxChange(record, 'write')}
          />
        )
      }
    },
    {
      title: "Delete Access",
      editable: true,
      align: "center",
      render: (record) => {
        return (
          <Checkbox
            defaultChecked={record.delete}
            checked={record.delete}
            disabled={!record.status}
            onChange={onCheckboxChange(record, 'delete')}
          />
        )
      }
    },
  ];

  const onCheckboxChange = (record, type) => (e) => {
    setTableKey(prevKey => prevKey + 1);

    const isChecked = e.target.checked;

    if (record.status && record.read && type === "read") {
      return;
    }

    setUserAccessTemplateData((prevState) => {
      // Create a copy of the existing state for immutability
      const updatedData = { ...prevState };

      // Find the index of the record within the currentParentPage array
      const currentPageData = updatedData[currentParentPage] || [];
      const recordIndex = currentPageData.findIndex((item) => item.key === record.key);

      if (recordIndex !== -1) {
          // Update the specific type (write, create, delete) based on the checkbox status
          currentPageData[recordIndex] = {
              ...currentPageData[recordIndex],
              [type]: isChecked ? 1 : 0,
          };

          // Assign the updated page data back to the state
          updatedData[currentParentPage] = currentPageData;
      }

      return updatedData;
    });
  };

  const handleStatusChange = async (checked, record) => {
    setStatusLoading(true);
    const status = checked ? 1 : 0 ;
    const requestData = { access: record.userType, status};

    const response = await setUserAclStatus(requestData);
    const res = await response.json();

    if (res.status === 'ok') {
      retrieveUserType();
    } 
    setStatusLoading(false);
  }

  const getParentPageList = async () => {
    const response = await getParentPage();
    const res = await response.json();

    if (res.status === 'ok') {
      let count = 0;
      let newData = res.data.map((obj) => {
        return {
          key: count ++,
          value: obj.ParentId,
          label: obj.ParentName
        }
      })
      setParentPageOptions(newData);
    }
  }

  const handleAddUserAccessTemplate = async () => {

    // Get latest page data from bo_page_access
    const response1 = await getBoPage();
    const res1 = await response1.json();
    if (res1.status == 'ok') {
      const data = res1.data;
      const boPageList = data.map(obj => ({
        // Create default access entries from boPageList
          ParentId: obj.parentId,
          PageId: obj.PageId,
          PageName: obj.PageName,
          AccessStatus: 0,
          ReadAccess: 0,
          WriteAccess: 0,
          CreateAccess: 0,
          DeleteAccess: 0
      }));
      
      // Format data
      const formattedData = boPageList.reduce((acc, item) => {
          const { ParentId, PageId, PageName, AccessStatus, ReadAccess, WriteAccess, CreateAccess, DeleteAccess } = item;
      
          const newItem = {
              key: PageId,
              pageId: PageId,
              pageName: PageName,
              status: AccessStatus,
              read: ReadAccess,
              write: WriteAccess,
              create: CreateAccess,
              delete: DeleteAccess
          };
      
          if (!acc[ParentId]) {
              acc[ParentId] = [];
          }
      
          acc[ParentId].push(newItem);
          return acc;
      }, {});

    setUserAccessTemplateData(formattedData);
    }

    setAddUserAccessTemplate(true);
  }

  const handleEditUserAccessTemplate = async (record) => {
    setEditUserAccessTemplateLoading(true);
    setEditingUser(record.userType);

    // Get latest page data from bo_page_access
    const response1 = await getBoPage();
    const res1 = await response1.json();
    if (res1.status !== 'ok') {}
    const boPageList = res1.data;

    const requestData = {
      accessName: record.userType
    }
    const response = await getPageAccess(requestData);
    const res = await response.json();
    if (res.status === 'ok') {
      let userTemplateAccess = res.data;
      // if userTemplateAccess is empty data, res.data = {};
      if (userTemplateAccess && Object.keys(userTemplateAccess).length === 0) {
          // Create default access entries from boPageList
          userTemplateAccess = boPageList.map(page => ({
              ParentId: page.parentId,
              PageId: page.PageId,
              PageName: page.PageName,
              AccessStatus: 0,
              ReadAccess: 0,
              WriteAccess: 0,
              CreateAccess: 0,
              DeleteAccess: 0
          }));

      } 

      
      
      // else {
      //     // If userTemplateAccess has data, ensure it's an array
      //     userTemplateAccess = Array.isArray(userTemplateAccess) ? userTemplateAccess : [];
      // }

      // Step 1: Create a set of existing PageIds in userTemplateAccess
      const existingPageIds = new Set(userTemplateAccess.map(page => page.PageId));

      // Step 2: Iterate through boPageList
      boPageList.forEach(page => {
          // Check if the page is already in userTemplateAccess
          if (!existingPageIds.has(page.PageId)) {
              // Create a new entry with default values
              const newAccessEntry = {
                  ParentId: page.parentId,  // You may need to determine how to handle ParentId if it's required
                  PageId: page.PageId,
                  PageName: page.PageName,
                  AccessStatus: 0,
                  ReadAccess: 0,
                  WriteAccess: 0,
                  CreateAccess: 0,
                  DeleteAccess: 0
              };
              // Add the new entry to userTemplateAccess
              userTemplateAccess.push(newAccessEntry);
          }
      });

      // Format data
      const formattedData = userTemplateAccess.reduce((acc, item) => {
          const { ParentId, PageId, PageName, AccessStatus, ReadAccess, WriteAccess, CreateAccess, DeleteAccess } = item;
      
          const newItem = {
              key: PageId,
              pageId: PageId,
              pageName: PageName,
              status: AccessStatus,
              read: ReadAccess,
              write: WriteAccess,
              create: CreateAccess,
              delete: DeleteAccess
          };
      
          if (!acc[ParentId]) {
              acc[ParentId] = [];
          }
      
          acc[ParentId].push(newItem);
          return acc;
      }, {});

      setUserAccessTemplateData(formattedData);

      const createFilteredPageIds = Object.entries(formattedData).reduce((acc, [parentId, pages]) => {
          const pageIds = pages
              .filter(page => page.status === 1)
              .map(page => page.key);             
      
          acc[parentId] = pageIds;
          return acc;
      }, {});
      
      setSelectedPage(createFilteredPageIds);

      setEditUserAccessTemplate(true);
    }
    setEditUserAccessTemplateLoading(false);
  }

  const onCancelAddUserAccessTemplate = () => {
    setAddUserAccessTemplate(false);
    setEditUserAccessTemplate(false);
    setUserAccessTemplateData({});
    setCurrentParentPage(1);
    setUserAccessName("");
    setSelectedPage({});
    setAddUserAccessTemplateSubmitLoading(false);
    setEditUserAccessTemplateSubmitLoading(false);
    // setPageAccessSubmitLoading(false);
  }

  const handleSubmitAddUserAccessTemplate = async () => {
    setAddUserAccessTemplateSubmitLoading(true);

    // Add user access name to user_template
    const requestData = { access: userAccessName}
    const addUserResponse = await setUserAcl(requestData);
    const addUserRes = await addUserResponse.json();
    if (addUserRes.status !== 'ok') {
      onCancelAddUserAccessTemplate();
      alertData.title = "Failed";
      alertData.msg = addUserRes.msg;
      sharedFailAlert(alertData);
      return;
    }

    // Add user access template to user_access_template
    const combinePageAccessData = (userAccessTemplateData) => {
      // Initialize an array to hold all values
      let combinedValues = [];
  
      // Iterate over each parent page in the userAccessTemplateData
      for (const pageData of Object.values(userAccessTemplateData)) {
          // Flatten the array of page values into the combinedValues array
          combinedValues = combinedValues.concat(
              pageData.map(({ key, ...rest }) => rest) // Exclude the key field
          );
      }
  
      return combinedValues;
    };

    const combinedArray = combinePageAccessData(userAccessTemplateData);

    const requestData2 = {
      accessName: userAccessName,
      userAccessTemplateData: combinedArray
    }

    const setResponse = await setUserAccessTemplate(requestData2);
    const setRes = await setResponse.json();
   
    if (setRes.status === 'ok') {
      alertData.title = "Success";
      alertData.msg = "User Access Template added successfully!";
      sharedSuccessAlert(alertData);
    } else {
      alertData.title = "Failed";
      alertData.msg = "Failed to add user access template. Please try again later!";
      sharedFailAlert(alertData);
    }
    onCancelAddUserAccessTemplate();
    retrieveUserType();
  }

  const handleSubmitEditUserAccessTemplate = async () => {
    setEditUserAccessTemplateSubmitLoading(true);
    const requestData = { accessName: editingUser}

    const deleteResponse = await deletePageAccess(requestData);
    const deleteRes = await deleteResponse.json();

    if (deleteRes.status !== 'ok') {
      // Close modal
      onCancelAddUserAccessTemplate();
      alertData.title = "Failed";
      alertData.msg = "Failed to edit user access template. Please try again later!";
      sharedFailAlert(alertData);
      return;
    }

    const combinePageAccessData = (userAccessTemplateData) => {
      // Initialize an array to hold all values
      let combinedValues = [];
  
      // Iterate over each parent page in the userAccessTemplateData
      for (const pageData of Object.values(userAccessTemplateData)) {
          // Flatten the array of page values into the combinedValues array
          combinedValues = combinedValues.concat(
              pageData.map(({ key, ...rest }) => rest) // Exclude the key field
          );
      }
  
      return combinedValues;
    };

    const combinedArray = combinePageAccessData(userAccessTemplateData);

    const requestData2 = {
      accessName: editingUser,
      userAccessTemplateData: combinedArray
    }

    const setResponse = await setPageAccess(requestData2);
    const setRes = await setResponse.json();
   
    if (setRes.status === 'ok') {
      alertData.title = "Success";
      alertData.msg = "User access template edited successfully!";
      sharedSuccessAlert(alertData);
    } else {
      alertData.title = "Failed";
      alertData.msg = "Failed to edit user access template. Please try again later!";
      sharedFailAlert(alertData);
    }
    onCancelAddUserAccessTemplate();
  }

  // const EditableCell = ({
  //   editing,
  //   dataIndex,
  //   title,
  //   inputType,
  //   record,
  //   index,
  //   children,
  //   ...restProps
  // }) => {
  //   const inputNode =
  //     inputType === "setCSUserType" ? (
  //       <Select
  //         dropdownStyle={{ position: "fixed" }}
  //         onSelect={(value, event) => {
  //           setCSUserType(value, event);
  //         }}
  //       >
  //         {boUserTypeList.map((data) => (
  //           <Option key={data.key} value={data.userType}>{data.displayName}</Option>
  //         ))}
  //       </Select>
  //     ) : (
  //       <Input allowClear={true} />
  //     );

  //   //const inputNode = <Input allowClear={true} />;

  //   return (
  //     <td {...restProps}>
  //       {editing ? (
  //         <Form.Item
  //           name={dataIndex}
  //           style={{
  //             margin: 0,
  //           }}
  //           rules={[
  //             {
  //               required: true,
  //               message: `Please Input ${title}!`,
  //             },
  //           ]}
  //         >
  //           {inputNode}
  //         </Form.Item>
  //       ) : (
  //         children
  //       )}
  //     </td>
  //   );
  // };

  const retrieveUserType = async () => {
    setTableLoadings(true);
    let requestData = {
      columnRequire : [
        'displayName',
        'access',
        'status',
        `FROM_UNIXTIME(SysCreateTime,'%Y-%m-%d %H:%i:%s') as registerDate`,
      ],
      condition: [`WHERE access != 'company'`]
    }
    let response = await getBoUserType(requestData);
    let result = await response.json();
    if(result.status ==='ok'){
      if(Object.keys(result.data).length > 0){
        let count = 0;
        let boUserTypeListData = result.data.map((obj) => {
          return {
              key           :   count++,
              displayName   :   obj.displayName,
              userType      :   obj.access,
              status        :   obj.status,
              registerDate  :   obj.registerDate,
            }
        })
        if(boUserTypeListData.length > 0){
          // if(role === 'CS_Super' ){
          //   let newBoUserTypeListData = boUserTypeListData.filter(obj => obj.displayName !== 'Super CS');
          //   setBoUserTypeList(newBoUserTypeListData);
          // }else {
          //   setBoUserTypeList(boUserTypeListData)
          // }
          setBoUserTypeList(boUserTypeListData);
        }
      }
    } else {
        alertData.title = "Failed";
            alertData.msg = result.msg;
            sharedFailAlert(alertData);
          }
    setTableLoadings(false);
  }

  const onRowSelectChange = (newSelectedPage) => {
    setTableKey(prevKey => prevKey + 1);

    setUserAccessTemplateData(prevState => {
        // Get the previous selection
        const prevSelectedKeys = new Set(selectedPage[currentParentPage] || []);
        const newSelectedKeys = new Set(newSelectedPage);

        // Determine which keys were added and which were removed
        const addedKeys = [...newSelectedKeys].filter(key => !prevSelectedKeys.has(key));
        const removedKeys = [...prevSelectedKeys].filter(key => !newSelectedKeys.has(key));

        // Update the state based on added and removed keys
        const updatedData = { ...prevState };
        const currentPageData = updatedData[currentParentPage] || [];

        const updatedPageData = currentPageData.map(page => {
            // Check if the current page's key is in the added or removed keys
            const isAdded = addedKeys.includes(page.key);
            const isRemoved = removedKeys.includes(page.key);

            // If the key was added or removed, update its status accordingly
            if (isAdded) {
                return {
                    ...page,
                    status: 1,
                    read: 1,
                    write: 1,
                    create: 1,
                    delete: 1
                };
            } else if (isRemoved) {
                return {
                    ...page,
                    status: 0,
                    read: 0,
                    write: 0,
                    create: 0,
                    delete: 0
                };
            }

            // Otherwise, keep the page unchanged
            return page;
        });

        updatedData[currentParentPage] = updatedPageData;
        return updatedData;
    });

    setSelectedPage(prevState => ({
      ...prevState,
      [currentParentPage]: newSelectedPage
    }));
  };

  const rowSelection = {
    selectedRowKeys: selectedPage[currentParentPage] || [],
    onChange: onRowSelectChange,
  };

  const handleGrantAllAccess = (e) => {
    const isChecked = e.target.checked;
    setGrantAllAccess(isChecked);

    const updatedData = Object.keys(userAccessTemplateData).reduce((acc, parentId) => {
      acc[parentId] = userAccessTemplateData[parentId].map(item => ({
        ...item,
        status: isChecked ? 1 : 0,
        read: isChecked ? 1 : 0,
        write: isChecked ? 1 : 0,
        create: isChecked ? 1 : 0,
        delete: isChecked ? 1 : 0
      }));
      return acc;
    }, {});

    const createFilteredPageIds = Object.entries(updatedData).reduce((acc, [parentId, pages]) => {
      const pageIds = pages
          .filter(page => page.status === 1)
          .map(page => page.key);             
  
      acc[parentId] = pageIds;
      return acc;
  }, {});
  
  setUserAccessTemplateData(updatedData);
  setSelectedPage(createFilteredPageIds);
  };
  
  return (
    <div >
      {
        boUserVw(role) ?
        <div style={{ backgroundColor: "#FFFFFF" }}>

          <div>
            <Modal 
              title="Add User Access Template" 
              visible={addUserAccessTemplate} 
              width={1000}
              footer={[
                <Button key="cancel" onClick={onCancelAddUserAccessTemplate} loading={addUserAccessTemplateSubmitLoading}>
                  Cancel
                </Button>,
                <Button
                  key="submit"
                  type="primary"
                  onClick={handleSubmitAddUserAccessTemplate}
                  loading={addUserAccessTemplateSubmitLoading}
                >
                  Submit
                </Button>
              ]}
            >
              <Form layout="vertical">
                <Form.Item
                  width="50%"
                  name={["user", "userTypeName"]}
                  label="User Type Name"
                >
                  <Input
                    placeholder="Please enter user type name"
                    value={userAccessName}
                    onChange={(e) => {
                      setUserAccessName(e.target.value);
                    }}
                  />
                   <Input
                    value={userAccessName}
                    onChange={(e) => {
                      setUserAccessName(e.target.value);
                    }}
                    style={{ display: "none" }}
                  />
                </Form.Item>
                <Form.Item
                  name={["page", "grantAllAccess"]}
                  // label="Grant All Access"
                >
                  <Checkbox
                    defaultChecked={grantAllAccess}
                    checked={grantAllAccess}
                    // disabled={!record.status}
                    onChange={handleGrantAllAccess}
                  >
                    Grant all access
                  </Checkbox>
                </Form.Item>
                <Form.Item
                  name={["page", "pageGroup"]}
                  label="Page Group"
                > 
                  <Select
                    dropdownStyle={{ position: "fixed" }}
                    placeholder="Please select page group"
                    value={currentParentPage}
                    // loading={defaultUserTypeSelectLoading}
                    onChange={(value) => setCurrentParentPage(value)}
                    style={{ width: 300 }}
                  >
                    {parentPageOptions.map((data) => (
                      <Option key={data.key} value={data.value}>{data.label}</Option>
                    ))}
                  </Select>
                  <Select
                    dropdownStyle={{ position: "fixed" }}
                    style={{ display: "none" }}
                    placeholder="Please select page group"
                    value={currentParentPage}
                    // loading={defaultUserTypeSelectLoading}
                    onChange={(value) => setCurrentParentPage(value)}
                  >
                    {parentPageOptions.map((data) => (
                      <Option key={data.key} value={data.value}>{data.label}</Option>
                    ))}
                  </Select>
                </Form.Item>
              </Form>
              <Table 
                key={tableKey}
                rowSelection={rowSelection} 
                columns={pageAccessColumn} 
                dataSource={userAccessTemplateData[currentParentPage]} 
                style={{textAlign: "center"}}
              />
            </Modal>
          </div>

          <div>
            <Modal 
              title="Edit User Access Template" 
              visible={editUserAccessTemplate}
              width={1000}
              footer={[
                <Button key="cancel" onClick={onCancelAddUserAccessTemplate} loading={editUserAccessTemplateSubmitLoading}>
                  Cancel
                </Button>,
                <Button
                  key="submit"
                  type="primary"
                  onClick={handleSubmitEditUserAccessTemplate}
                  loading={editUserAccessTemplateSubmitLoading}
                >
                  Submit
                </Button>
              ]}
            >
              <Form layout="vertical">
                <Form.Item
                  name={["page", "pageGroup"]}
                  label="Page Group"
                > 
                  <Select
                    dropdownStyle={{ position: "fixed" }}
                    placeholder="Please select page group"
                    value={currentParentPage}
                    // loading={defaultUserTypeSelectLoading}
                    onChange={(value) => setCurrentParentPage(value)}
                    style={{ width: 300 }}
                  >
                    {parentPageOptions.map((data) => (
                      <Option key={data.key} value={data.value}>{data.label}</Option>
                    ))}
                  </Select>
                  <Select
                    dropdownStyle={{ position: "fixed" }}
                    style={{ display: "none" }}
                    placeholder="Please select page group"
                    value={currentParentPage}
                    // loading={defaultUserTypeSelectLoading}
                    onChange={(value) => setCurrentParentPage(value)}
                  >
                    {parentPageOptions.map((data) => (
                      <Option key={data.key} value={data.value}>{data.label}</Option>
                    ))}
                  </Select>
                </Form.Item>
              </Form>
              <Table 
                key={tableKey}
                rowSelection={rowSelection} 
                columns={pageAccessColumn} 
                dataSource={userAccessTemplateData[currentParentPage]} 
                style={{textAlign: "center"}}
              />
            </Modal>
          </div>
      

          <PageHeader ghost={false}>
            <div>
              <Button key="1" type="primary" onClick={handleAddUserAccessTemplate}>
                Add
              </Button>
            </div>
          </PageHeader>
          <Form form={form} component={false}>
            <Table
              loading={tableLoadings}
              scroll={{ x: "100%", y: null }}
              // components={{
              //   body: {
              //     cell: EditableCell,
              //   },
              // }}
              dataSource={boUserTypeList}
              columns={columns}
              rowClassName="editable-row"
              // pagination={{
              //   onChange: cancel,
              // }}
              style={{ overflow: "auto", marginLeft: 20 }}
            />
          </Form>
      </div> : <CNotAuthorized />
      }
    </div>
  );
}