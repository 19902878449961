import { PageHeader } from "antd";
import { CS_USER_TYPE, REPORT_USER_TYPE, MARKETING_USER_TYPE } from "../common/constants/csuserType";
import { Page } from "../common/constants/pages";
import { adjustCredit } from "../config/networkConfig";

const approveLIMIT = 30000;



const downgradeRole = (role) => {
    if (role == 'CS') {
        return CS_USER_TYPE.Junior;
    }

    return role;
}

const depMgmtAppAppr = ()=> {
    return true;
}


const reportBankStmt = (role)=> {
    role = downgradeRole(role);

    if (!role ) return false;

    if (role == CS_USER_TYPE.Junior || role == CS_USER_TYPE.Senior || role == CS_USER_TYPE.SuperCS) {
        return true;
    }
}


const witMgmtAppAppr = (role, amount ) => {
    role = downgradeRole(role);

    if (!role || !amount) return false;

    if (role == CS_USER_TYPE.Junior && Math.abs(amount) < approveLIMIT ) {
        return true;
    }

    if (role == CS_USER_TYPE.Senior || role == CS_USER_TYPE.SuperCS) {
        return true;
    }
}

const witCommAppAppr = (role) => {
    role = downgradeRole(role);

    if (!role) return false;

    if (role == CS_USER_TYPE.Junior || role == CS_USER_TYPE.Senior || role == CS_USER_TYPE.SuperCS) {
        return false;
    }
}



const bankAccAdd = (role) => {
    role = downgradeRole(role);

    if (!role) return false;
}

const bankAccEdit = (role) => {
    role = downgradeRole(role);

    if (!role) return false;

    if (role == CS_USER_TYPE.Junior ) {
        return false;
    }

    if (role == CS_USER_TYPE.Senior || role == CS_USER_TYPE.SuperCS) {
        return true;
    }
}

const unlockMember = (role) => {
    role = downgradeRole(role);

    if (!role) return false;

    if (role == CS_USER_TYPE.Junior ) {
        return false;
    }

    if (role == CS_USER_TYPE.Senior || role == CS_USER_TYPE.SuperCS) {
        return true;
    }
}

const unlockMemberLockedBySystem = (role, compCode = '') => {
    role = downgradeRole(role);

    if (!role ) return false;

    if (role == CS_USER_TYPE.SuperCS || role == CS_USER_TYPE.Senior) {
        return true;
    }
    else {
        return false;
    }

}

const recoverMemberWallet = (role) => {
    role = downgradeRole(role);

    if (!role) return false;

    if (role == CS_USER_TYPE.Junior || role == CS_USER_TYPE.Senior || role == CS_USER_TYPE.SuperCS) {
        return true;
    }
}

const memberMgmtCrDelayDep = (role, compCode = '') => {
    role = downgradeRole(role);

    if (!role) return false;
    // Only siam96 is allow to all cs to do adjustment
    if (compCode != 'TW1' ) {
        if (role == CS_USER_TYPE.Senior || role == CS_USER_TYPE.SuperCS) {
            return true;
        }
        else {
            return false
        } 
    }
    else {
        return true;
    }
}

const memberMgmtVwMemberWallet = (role) => {
    role = downgradeRole(role);

    if (!role ) return false;

    if (role == CS_USER_TYPE.Junior || role == CS_USER_TYPE.Senior || role == CS_USER_TYPE.SuperCS) {
        return true;
    }
}

const memberMgmtVwPlayerTrxn = (role) => {
    role = downgradeRole(role);

    if (!role) return false;

    if (role == CS_USER_TYPE.Junior || role == CS_USER_TYPE.Senior || role == CS_USER_TYPE.SuperCS) {
        return true;
    }
}

const memberMgmtCrMember = (role) => {
    role = downgradeRole(role);

    if (!role) return false;

    if (role == CS_USER_TYPE.Junior || role == CS_USER_TYPE.Senior || role == CS_USER_TYPE.SuperCS) {
        return true;
    }
}

const memberMgmtRemoveCredit = (role, compCode = '') => {
    role = downgradeRole(role);

    if (!role) return false;

    if (role == CS_USER_TYPE.Senior || role == CS_USER_TYPE.SuperCS) {
        return true;
    }
    else {
        return false;
    }
}

const memberMgmtUpdateUplineRef = (role) => {
    role = downgradeRole(role);

    if (!role ) return false;

    if (role == CS_USER_TYPE.SuperCS || role == CS_USER_TYPE.Senior) {
        return true;
    }
    else {
        return false;
    }
}

const depMgmtManCrDep = (role) => {
    role = downgradeRole(role);

    if (!role) return false;

    if (role == CS_USER_TYPE.Senior || role == CS_USER_TYPE.SuperCS) {
        return true;
    }
    else {
        return false;
    }
}

const depMgmtManAppRejDep = (role) => {
    role = downgradeRole(role);

    if (!role) return false;

    if (role == CS_USER_TYPE.Senior || role == CS_USER_TYPE.SuperCS) {
        return true;
    }
    else {
        return false;
    }
}

const witMgmtManCrWit = (role) => {
    role = downgradeRole(role);

    if (!role) return false;

    if (role == CS_USER_TYPE.Senior || role == CS_USER_TYPE.SuperCS) {
        return true;
    }
    else {
        return false;
    }
}

const witMgmtManApprRej = (role) => {
    role = downgradeRole(role);

    if (!role) return false;

    if (role == CS_USER_TYPE.Senior || role == CS_USER_TYPE.SuperCS) {
        return true;
    }
    else {
        return false;
    }
}

const witCommManCrWith = (role) =>{
    role = downgradeRole(role);

    if (!role) return false;

    if (role == CS_USER_TYPE.Senior || role == CS_USER_TYPE.SuperCS) {
        return true;
    }
    else {
        return false;
    }
}

const witCommManApprRej = (role) =>{
    role = downgradeRole(role);

    if (!role) return false;
    
    if (role == CS_USER_TYPE.Senior || role == CS_USER_TYPE.SuperCS) {
        return true;
    }
    else {
        return false;
    }
}

const bankMgmtBnkSwt = (role) => {
    role = downgradeRole(role);

    if (!role) return false;

    if (role == CS_USER_TYPE.Senior || role == CS_USER_TYPE.SuperCS || role == 'Company') {
        return true;
    }
    else {
        return false;
    }
}

const bankMgmtSbSwitch = (role) => {
    role = downgradeRole(role);

    if (!role) return false;

    if (role == CS_USER_TYPE.Senior || role == CS_USER_TYPE.SuperCS ) {
        return true;
    }
    else {
        return false;
    }
}

const bankMgmtAT = (role) => {
    role = downgradeRole(role);

    if (!role) return false;
    
    if (role == CS_USER_TYPE.SuperCS ) {
        return true;
    }
    else {
        return false;
    }
}

const dashMonDLReport = (role) => {
    role = downgradeRole(role);

    if (!role) return false;

    if (role == CS_USER_TYPE.SuperCS || role == REPORT_USER_TYPE.Reporter || role == REPORT_USER_TYPE.JR_Reporter ) {
        return true;
    }
    else {
        return false;
    }
}

const marqMgmtEdit = (role) => {
    role = downgradeRole(role);

    if (!role) return false;

    if ( role == CS_USER_TYPE.Senior  || role == CS_USER_TYPE.SuperCS) {
        return true;
    }
    else {
        return false;
    }
}


const marqMgmtCr = (role) => {
    role = downgradeRole(role);

    if (!role ) return false;

    if (role == CS_USER_TYPE.Senior  || role == CS_USER_TYPE.SuperCS) {
        return true;
    }
    else { 
        return false;
    }
}

// Added for System Settings for allowing company role user
const mtnMgmtSwt = (role, compCode = '') => {
    role = downgradeRole(role);

    if (!role) return false;

    // if (compCode == 'TKC' || compCode == 'TW3' || compCode == 'TWA') {
    //     if (role == CS_USER_TYPE.Senior || role == CS_USER_TYPE.SuperCS || role == 'Company') {
    //         return true;
    //     }
    //     else {
    //         return false;
    //     }
    // }

    // else {
    //     if (role == CS_USER_TYPE.SuperCS || role == 'Company') {
    //         return true;
    //     }
    //     else {
    //         return false;
    //     }
    // }

    if (role == CS_USER_TYPE.SuperCS || CS_USER_TYPE.Senior || role == 'Company') {
        return true;
    }
    else {
        return false;
    }
}

const repDownlineVw = (role) => {
    role = downgradeRole(role);

    if (!role) return false;

    if (role == CS_USER_TYPE.SuperCS || role == REPORT_USER_TYPE.Reporter || role == REPORT_USER_TYPE.JR_Reporter || role == 'Shareholder') {
        return true;
    }
    else {
        return false;
    }
}

const feedMgmtFeedEdit = (role) => {
    role = downgradeRole(role);

    if (!role) return false;

    if (role == CS_USER_TYPE.Junior || role == CS_USER_TYPE.Senior || role == CS_USER_TYPE.SuperCS) {
        return true;
    }
}

const feedMgmtFeedExport = (role) => {
    role = downgradeRole(role);

    if (!role ) return false;

    if (role == CS_USER_TYPE.Junior || role == CS_USER_TYPE.Senior || role == CS_USER_TYPE.SuperCS) {
        return true;
    }
}

const repAccRecVw = (role, compCode = '') => {
    role = downgradeRole(role);

    if (!role) return false;

    if (compCode == 'OGT' || 'TK4') {
        if (role == CS_USER_TYPE.SuperCS || role == CS_USER_TYPE.Senior || role == REPORT_USER_TYPE.Reporter || role == REPORT_USER_TYPE.JR_Reporter || role == REPORT_USER_TYPE.Ext_Finance) {
            return true;
        }
        else {
            return false;
        }
    }
    else {
        if (role == CS_USER_TYPE.SuperCS || role == REPORT_USER_TYPE.Reporter || role == REPORT_USER_TYPE.JR_Reporter) {
            return true;
        }
        else {
            return false;
        }
    }
}

const repAccRecEdit = (role) => {
    role = downgradeRole(role);

    if (!role ) return false;

    if ( role == CS_USER_TYPE.SuperCS ) {
        return true;
    }
    else {
        return false;
    }
}

const refCommApprRej = (role) => {
    role = downgradeRole(role);

    if (!role) return false;

    if (role == CS_USER_TYPE.Junior || role == CS_USER_TYPE.Senior || role == CS_USER_TYPE.SuperCS) {
        return true;
    }
}

const refCommSearchApprRej = (role, status , uplinename, compCode = '') => {
    if (!role || !status) {
        return false;
    }

    if (status == "pending" || status == "kiv") {
        if (role == CS_USER_TYPE.Junior) {
            if (uplinename == 'undefined') {
                return false;
            }
            else {
                return true;
            }
        }

        if (compCode == 'OGT' || compCode == 'TK4') {
            if (role == CS_USER_TYPE.SuperCS || role == CS_USER_TYPE.Senior) {
                return true;
            }
        }
        else {
            if (role == CS_USER_TYPE.SuperCS) {
                return true;
            }
        }
    }

    if (status == "reject") { 
        if (role == CS_USER_TYPE.SuperCS) {
            return true;
        }
        else {
            return false;
        }
    }  
}

const refCommSearchSuperCS = (role, status) => {
    if (!role) {
        return false;
    }

    if (role != CS_USER_TYPE.SuperCS) {
        return false;
    }
    else if (role == CS_USER_TYPE.SuperCS && status == "reject" ){
        return true;
    }
}

const newsModuleEdit = (role) => {
    role = downgradeRole(role);

    if (!role) return false;

    if (role == CS_USER_TYPE.Senior || role == CS_USER_TYPE.SuperCS) {
        return true;
    }
    else {
        return false;
    }
}

const opModuleVw = (role, username, compCode = '') => {
    role = downgradeRole(role);
    let permittedUsers = ['CT_Liew','ogcs_super','ogtest01','roychua'];

    if (!role) return false;

    if (compCode == 'OGT' || compCode == 'TK4') {
        if (role == CS_USER_TYPE.SuperCS || REPORT_USER_TYPE.Reporter) {
            return (permittedUsers.includes(username) == true) ? true : false;
        }
        else {
            return false;
        }
    }
    else{
        if (role == CS_USER_TYPE.SuperCS || REPORT_USER_TYPE.Reporter) {
            return true;
        }
        else {
            return false;
        }
    }

}

const witResortVw = (role,username) => {
    let permittedUsers = ['CT_Liew','ogcs_super','ogtest01','roychua'];

    if (!role) return false;

    if (role == CS_USER_TYPE.Senior || role == CS_USER_TYPE.SuperCS) {
        return true;
    }
    else {
        return false;
    }

}

const topListVw = (role, username, compCode) => {
    role = downgradeRole(role);
    let permittedUsers = ['CT_Liew','ogcs_super','ogtest01','Ryan'];

    if (!role) return false;

    if (compCode == 'OGT' || compCode == 'TK4') {
        if (role == CS_USER_TYPE.SuperCS) {
            return (permittedUsers.includes(username) == true) ? true : false;
        }
        else if (role == REPORT_USER_TYPE.Reporter || role == REPORT_USER_TYPE.JR_Reporter) {
            return true;
        }
        else {
            return false;
        }
    }
    else if (compCode == 'TKC' || compCode == 'TW3'){
        if (role == CS_USER_TYPE.Senior || role == CS_USER_TYPE.SuperCS || role == REPORT_USER_TYPE.Reporter || role == REPORT_USER_TYPE.JR_Reporter) {
            return true;
        }
        else {
            return false;
        }
    }
    else {
        return true;
    }
}

const MasterDataVw = (role, username, compCode = '') => {
    let permittedUsers = ['CT_Liew','ogcs_super','ogtest01','Ryan'];

    if (!role) return false;

    if (compCode == 'OGT') {
        if (role == CS_USER_TYPE.SuperCS) {
            return (permittedUsers.includes(username) == true) ? true : false;
        }
        else {
            return false;
        }
    }
    else{
        if (role == CS_USER_TYPE.SuperCS) {
            return true;
        }
        else {
            return false;
        }
    }
}

const DailyTrxnStatsVw = (role, username, compCode = '') => {

    if (!role) return false;

    if (role == CS_USER_TYPE.Senior || role == CS_USER_TYPE.SuperCS || role == REPORT_USER_TYPE.Reporter || role == REPORT_USER_TYPE.JR_Reporter || role == REPORT_USER_TYPE.Ext_Finance || role == MARKETING_USER_TYPE.Marketing) {
        return true;
    }
    else {
        return false;
    }
}

const DailyBankStatsVw = (role, username, compCode = '') => {

    if (role) return true;
}

const reportCustomerDepVw = (role, username, compCode = '') => {
    
    if (!role) return false;

    if (role == CS_USER_TYPE.SuperCS || role == REPORT_USER_TYPE.Reporter || role == REPORT_USER_TYPE.JR_Reporter) {
        return true;
    }
    else {
        return false;
    }
}

const reportVipPlayerVw = (role, username, compCode = '') => {
    let permittedUsers = ['CT_Liew','xiaohui','tkb777_supercs','ogcs_super','ogcs_supercs2'];

    if (!role) return false;

    if (role == CS_USER_TYPE.SuperCS) {
        return (permittedUsers.includes(username) == true) ? true : false;
    }
    else if (role == REPORT_USER_TYPE.Reporter || role == REPORT_USER_TYPE.JR_Reporter) {
        return true;
    }
    else {
        return false;
    }
}

const DailyBankTrxnStatsVw = (role, username, compCode = '') => {

    if (!role) return false;

    if (compCode == 'OGT' || compCode == 'TKC') {
        return false;
    }
    else {
        return true;
    }
}

const ReportPlayerVw = (role, username, compCode = '') => {

    if (!role) return false;

    if (role == CS_USER_TYPE.SuperCS || role == CS_USER_TYPE.Senior || role == REPORT_USER_TYPE.Reporter || role == REPORT_USER_TYPE.JR_Reporter) {
        return true;
    }
    else {
        return false;
    }
}

const ReportAgentVw = (role, username) => {

    if (!role) return false;

    if (role == CS_USER_TYPE.Junior) {
        return false;
    }
    else {
        return true;
    }
}

const ReportFortuneWheelVw = (role, username, compCode = '') => {
    let permittedUsers = ['CT_Liew','xiaohui','tkb777_supercs','ogcs_super'];

    if (!role) return false;

    if (compCode == 'OGT' || compCode == 'TK4') {
        return true;
    }
    else if (compCode == 'TKC' || compCode == 'TW3') {
        if (role == CS_USER_TYPE.SuperCS) {
            return (permittedUsers.includes(username) == true) ? true : false;
        }
        else {
            return false;
        }
    }
    else {
        if (role == CS_USER_TYPE.Senior || role == CS_USER_TYPE.SuperCS || role == REPORT_USER_TYPE.Reporter || role == REPORT_USER_TYPE.JR_Reporter || role == REPORT_USER_TYPE.Ext_Finance) {
            return true;
        }
        else {
            return false;
        }
    }
}

const GameStatsVw = (role, username, compCode = '') => {

    if (!role) return false;

    if (role == CS_USER_TYPE.SuperCS || role == REPORT_USER_TYPE.Reporter || role == REPORT_USER_TYPE.JR_Reporter || role == REPORT_USER_TYPE.Ext_Finance) {
        return true;
    }
    else {
        return false;
    }
}

const ReportSmsCostingVw = (role, username, compCode = '') => {

    if (!role) return false;

    if (role == CS_USER_TYPE.SuperCS || role == REPORT_USER_TYPE.Reporter || role == REPORT_USER_TYPE.JR_Reporter || role == REPORT_USER_TYPE.Ext_Finance) {
        return true;
    }
    else {
        return false;
    }
}

const ReportDepositLedgerVw = (role = '') => {

    if (!role) return false;

    if (role == CS_USER_TYPE.SuperCS || role == REPORT_USER_TYPE.Ext_Finance) {
        return true;
    }
    else {
        return false;
    }
}

const monitorBotVw = (role)=> {
    if (!role) return false;

    if (role) {
        return true;
    }
}

const gameProviderVw = (role) => {
    if (!role) return false;

    if (role == CS_USER_TYPE.Senior || role == CS_USER_TYPE.SuperCS ) {
        return true;
    }
    else {
        return false;
    }
}

const repRegVsDepVw = (role, compCode = '') => {
    if (!role) return false;

    if (role == CS_USER_TYPE.Senior || role == CS_USER_TYPE.SuperCS || role == REPORT_USER_TYPE.Reporter || role == REPORT_USER_TYPE.JR_Reporter) {
        return true;
    }
    else {
        return false;
    }
}

const switchWithdrawModeVw = (role) => {
    if (!role ) return false;

    if (role == CS_USER_TYPE.SuperCS ) {
        return true;
    }
    else {
        return false;
    }
}

const pageSettingVw = (role, username, compCode = '') => {
    let permittedUsers = ['og_ryan'];
    role = downgradeRole(role);

    if (!role) return false;

    if (compCode == 'OGT' || compCode == 'TK4' || compCode == 'TWC' || compCode == 'TWB') {
        if (role != CS_USER_TYPE.Junior) {
            return true;
        }
        else {
            return false;
        }
    }
    else {
        if (role == CS_USER_TYPE.SuperCS || role == 'Company' || role == 'Domain') {
            return true;
        }
        else if (role == CS_USER_TYPE.Senior) {
            return (permittedUsers.includes(username) == true) ? true : false;
        }
        else {
            return false;
        }
    }
}

const onboardDomainVw = (role) => {
    if (!role) return false;

    if (role == CS_USER_TYPE.SuperCS || role == CS_USER_TYPE.Senior || role == 'Company' || role == 'Domain') {
        return true;
    }
    else {
        return false;
    }
}

const bindLineConnectVw = (role) => {
    if (!role) return false;

    if (role == CS_USER_TYPE.SuperCS || role === CS_USER_TYPE.Senior || role == 'Company') {
        return true;
    }
    else {
        return false;
    }
}

const bindSmsVw = (role) => {
    if (!role) return false;

    if (role == CS_USER_TYPE.SuperCS || role === CS_USER_TYPE.Senior || role == 'Company') {
        return true;
    }
    else {
        return false;
    }
}

const boUserVw = (role) => {
    if (!role) return false;

    if (role == CS_USER_TYPE.SuperCS || role == 'Company') {
        return true;
    }
    else {
        return false;
    }
}


// ================================================= BANK TRANSACTION PAGE =================================================
const removePuppetBankTrxn = (role, compCode = '') => {
    if (!role) return false;

    if (compCode == 'OGT' || compCode == 'TK4') {
        if (role == CS_USER_TYPE.SuperCS) {
            return true;
        }
        else {
            return false;
        }
    }
    else {
        return true;
    }
}

const fundTransfer = (role, compCode = '') => {
    if (!role) return false;

    if (role == CS_USER_TYPE.SuperCS || role == CS_USER_TYPE.Senior) {
        return true;
    }
    else {
        return false;
    }
}

const demoUsers = (role, compCode = '') => {
    if (!role) return false;

    if (role == CS_USER_TYPE.SuperCS || role == 'Company') {
        return true;
    }
    else {
        return false;
    }
}

export { 
    reportBankStmt,
    witMgmtAppAppr, 
    witCommAppAppr,
    bankAccEdit,
    unlockMember, 
    recoverMemberWallet,
    unlockMemberLockedBySystem,
    memberMgmtCrDelayDep,
    memberMgmtVwMemberWallet,
    memberMgmtVwPlayerTrxn,
    memberMgmtCrMember,
    memberMgmtRemoveCredit,
    memberMgmtUpdateUplineRef,
    depMgmtManCrDep , 
    depMgmtManAppRejDep, 
    witMgmtManCrWit, 
    witMgmtManApprRej,
    witCommManCrWith,
    witCommManApprRej,
    bankMgmtBnkSwt,
    bankMgmtSbSwitch,
    bankMgmtAT,
    dashMonDLReport,
    marqMgmtEdit, 
    marqMgmtCr,
    mtnMgmtSwt,
    repDownlineVw,
    feedMgmtFeedEdit,
    feedMgmtFeedExport,
    repAccRecVw,
    repAccRecEdit,
    refCommApprRej,
    refCommSearchApprRej,
    refCommSearchSuperCS,
    newsModuleEdit,
    opModuleVw,
    witResortVw,
    topListVw,
    MasterDataVw,
    DailyTrxnStatsVw,
    DailyBankStatsVw,
    reportCustomerDepVw,
    reportVipPlayerVw,
    DailyBankTrxnStatsVw,
    ReportPlayerVw,
    ReportAgentVw,
    ReportFortuneWheelVw,
    GameStatsVw,
    ReportSmsCostingVw,
    ReportDepositLedgerVw,
    monitorBotVw,
    gameProviderVw,
    repRegVsDepVw,
    switchWithdrawModeVw,
    removePuppetBankTrxn,
    fundTransfer,
    pageSettingVw,
    onboardDomainVw,
    bindLineConnectVw,
    bindSmsVw,
    boUserVw,
    demoUsers
};