import { combineReducers } from "redux";
import login from "../reducer/login";
import { pageAccess, pageAccessLoading, currentPageAccess, sideMenu } from "../reducer/access"

const rootReducer = combineReducers({
  login,
  pageAccess,
  pageAccessLoading,
  currentPageAccess,
  sideMenu,
});

export default rootReducer;
