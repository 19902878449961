import { Router } from "react-router-dom";
import React, { Component } from "react";
import { createBrowserHistory } from "history";
import Navigation from "./navigations/index";
import "./App.less";
import { BRAND } from "../src/config/helper.js";

document.title = BRAND; // Dynamically set the title

const history = createBrowserHistory();
class App extends Component {
  render() {
    return (
      <Router history={history}>
        <Navigation />
      </Router>
    );
  }
}

export default App;
