import { DOMAIN, VERSION } from "../config/helper.js";

export const settings = {
    "version": VERSION,
    "appName": DOMAIN,
    "companyName" : "OgCloud Tech",
    "decimalScale": 4,
    "decimalScaleMobile":2 ,
    "processTypeManual" :"M",
    "processTypeApp"    :"A",
    "pageSizeOptions"   : ["10", "50", "100", "500", "1000"],
    "pageSizeOptionsSmall"   : ["10", "20","50", "100"],
    "pageSizeOptionsMedium"   : ["100", "250", "500"],
    "pageSizeOptionsLarge"   : ["100", "200", "500","1000"],
    "monitorRefreshTime" :"300000", //5 minutes in ms

};